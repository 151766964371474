//  Externals
import React from 'react';
//  Component starts
const CheckBox = ({ checked, onChange }) => {
    return (
        <div className='custom-checkbox'>
            <label>
                <input
                    type='checkbox'
                    checked={checked}
                    onChange={onChange}
                />
                <span />
            </label>
        </div>
    );
}

export default CheckBox;