//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../store/reducers/loading';
import CS from '../../services/commonService';
import * as API from '../../constants/API';
import * as MSG from '../../constants/MSG';
import * as URL from '../../constants/URL';
import Theme from '../common/theme';
//  Externals
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
//	Component starts
const ForgotPassword = () => {
	//	Redux-store
	const dispatch = useDispatch();
	//	Location
	const location = useLocation();
	//	History
	const history = useHistory();
	//	State values
	const [errors, setErrors] = useState({ emailId: '' });
	const [emailId, setEmailId] = useState('');

	useEffect(() => {
		setEmailId(location.state.emailId);
		dispatch(resetLoading());
	}, [location, dispatch]);

	/******************************************************************************************
	@Purpose    :   To validate the forgot password form
	*******************************************************************************************/
	const validateForm = () => {
		//	Email
		if (isEmpty(emailId)) errors.emailId = MSG.REQUIRED_EMAIL;
		else if (!CS.validateEmail(emailId)) errors.emailId = MSG.REQUIRED_PROPER_EMAIL;
		else errors.emailId = '';
		//  Errors
		setErrors({ ...errors });
		return (isEmpty(errors.emailId)) ? true : false;
	};

	/******************************************************************************************
	@Purpose    :   To submit the forgot password form
	*******************************************************************************************/
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		try {
			const body = { emailId };
			dispatch(apiCallBegan());
			const response = await CS.callApi({ url: API.FORGOT_PASSWORD, body, method: 'post' });
			if (response.status === 1) {
				CS.showSuccessMessage(response.message);
				setEmailId('');
				history.replace(URL.login);
			}
			dispatch(apiCallEnd());
		}
		catch (error) {
			console.error('error In ====>>>> adminLogin <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	};

	return (
		<>
			<Theme />
			<div className='login-wrapper' style={{ background: 'url(../assets/images/login-bg.jpg) no-repeat center center' }}>
				<div className='login-body'>
					<div className='login-container row m-0'>
						<div className='col-xl-8 col-11'>
							<div className='card bg-login-card'>
								<div className='row m-0'>
									<div className='col-md-6 col-12 pl-0 pr-0'>
										<div className='card rounded-left mb-0 p-4 d-flex justify-content-center h-100'>
											<div className='card-header'>
												<div className='card-title text-center mb-4'>
													<h4 className='mb-2 mt-2'>Forgot Password?</h4>
												</div>
												<div className='text-center mb-3'><p className='common-small-text'>Enter the email you used when you joined and we will send you set password link</p></div>
											</div>
											<div className='card-content'>
												<div className='card-body'>
													<form onSubmit={(event) => handleSubmit(event)}>
														<div className='form-group mb-4'>
															<label htmlFor='emailId'>Email<sup className='text-danger'>*</sup></label>
															<input type='text'
																className={errors.emailId ? 'form-control input-error' : 'form-control'}
																id='emailId'
																placeholder='Email'
																value={emailId}
																onChange={(e) => {
																	setEmailId(e.target.value);
																	setErrors({ ...errors, emailId: '' })
																}}
															/>
															<span className='text-danger error-msg'>{errors.emailId}</span>
														</div>
														<button
															type='submit'
															className='btn btn-primary glow position-relative btn-block'
														>Send Email
														</button>
													</form>
													<div className=' d-flex flex-md-row flex-column justify-content-between align-items-center mt-4 mb-3'>
														<div className='text-left common-small-text'>
															<small> <Link to='/'>Sign in</Link></small>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-md-6 d-md-block d-none text-center align-self-center p-7'>
										<div className='card-content'>
											<img className='img-fluid' src='../assets/images/register.png' alt='sign up' width='300'></img>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ForgotPassword;