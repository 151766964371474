//  Internals
import TableHeader from './tableHeader';
import Pagination from './pagination';
import Icon from '../icons/index';
import TableBody from './tableBody';
//  Externals
import DragHandle from './dragHandle';
import { useSelector } from 'react-redux';
import DropDown from 'rc-dropdown';
import PropTypes from 'prop-types';
import React from 'react';
//  Component state
const Table = ({ columnKey, columns, showColumnSettings, onColumnSettings }) => {
    //  Redux-store
    const { columnSettings } = useSelector(state => state.entities[columnKey]);
    return (
        <>
            <div className='custom-table'>
                <div className='table-responsive'>
                    <table className='table row-border nowrap common-dataTable'>
                        <TableHeader
                            columns={columns}
                            columnKey={columnKey} />
                        <TableBody
                            columns={columns}
                            columnKey={columnKey} />
                    </table>
                </div>
                {showColumnSettings && <DropDown
                    className='setting-gear dropdown'
                    trigger={['click']}
                    animation='slide-up'
                    overlay={<DragHandle columnSettings={columnSettings} onColumnSettings={onColumnSettings} />}
                >
                    <span
                        className='nav-link nav-link-label'
                        data-toggle='dropdown'
                        aria-expanded='false'
                    ><Icon type='settings' />
                    </span>
                </DropDown>}
            </div>
            <Pagination columnKey={columnKey} />
        </>
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    columns: PropTypes.array.isRequired,
    columnKey: PropTypes.string.isRequired,
    onColumnSettings: PropTypes.func.isRequired,
    showColumnSettings: PropTypes.bool.isRequired
};

export default Table;