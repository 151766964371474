//	Externals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import { setColumnSettings, setItems, setTotal } from '../../../store/reducers/entities';
import MultipleDeleteBox from '../../common/multipleDeleteBox';
import { usePrevious } from '../../../hooks/usePrevious';
import BreadCrumbs from '../../common/breadcrumbs';
import HelpQueriesTable from './helpQueriesTable';
import CS from '../../../services/commonService';
import SearchBox from '../../common/searchBox';
import withCommon from '../../hoc/withCommon';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
import Modal from '../../common/Modal';
import Details from './details';
//	Internals
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-dropdown';
import { isEmpty } from 'lodash';
//	Pre-defined values
const options = [
	{ value: 'open', label: 'Open' },
	{ value: 'closed', label: 'Close' }
];
const columnKey = 'helpQueriesUsers';
//	Component starts
const HelpQueriesUsers = ({ selectAll, setSelectAll, handleSelectSingle, handleSelectAll, handleDelete, handleColumnSettings, handleMultipleDelete }) => {
	//	Redux-store
	const dispatch = useDispatch();
	const { page, limit, searchText, items, sort } = useSelector(state => state.entities[columnKey]);
	//	History
	const history = useHistory();
	//	Custom hooks
	const previousSearchText = usePrevious(searchText);
	//	State values
	const [query, setQuery] = useState({});
	const [open, setOpen] = useState(false);
	//	Manipulate the data
	const checked = items.map(item => item.checked).includes(true);

	/******************************************************************************************
	@Purpose    :   To get the help queries of users
	*******************************************************************************************/
	const getHelpQueries = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const body = { page, limit, columnKey };
			//	Search text
			if (previousSearchText !== searchText) body.searchText = searchText;
			//	Sorting
			if (!isEmpty(sort.path) && ([1, -1].includes(sort.order))) body.sort = { [sort.path]: sort.order };
			//	Get the response from API
			const response = await CS.callApi({ url: API.HELP_QUERIES, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				const { data } = response;
				const { columnSettings, listing, total } = data;
				//	Store the Data in Redux
				dispatch(setItems({ columnKey, items: listing.map(list => { list.checked = false; return list; }) }));
				dispatch(setColumnSettings({ columnKey, columnSettings }));
				dispatch(setTotal({ columnKey, total }));
				//	Store the Data in local state
				setSelectAll(false);
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getHelpQueries <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	}, [dispatch, page, limit, previousSearchText, searchText, sort.path, sort.order, setSelectAll, history]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getHelpQueries(), [page, limit, searchText, sort]);

	/******************************************************************************************
	@Purpose    :   To change the verification status of the restaurants
	*******************************************************************************************/
	const handleVerifyStatus = useCallback(async (ids, status) => {
		try {
			dispatch(apiCallBegan());
			const body = { ids, status };
			const response = await CS.callApi({ url: API.CHANGE_HELP_QUERY_STATUS, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				CS.showSuccessMessage(response.message);
				const updatedItems = items.map(item => {
					const entity = { ...item };
					if (ids.indexOf(item._id) !== -1) {
						if (item.status !== 'closed') entity.status = status;
						entity.checked = false;
					}
					return entity;
				});
				dispatch(setItems({ columnKey, items: updatedItems }));
				setSelectAll(false);
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> handleVerifyStatus <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	}, [dispatch, history, items, setSelectAll]);

	/******************************************************************************************
	@Purpose    :   To multiple verification status change
	*******************************************************************************************/
	const handleMultipleVerificationStatusChange = useCallback((value) => {
		const ids = items.filter(item => item.checked).map(item => item._id);
		handleVerifyStatus(ids, value);
	}, [handleVerifyStatus, items]);

	/******************************************************************************************
	@Purpose    :   To close the modal
	*******************************************************************************************/
	const closeModal = useCallback(() => setOpen(false), []);

	return (
		<>
			<BreadCrumbs
				title='Content Management'
				data={[{ label: 'Help' }, { label: 'Help Queries Uses' }]} />
			<div className='dataTables_wrapper'>
				<div className='content-area position-relative'>
					<div className='grid'>
						<div className='grid-content'>
							<div className='user-listing-filterOptions mb-2 mt-3 d-block'>
								<div className='row mb-2'>
									<div className='col-sm-8 mb-2 position-static'>
										<div className='left-buttons d-flex'>
											<SearchBox
												columnKey={columnKey}
												searchText={searchText}
											/>
											{checked && <>
												<MultipleDeleteBox onClick={handleMultipleDelete} />
												<Select
													className='ml-2 custom-dropdown'
													placeholder='Status'
													onChange={(option) => handleMultipleVerificationStatusChange(option.value)}
													options={options}
												/>
											</>}
										</div>
									</div>
									<div className='col-sm-4'>
										<div className='right-buttons d-flex justify-content-end'>
										</div>
									</div>
								</div>
							</div>
							<Modal
								open={open}
								onCloseModal={closeModal} >
								<Details
									query={query}
									onCloseModal={closeModal}
								/>
							</Modal>
							<HelpQueriesTable
								setOpen={setOpen}
								setQuery={setQuery}
								columnKey={columnKey}
								selectAll={selectAll}
								onDelete={handleDelete}
								onSelectAll={handleSelectAll}
								onSelectSingle={handleSelectSingle}
								onVerifyStatus={handleVerifyStatus}
								onColumnSettings={handleColumnSettings}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withCommon({ component: HelpQueriesUsers, columnKey });