import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'loading',
    initialState: false,
    reducers: {
        apiCallBegan: () => true,
        apiCallSuccess: () => false,
        apiCallFailed: () => false,
        apiCallEnd: () => false,
        resetLoading: () => false
    }
});

export const { apiCallBegan, apiCallSuccess, apiCallFailed, apiCallEnd, resetLoading } = slice.actions;

export default slice.reducer;