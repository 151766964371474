//	Internals
import { columnsOrdering } from '../../../util/columnsOrdering';
import getBadgeClasses from '../../../util/badgeClass';
import CS from '../../../services/commonService';
import * as URL from '../../../constants/URL';
import Table from '../../common/table';
import Icon from '../../icons/index';
//	Externals
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
//	Component starts
const GiftOrdersTable = ({ columnKey, onColumnSettings }) => {
	//	Pre-defined values
	const columns = [
		{
			label: '', status: false
		},
		{
			label: 'Order Id',
			type: 'string',
			sort: true,
			path: 'orderId'
		},
		{
			label: 'Date',
			type: 'date',
			sort: true,
			path: 'createdAt',
			content: (item) => moment(item.createdAt).format('DD-MM-YYYY, h:mm a')
		},
		{
			label: 'Restaurant Info',
			type: 'string',
			sort: true,
			path: 'restaurantName',
			content: (item) => <Link to={{
				pathname: `${URL.restaurants}/${item.restaurantId}`,
				state: { key: 'giftOrders' }
			}}>
				<div className="d-flex align-items-center">
					<div className="flex-auto">{CS.renderImageUrl(item.restaurantPhoto)}</div>
					<div className="d-flex flex-column flex-wrap">
						<span className='pl-2 user-head'>{item.restaurantName}</span>
						<span className='pl-2'>{item.restaurantEmailId}</span>
					</div>
				</div>
			</Link>
		},
		{
			label: 'Gifted By',
			type: 'string',
			sort: true,
			path: 'giftedByName',
			content: (item) => <Link to={{
				pathname: `${URL.users}/${item.giftedBy}`,
				state: { key: 'giftOrders' }
			}}>
				<div className="d-flex align-items-center pr-3">
					<div className="flex-auto">{CS.renderImageUrl(item.giftedByPhoto)}</div>
					<div className="d-flex flex-column flex-wrap">
						<span className='pl-2 user-head'>{item.giftedByName}</span>
						<span className='pl-2'>{item.giftedByEmailId}</span>
					</div>
				</div>
			</Link>
		},
		{
			label: 'Gifted To',
			type: 'string',
			sort: true,
			path: 'giftedToName',
			content: (item) => <Link to={{
				pathname: `${URL.users}/${item.giftedTo}`,
				state: { key: 'giftOrders' }
			}}>
				<div className="d-flex align-items-center pr-3">
					<div className="flex-auto">{CS.renderImageUrl(item.giftedToPhoto)}</div>
					<div className="d-flex flex-column flex-wrap">
						<span className='pl-2 user-head'>{item.giftedToName}</span>
						<span className='pl-2'>{item.giftedToEmailId}</span>
					</div>
				</div>
			</Link>
		},
		{
			label: 'Order Type',
			type: 'boolean',
			sort: true,
			path: 'orderType'
		},
		{
			label: 'Gift Type',
			type: 'boolean',
			sort: true,
			path: 'giftType'
		},
		{
			label: 'Quantity',
			type: 'number',
			sort: true,
			path: 'totalItem'
		},
		{
			label: 'Total Amount',
			type: 'number',
			sort: true,
			path: 'subTotal',
			content: item => `$ ${item.subTotal}`
		},
		{
			label: 'Status',
			type: 'boolean',
			content: (item) => <p className={getBadgeClasses(item.orderStatus)}>{item.orderStatus}</p>
		},
		{
			label: 'Action',
			type: 'boolean',
			content: (item) => <>
				<Icon
					type='show'
					data-tip
					data-for='show'
					onClick={() => history.push(`${URL.giftOrders}/${item._id}`)} />
				<ReactTooltip
					id='show'
					place='top'
					effect='solid'>Show</ReactTooltip>
			</>
		}
	];
	//	History
	const history = useHistory();
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	//	Manipulate the data

	const availableColumns = columns.filter(column => {
		const entity = columnSettings.find(c => c.label === column.label);
		if (isEmpty(entity)) return column;
		if (entity.status) return column;
		return null;
	});

	const updatedColumns = columnsOrdering(availableColumns, columnSettings);

	return (
		<Table
			columnKey={columnKey}
			columns={updatedColumns}
			showColumnSettings={true}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	columnKey: PropTypes.string.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default memo(GiftOrdersTable);