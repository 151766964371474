//  Externals
import { Modal } from 'react-responsive-modal';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
//  Component starts
const PopUpModal = ({ open, onCloseModal, children }) => {
    //	Manipulate the data
    return (<Modal
        center
        open={open}
        onClose={onCloseModal}
        focusTrapped={false}
    >{children}</Modal>)
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    open: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired
};

export default memo(PopUpModal);