//  Internals
import { setLimit, setPage } from '../../store/reducers/entities';
//  Externals
import { useDispatch, useSelector } from 'react-redux';
import RCPagination from 'rc-pagination';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Select } from 'antd';
import React from 'react';
//  Manipulate the data
const { Option } = Select;
//  Component starts
const Pagination = ({ columnKey }) => {
    //	Redux-store
    const dispatch = useDispatch();
    const { name, page, limit, total, items } = useSelector(state => state.entities[columnKey]);
    const { length: value } = items;
    const numbers = [2, 5, 10, 15, 25, 50, 100, 200, 500, 1000].filter((number) => number < total);

    return (
        !isEmpty(numbers) && (
            <div className='table-footer d-flex align-items-center justify-content-between'>
                <div>
                    <label className='mr-2'>Showing</label>
                    <Select
                        onChange={(number) => dispatch(setLimit({ columnKey, limit: number }))}
                        value={value}
                    >
                        {numbers.map((number) => <Option value={number} key={number}>{number}</Option>)}
                    </Select>
                    <label className='ml-2'>Out of {total} {name}</label>
                </div>
                <div className='pagination-list'>
                    <RCPagination
                        className='ant-pagination'
                        total={total}        //  items total count
                        current={page}       //  current page
                        pageSize={limit}     //  items per page
                        onChange={(number) => dispatch(setPage({ columnKey, page: number }))}     //  page change callback
                    />
                </div>
            </div>
        )
    );
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    columnKey: PropTypes.string.isRequired,
};

export default Pagination;