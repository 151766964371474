//	Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import getBadgeClasses from '../../../util/badgeClass';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import * as URL from '../../../constants/URL';
import * as API from '../../../constants/API';
//	Externals
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
// Component starts
const GiftOrderDetails = () => {
	//	Pre-defined values
	const restaurantInfo = { restaurantName: '', address: '', emailId: '', photo: '' };
	const giftedToUser = { phoneNumber: '', fullName: '', emailId: '', photo: '' };
	const giftedByUser = { phoneNumber: '', fullName: '', emailId: '', photo: '' };
	//	Params
	const { slug } = useParams();
	//	Redux-store
	const dispatch = useDispatch();
	//  History
	const history = useHistory();
	//	State values
	const [orderData, setOrdersData] = useState({});
	//	Manipulate the data
	const orderDetails = { isTipAdded: false, offerApplied: false, offerId: '', currencyGiftApplied: false, currencyGiftId: '', orderNumber: '', orderType: '', orderStatusHist: [], items: [], subTotal: '', tipAmount: '', discount: '', totalAmount: '', tax: '', commission: '', amountToPayRest: '', amountToAdmin: '', billSplitStatus: '', orderStatus: '', sharedBillFriends: false, friends: [], rewardPointsUsed: false, rewardPointsValue: '' };

	const { createdAt, restaurantId, giftedTo, giftedBy, giftType, paymentDetail, orderId = { ...orderDetails } } = orderData;

	const { isTipAdded = false, offerApplied = false, offerId, currencyGiftApplied = false, currencyGiftId, orderNumber, orderType, orderStatusHist = [], items = [], subTotal, tipAmount, discount, totalAmount, tax, commission, amountToPayRest, amountToAdmin, billSplitStatus = false, orderStatus, sharedBillFriends = false, friends = [], rewardPointsUsed = false, rewardPointsValue } = orderId;

	if (!isEmpty(restaurantId)) {
		restaurantInfo.phoneNumber = (isEmpty(restaurantId.dialCode) || isEmpty(restaurantId.mobile)) ? null : `${restaurantId.dialCode}-${restaurantId.mobile}`;
		restaurantInfo.restaurantName = restaurantId.restaurantName;
		restaurantInfo.address = restaurantId.address;
		restaurantInfo.emailId = restaurantId.emailId;
		restaurantInfo.photo = restaurantId.photo;
	}

	if (!isEmpty(giftedTo)) {
		giftedToUser.phoneNumber = (isEmpty(giftedTo.dialCode) || isEmpty(giftedTo.mobile)) ? null : `${giftedTo.dialCode}-${giftedTo.mobile}`;
		giftedToUser.fullName = giftedTo.firstname + giftedTo.lastname;
		giftedToUser.emailId = giftedTo.emailId;
		giftedToUser.photo = giftedTo.photo;
	}

	if (!isEmpty(giftedBy)) {
		giftedByUser.phoneNumber = (isEmpty(giftedBy.dialCode) || isEmpty(giftedBy.mobile)) ? null : `${giftedBy.dialCode}-${giftedBy.mobile}`;
		giftedByUser.fullName = giftedBy.firstname + giftedBy.lastname;
		giftedByUser.emailId = giftedBy.emailId;
		giftedByUser.photo = giftedBy.photo;
	}

	/******************************************************************************************
	@Purpose    :   To get the order details
	*******************************************************************************************/
	const getDetails = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const response = await CS.callApi({ url: API.GIFT_ORDER_DETAILS + slug, isAuthorized: true });
			if (response.status === 1) {
				const { data } = response;
				//	Store the data in local state
				setOrdersData({ ...data });
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getDetails <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	}, [dispatch, history, slug]);

	useEffect(() => {
		if (!isEmpty(slug)) getDetails();
		dispatch(resetLoading());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, slug]);

	return (
		<>
			<BreadCrumbs
				title='View Gift Order'
				data={[{ label: 'Gift Orders', path: URL.giftOrders }, { label: 'View Gift Order' }]} />
			<div className='content-wrapper order-details-wrap'>
				<div className='d-flex justify-content-end w-100 mb-4'>
					<p className={getBadgeClasses(orderStatus)}>{orderStatus}</p>
				</div>
				<div className='card p-0'>
					<div className='card-body'>
						<div className='form-group row'>
							<div className='col-md-6'>
								<div className='card-box'>
									<div className='card-items'>
										<h6>Order Information</h6>
										<p>ID : <strong>{orderNumber}</strong></p>
										<p>Date : <strong>{moment(createdAt).format('DD-MM-YYYY, h:mm a')}</strong></p>
										<p>Type : <strong>{orderType}</strong></p>
										<p>Gift Type : <strong>{giftType}</strong></p>
									</div>
									{paymentDetail && <div className='card-items'>
										<h6>Payment Method</h6>
										<p>{paymentDetail.card.brand} ....{paymentDetail.card.last4}</p>
										<p>Exp : <strong>{paymentDetail.card.exp_month}/{paymentDetail.card.exp_year}</strong></p>
									</div>}
									<div className='card-items'>
										<h6>Restaurant Info</h6>
										<div className='profile-info'>
											<div className='personal-info'>
												<p><strong>{restaurantInfo.restaurantName}</strong></p>
												<p>{restaurantInfo.address}</p>
												<p>{restaurantInfo.phoneNumber}</p>
												<p>{restaurantInfo.emailId}</p>
											</div>
											<div className="user-item">
												{CS.renderImageUrl(restaurantInfo.photo)}
											</div>
										</div>
									</div>
									<div className='card-items'>
										<h6>User Info (Gifted By)</h6>
										<div className='profile-info'>
											<div className='personal-info'>
												<p><strong>{giftedByUser.fullName}</strong></p>
												<p>{giftedByUser.phoneNumber}</p>
												<p>{giftedByUser.emailId}</p>
											</div>
											<div className="user-item">
												{CS.renderImageUrl(giftedByUser.photo)}
											</div>
										</div>
									</div>
									<div className='card-items'>
										<h6>Gifted To</h6>
										<div className='profile-info'>
											<div className='personal-info'>
												<p><strong>{giftedToUser.fullName}</strong></p>
												<p>{giftedToUser.phoneNumber}</p>
												<p>{giftedToUser.emailId}</p>
											</div>
											<div className="user-item">
												{CS.renderImageUrl(giftedToUser.photo)}
											</div>
										</div>
									</div>
									<div className='card-items'>
										<h6>Order</h6>
										<ul className='list-group'>
											{items.map((item, i) => {
												if (item.isAccepted || item.isAddedNew) {
													return (<li key={i}>
														<p>{item.quantity} x {item.dishTitle} ($ {item.dishPrice})</p>
														<span>$ {item.quantity * item.dishPrice}</span>
														{!isEmpty(item.attributes) && item.attributes && (
															<ul className='list-group'>
																{item.attributes.map(attribute => (
																	attribute.options.map(option => <li>
																		<p>{option.name} + ${option.price}</p>
																		<span>$ {item.quantity * option.price}</span>
																	</li>)
																))}
															</ul>
														)}
													</li>)
												}
											})}
										</ul>
										<div className='d-flex justify-content-between'>
											<p>Item Total: </p><span><strong>$ {subTotal}</strong></span>
										</div>
										{isTipAdded && <div className='d-flex justify-content-between'>
											<p>Tip: </p><span><strong>$ {tipAmount}</strong></span>
										</div>}
										{rewardPointsUsed && <div className='d-flex justify-content-between danger-text'>
											<p className='danger-text'>Reward Points Value : </p><span><strong className='danger-text'>- ${rewardPointsValue}</strong></span>
										</div>}
										<div className='d-flex justify-content-between'>
											<p>Tax: </p><span><strong>$ {tax}</strong></span>
										</div>
										{offerApplied && <div className='d-flex justify-content-between danger-text'>
											<p className='danger-text'>Discount : </p><span><strong className='danger-text'>- $ {discount}</strong></span>
										</div>}
										{currencyGiftApplied && <div className='d-flex justify-content-between'>
											<p className='danger-text'>Gift Currency : </p><span><strong className='danger-text'>- ${currencyGiftId.giftAmount}</strong></span>
										</div>}
										<div className='d-flex justify-content-between mt-3'>
											<h6 className=''>Order Total: </h6><span><strong>$ {totalAmount}</strong></span>
										</div>
									</div>
								</div>
							</div>
							<div className='col-md-6 col-lg-6'>
								<div className='card-box'>
									{!isEmpty(orderStatusHist) && <div className='card-items'>
										<h6>Status History</h6>
										<ul className='history-list'>
											{orderStatusHist.map(order => (
												<li>
													<div className='history-heading'>
														<span>{order.orderStatus}</span>
														<p>Status by : {order.updatedBy}</p>
													</div>
													<div className='history-date'>
														<span>{moment(order.updatedAt).format('DD-MM-YYYY, h:mm a')}</span>
													</div>
												</li>
											))}
										</ul>
									</div>}
									{offerApplied && <div className="card-items">
										<h6>Coupon code</h6>
										<p>Name: <strong>{offerId.title}</strong></p>
										<p>Code: <strong>{offerId.code}</strong></p>
										<p>Discount Rate: <strong>{offerId.discountRate}%</strong></p>
									</div>}
									{currencyGiftApplied && <div className="card-items">
										<h6>Gift currency code</h6>
										<p>Code: <strong>{currencyGiftId.giftCode}</strong></p>
										<p>Amount: <strong>$ {currencyGiftId.giftAmount}</strong></p>
									</div>}
									<div className="card-items">
										<h6>Earnings</h6>
										<div className='d-flex justify-content-between'>
											<p>Total Amount: </p><span><strong>$ {totalAmount}</strong></span>
										</div>
										<div className='d-flex justify-content-between'>
											<p>Commission: </p><span><strong>$ {commission}</strong></span>
										</div>
										<div className='d-flex justify-content-between'>
											<p>Tax: </p><span><strong>$ {tax}</strong></span>
										</div>
										<div className='d-flex justify-content-between'>
											<p>Amount To Pay Restaurant: </p><span><strong>$ {amountToPayRest}</strong></span>
										</div>
										<div className='d-flex justify-content-between'>
											<p>Earnings: </p><span><strong>$ {amountToAdmin}</strong></span>
										</div>
									</div>
									{billSplitStatus && <div className="card-items">
										<h6>Split Bill</h6>
										{sharedBillFriends && sharedBillFriends.map(shareBillFriend => (
											<li className='mt-3'>
												<div className='d-flex justify-content-between'>
													<div className="d-flex align-items-center">
														<div className="flex-auto">{CS.renderImageUrl(shareBillFriend.friendId.photo)}</div>
														<div className="d-flex flex-column">
															<span className='pl-2 user-head'>{shareBillFriend.friendId.firstname} {shareBillFriend.friendId.lastname}</span>
															<span className='pl-2'>{shareBillFriend.friendId.emailId}</span>
														</div>
														{shareBillFriend.shareType !== "Self" && <div className="d-flex flex-column ml-4">
															<p className='mb-0 badge badge-light-primary'>Self</p>
														</div>}
													</div>
													<span><strong>$ {shareBillFriend.amount}</strong></span>
												</div>
											</li>
										))}
									</div>}
									{!isEmpty(friends) && friends.length !== 0 && <div className="card-items">
										<h6>Friends</h6>
										{friends.map(friend => (
											<div className="d-flex align-items-center">
												<div className="flex-auto">{CS.renderImageUrl(friend.photo)}</div>
												<div className="d-flex flex-column">
													<span className='pl-2 user-head'>{friend.firstname} {friend.lastname}</span>
													<span className='pl-2'>{friend.emailId}</span>
												</div>
											</div>
										))}
									</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default GiftOrderDetails;