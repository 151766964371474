import * as actions from '../reducers/loading';
import CS from '../../services/commonService';

//	actions to display success messages
const actionTypes = [
	'auth/setCurrentUser',
];

const api = ({ dispatch }) => next => async action => {

	if (!action.payload) return next(action);

	if (action.type !== actions.apiCallBegan.type) return next(action);

	const { onSuccess } = action.payload;

	next(action);

	const response = await CS.callApi({ ...action.payload });
	if (response.status) {
		if (actionTypes.includes(onSuccess)) CS.showSuccessMessage(response.message);
		//	General
		dispatch(actions.apiCallSuccess(response.data));
		//	Specific
		if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
	}
	else dispatch(actions.apiCallFailed(response.data));
};

export default api;