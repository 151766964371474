// Refer:https://loading.io/
import React from 'react';
import LoaderGif from './loader.gif';
import './loader.css';
import { useSelector } from 'react-redux';

const FullPageLoader = (props) => {
    const loading = useSelector(state => state.loading);

    return (
        (loading || props.loading) && <div className='fp-container'>
            <div className='fp-loader'>
                <img src={LoaderGif} alt='loading' />
            </div>
        </div>
    );
}

export default FullPageLoader;