import React, { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import DropDown from 'rc-dropdown';

import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../store/reducers/loading';
import { setMenuAlignment, setTheme } from '../../store/reducers/auth';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import ChangePassword from '../modules/profile/changePassword';
import { resetCurrentUser } from '../../store/reducers/auth';
import CS from '../../services/commonService';
import * as MSG from '../../constants/MSG';
import * as URL from '../../constants/URL';
import * as API from '../../constants/API';
import Modal from '../common/Modal';
import Icon from '../icons/index';

const Header = () => {
	//	Redux-store
	const dispatch = useDispatch();
	//	History
	const history = useHistory();

	const [toggle, setToggle] = useState(false);
	const [open, setOpen] = useState(false);

	const { photo, firstName, lastName, theme, menuAlignment } = useSelector(state => state.auth.currentUser);
	const fullName = `${firstName} ${lastName}`;

	//  Theme selection -- Start
	if (theme === 'light') {
		document.body.classList.add('light');
		document.body.classList.remove('dark');
	} else {
		document.body.classList.add('dark');
		document.body.classList.remove('light');
	}
	//  Theme selection -- End
	//  Menu alignment -- Start
	if (menuAlignment !== 'vertical') {
		document.body.classList.add('vertical');
		document.body.classList.remove('horizontal');
	} else {
		document.body.classList.add('horizontal');
		document.body.classList.remove('vertical');
	}
	//  Menu alignment -- End

	const logout = async () => {
		if (!CS.getItem('accessToken')) {
			history.replace(URL.login);
			return;
		}
		try {
			await updateTheme();
			await dispatch(apiCallBegan({ url: API.LOG_OUT, isAuthorized: true, onSuccess: resetCurrentUser.type }));
			await CS.setItem('accessToken', '');
			CS.showSuccessMessage(MSG.LOGOUT_SUCCESS);
			history.replace(URL.login);
		} catch (error) {
			console.error('error In ====>>>> logout <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	};

	const updateTheme = async () => {
		try {
			dispatch(apiCallBegan());
			const body = { theme, menuAlignment };
			await CS.callApi({ url: API.UPDATE_THEME, body, method: 'put', isAuthorized: true });
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> updateTheme <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	};

	// Create a ref that we add to the element for which we want to detect outside clicks
	const ref = useRef();
	const onClose = useCallback(() => setToggle(false), []);
	// Call hook passing in the ref and a function to call on outside click
	useOnClickOutside(ref, onClose);

	return (
		<div className='main-content-area'>
			<header>
				<div className='navigation-bar'>
					<nav className='navbar d-flex navbar-expand bd-navbar fixed-top'>
						<div className='mr-auto float-left bookmark-wrapper d-flex align-items-center'>
							<ul className='nav navbar-nav'>
								<li className='nav-item mobile-menu'>
									<span onClick={() => document.body.classList.add('sidebar-open')} className='nav-link nav-menu-main menu-toggle hidden-xs'  ><Icon type='menu' /></span>
								</li>
							</ul>
							<ul className='horizontal-brand nav navbar-nav'>
								<li>
									<Link to='/'>
										<img className='img-fluid' src='/assets/images/logo.svg' alt='branding logo' />
									</Link>
								</li>
							</ul>
						</div>
						<ul className='navbar-nav flex-row ml-md-auto d-md-flex'>
							<li className='nav-item user-dropdown dropdown'>
								<DropDown
									trigger={['click']}
									animation='slide-up'
									className='custom-dropdown'
									overlay={<ul className='list-group handle-list'>
										{history.location.pathname !== URL.profile && <li onClick={() => history.push(URL.profile)} className='list-group-item toggle-vis d-flex'><i className='bx bx-user mr-2'></i>Edit Profile</li>}
										<li onClick={() => setOpen(true)} className='list-group-item toggle-vis d-flex'><i className='bx bx-reset mr-2'></i>Change Password</li>
										<li onClick={() => logout()} className='list-group-item toggle-vis d-flex'><i className='bx bx-log-out mr-2'></i>Log out</li>
									</ul>}
								>
									<div className='custom-btn'>
										<a className='btn nav-link dropdown-toggle dropdown-user-link' id='userDropdown'
											role='button' data-toggle='dropdown' aria-expanded='true'>
											<div className='user-nav d-sm-flex d-none'>
												<span className='user-name'>{fullName}</span>
											</div>
											<span>{CS.renderImageUrl(photo)}</span>
										</a>
									</div>
								</DropDown>
							</li>
							<li className='nav-item theme-setting-block-nav-link' ref={ref}>
								<span onClick={() => setToggle(!toggle)} className='theme-setting-link'>
									<Icon type='themeSettings' />
								</span>
								<div className={toggle ? 'theme-setting-block open' : 'theme-setting-block '} >
									<div className='d-flex align-items-center'>
										<span className='light-icon icon mr-1 d-block'><em className='bx bx-sun'></em></span>
										<div className='custom-control custom-switch theme-switch'>
											<input type='checkbox' checked={theme === 'dark'} onChange={() => dispatch(setTheme())} className='custom-control-input' id='customSwitchTheme' />
											<label className='custom-control-label' htmlFor='customSwitchTheme'></label>
										</div>
										<span className='dark-icon icon'><Icon type='sun' /></span>
									</div>
									<div className='d-flex align-items-center vertical-btn'>
										<span className='vertical-icon icon mr-1 d-block'><em className='bx bx-grid-vertical'></em></span>
										<div className='custom-control custom-switch sidebar-switch'>
											<input type='checkbox' checked={menuAlignment === 'horizontal'} onChange={() => dispatch(setMenuAlignment())} className='custom-control-input' id='sidebarSwitchBtn' />
											<label className='custom-control-label' htmlFor='sidebarSwitchBtn'></label>
										</div>
										<span className='horizontal-icon icon'><em className='bx bx-grid-horizontal'></em></span>
									</div>
								</div>
							</li>
						</ul>
					</nav>
				</div>
				<Modal open={open} onCloseModal={() => setOpen(false)} >
					<ChangePassword handleLogout={logout} />
				</Modal>
			</header>
		</div >
	);
}

export default Header;