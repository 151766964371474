//  Internals
import { setItems, setTotal, setColumnSettings, setFilterSettings, setTemplateSettings } from '../../../store/reducers/entities';
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import MultipleFeaturedSelect from '../../common/multipleFeaturedSelect';
import MultipleStatusSelect from '../../common/multipleStatusSelect';
import MultipleDeleteBox from '../../common/multipleDeleteBox';
import { usePrevious } from '../../../hooks/usePrevious';
import ValidFilters from '../../common/validFilters';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import SearchBox from '../../common/searchBox';
import withCommon from '../../hoc/withCommon';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
import Download from '../../common/download';
import Filters from '../../common/filters';
import BrandsTable from './brandsTable';
import Modal from '../../common/Modal';
import AddUpdate from './addUpdate';
//	Externals
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
//	Pre-defined values
const columnKey = 'brands';
//	Component starts
const Brands = ({ selectAll, setSelectAll, handleSelectSingle, handleSelectAll, handleColumnSettings, handleChangeStatus, handleMultipleStatus, handleChangeFeatured, handleMultipleFeatured, handleDelete, handleMultipleDelete }) => {
	//	Redux-store
	const dispatch = useDispatch();
	const { page, limit, searchText, validFilters, items, sort } = useSelector(state => state.entities[columnKey]);
	//  History
	const history = useHistory();
	//	Custom hooks
	const previousSearchText = usePrevious(searchText);
	//	State values
	const [brand, setBrand] = useState({});
	const [open, setOpen] = useState(false);
	//	Manipulate the data
	const checked = items.map(item => item.checked).includes(true);

	/******************************************************************************************
	@Purpose    :   To get the brands list
	*******************************************************************************************/
	const getBrands = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const body = { page, limit, columnKey };
			//	Search text
			if (previousSearchText !== searchText) body.searchText = searchText;
			//	Sorting
			if (!isEmpty(sort.path) && ([1, -1].includes(sort.order))) body.sort = { [sort.path]: sort.order };
			//	Filters
			if (!isEmpty(validFilters)) body.filters = validFilters;
			//	Get the response from API
			const response = await CS.callApi({ url: API.BRANDS, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				const { data } = response;
				const { columnSettings, filterSettings, templateSettings, listing, total } = data;
				dispatch(setItems({ columnKey, items: listing.map(list => { list.checked = false; return list; }) }));
				dispatch(setTotal({ columnKey, total }));
				dispatch(setColumnSettings({ columnKey, columnSettings }));
				dispatch(setFilterSettings({ columnKey, filterSettings }));
				dispatch(setTemplateSettings({ columnKey, templateSettings }));
				//	Store the Data in local state
				setSelectAll(false);
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getBrands <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	}, [dispatch, page, limit, previousSearchText, searchText, sort, validFilters, setSelectAll, history]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getBrands(), [page, limit, searchText, sort, validFilters]);

	/******************************************************************************************
	@Purpose    :   To close the modal
	*******************************************************************************************/
	const closeModal = useCallback((reload) => {
		setOpen(false);
		setBrand({});
		if (reload === true) getBrands();
	}, [getBrands]);

	return (
		<>
			<BreadCrumbs
				title='Master Management'
				data={[{ label: 'Brands' }]} />
			<div className='dataTables_wrapper'>
				<div className='content-area position-relative'>
					<div className='grid'>
						<div className='grid-content'>
							<div className='user-listing-filterOptions mb-2 mt-3 d-block'>
								<div className='row mb-2'>
									<div className='col-sm-8 mb-2 position-static'>
										<div className='left-buttons d-flex'>
											<SearchBox
												columnKey={columnKey}
												searchText={searchText}
											/>
											<Filters columnKey={columnKey} />
											{checked && <>
												<MultipleDeleteBox onClick={handleMultipleDelete} />
												<MultipleStatusSelect onChange={handleMultipleStatus} />
												<MultipleFeaturedSelect onChange={handleMultipleFeatured} placeholder='Featured' />
											</>}
										</div>
									</div>
									<div className='col-sm-4'>
										<div className='right-buttons d-flex justify-content-end'>
											<button
												onClick={() => setOpen(true)}
												id='addNewBrand'
												type='button'
												className='btn glow-primary btn-primary'
											>
												<i className='bx bx-user-plus d-lg-none' />
												<span className='d-none d-sm-none d-lg-inline-block'>Add Brand</span>
											</button>
											<Download columnKey={columnKey} />
										</div>
									</div>
								</div>
								<ValidFilters columnKey={columnKey} />
							</div>
							<Modal open={open} onCloseModal={closeModal} >
								<AddUpdate
									brand={brand}
									columnKey={columnKey}
									onCloseModal={closeModal}
								/>
							</Modal>
							<BrandsTable
								setOpen={setOpen}
								setBrand={setBrand}
								columnKey={columnKey}
								selectAll={selectAll}
								onDelete={handleDelete}
								onSelectAll={handleSelectAll}
								onChangeStatus={handleChangeStatus}
								onSelectSingle={handleSelectSingle}
								onChangeFeatured={handleChangeFeatured}
								onColumnSettings={handleColumnSettings}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withCommon({ component: Brands, columnKey });