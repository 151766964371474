//	Internals
import { columnsOrdering } from '../../../util/columnsOrdering';
import * as URL from '../../../constants/URL';
import CheckBox from '../../common/checkBox';
import Table from '../../common/table';
import Icon from '../../icons/index';
//	Externals
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import React from 'react';
//	Component 
const EmailTemplatesTable = ({ columnKey, selectAll, onDelete, onSelectAll, onSelectSingle, onColumnSettings }) => {
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	//	History
	const history = useHistory();
	//	Pre-defined values
	const columns = [
		{
			label: <CheckBox checked={selectAll} onChange={onSelectAll} />,
			type: 'checkbox',
			content: (item) => <CheckBox checked={item.checked} onChange={() => onSelectSingle(item._id)} />
		},
		{
			label: 'Email Title',
			type: 'string',
			sort: true,
			path: 'emailTitle',
			content: (item) => <Link to={`${URL.emailTemplates}/${item._id}`}>{item.emailTitle}</Link>
		},
		{
			label: 'Email Key',
			type: 'string',
			sort: true,
			path: 'emailKey'
		},
		{
			label: 'Subject',
			type: 'string',
			sort: true,
			path: 'subject'
		},
		{
			label: 'Action',
			type: 'boolean',
			content: (item) => <>
				<Icon
					type='edit'
					data-tip
					data-for='edit'
					onClick={() => history.push(`${URL.emailTemplates}/${item._id}`)} />
				<ReactTooltip
					id='edit'
					place='top'
					effect='solid'>Edit</ReactTooltip>
				<Icon
					type='delete'
					data-tip
					data-for='delete'
					onClick={() => onDelete([item._id])} />
				<ReactTooltip
					id='delete'
					place='top'
					effect='solid'>Delete</ReactTooltip>
			</>
		}
	];
	//	Manipulate the data
	const availableColumns = columns.filter(column => {
		const entity = columnSettings.find(c => c.label === column.label);
		if (isEmpty(entity)) return column;
		if (entity.status) return column;
		return null;
	});
	const updatedColumns = columnsOrdering(availableColumns, columnSettings);

	return (
		<Table
			columnKey={columnKey}
			columns={updatedColumns}
			showColumnSettings={true}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	onDelete: PropTypes.func.isRequired,
	selectAll: PropTypes.bool.isRequired,
	columnKey: PropTypes.string.isRequired,
	onSelectAll: PropTypes.func.isRequired,
	onSelectSingle: PropTypes.func.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default EmailTemplatesTable;