import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import React from 'react';
import CS from '../../services/commonService';
import URL from '../../constants/URL';

const isAuthenticated = () => isEmpty(CS.getItem('accessToken')) ? false : true;

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => isAuthenticated() ? (<Component {...props}  {...rest} />) : <Redirect to={{
        pathname: URL.login,
        state: props.location
    }} />} />
);

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => isAuthenticated() ? <Redirect to={URL.dashboard} /> : <Component {...props}  {...rest} />} />
);

export const LoginRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => isAuthenticated() ? <Redirect to={URL.dashboard} /> : <Component {...props}  {...rest} />} />
);