//	Internals
import { setFilters, setValidFilters, setFilterSettings } from '../../store/reducers/entities';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { apiCallBegan } from '../../store/reducers/loading';
import * as API from '../../constants/API';
import * as MSG from '../../constants/MSG';
import * as URL from '../../constants/URL';
import Icon from '../icons/index';
//	Externals
import React, { useState, useCallback, useRef, Fragment, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DateTime from 'react-datetime';
import Select from 'react-dropdown';
import { isEmpty } from 'lodash';
//	Pre-defined values

// 'eq' - > 'Equal',
// 'ne' - > 'Not Equal',
// 'ct' - > 'Contains',
// 'nct' - > 'Doesn't Contains',
// 'sw' - > 'Starts With',
// 'ew' - > 'Finishes With',
// 'in' - > 'Is Empty',
// 'nn'  - > 'Is Not Empty',
// 'gt'  - > 'Greater Than',
// 'lt'  - > 'Less Than',
// 'bt'  - > 'Between'
const commonOperators = [
	{ label: 'Equal', value: 'eq' },
	{ label: 'Not Equal', value: 'ne' },
	{ label: 'Is Empty', value: 'in' },
	{ label: 'Is Not Empty', value: 'nn' }
];
const operators = {
	string: [
		...commonOperators,
		{ label: 'Contains', value: 'ct' },
		{ label: `Doesn't Contains`, value: 'nct' },
		{ label: 'Starts With', value: 'sw' },
		{ label: 'Ends With', value: 'ew' },
	],
	number: [
		...commonOperators,
		{ label: 'Greater Than', value: 'gt' },
		{ label: 'Less Than', value: 'lt' },
		{ label: 'Between', value: 'bt' },
	],
	date: [
		...commonOperators,
		{ label: 'Greater Than', value: 'gt' },
		{ label: 'Less Than', value: 'lt' },
		{ label: 'Between', value: 'bt' },
	]
};
const colorOptions = [
	{
		label: <span className='dropdown-item'><span className='color-shade bg-gray'></span><span className='color-text'>Gray</span></span>,
		value: 'gray'
	},
	{
		label: <span className='dropdown-item'><span className='color-shade bg-yellow'></span><span className='color-text'>Yellow</span></span>,
		value: 'yellow'
	},
	{
		label: <span className='dropdown-item'><span className='color-shade bg-green'></span><span className='color-text'>Green</span></span>,
		value: 'green'
	},
	{
		label: <span className='dropdown-item'><span className='color-shade bg-purple'></span><span className='color-text'>Purple</span></span>,
		value: 'purple'
	},
	{
		label: <span className='dropdown-item'><span className='color-shade bg-rose'></span><span className='color-text'>Rose</span></span>,
		value: 'rose'
	},
	{
		label: <span className='dropdown-item'><span className='color-shade bg-skyBlue'></span><span className='color-text'>Sky Blue</span></span>,
		value: 'skyBlue'
	}
];
const dateFormatUI = 'DD-MM-YYYY';
//	Component starts
const Filters = ({ columnKey }) => {
	//	Redux-store
	const dispatch = useDispatch();
	const { filters, defaultFilters, columnSettings, filterSettings } = useSelector(state => state.entities[columnKey]);
	//	History
	const history = useHistory();
	//	Create a ref that we add to the element for which we want to detect outside clicks
	const ref = useRef();
	const onClose = useCallback(() => setOpen(false), []);
	//	Call hook passing in the ref and a function to call on outside click
	useOnClickOutside(ref, onClose);
	//	State values
	const [errors, setErrors] = useState({ color: '', filterName: '' });
	let [filterName, setFilterName] = useState('');
	const [filterId, setFilterId] = useState('');
	const [color, setColor] = useState(false);
	const [open, setOpen] = useState(false);
	//	Manipulate the data
	const columns = columnSettings.filter(columnSetting => columnSetting.status);

	/******************************************************************************************
	@Purpose    :   To render the data types
	*******************************************************************************************/
	const renderTypes = ({ type, label }) => {
		if (type === 'boolean') {
			const entity = columns.find(column => column.label === label);
			return entity.options;
		}
		return operators[type];
	};

	/******************************************************************************************
	@Purpose    :   To handle select field
	*******************************************************************************************/
	const handleSelectField = (field, index) => {
		const entity = columns.find(column => column.label === field.label);
		const tempFilters = [...filters];
		const obj = {
			...tempFilters[index],
			selectedField: { ...field, type: entity ? entity.type : '', path: entity ? entity.path : '' },
			selectedType: { label: 'Select Type', value: '' },
			errors: { ...tempFilters[index].errors, selectedField: '' },
			values: { value: '', greaterThan: '', lessThan: '' }
		};
		tempFilters.splice(index, 1, { ...obj });
		dispatch(setFilters({ columnKey, filters: tempFilters }));
	};

	/******************************************************************************************
	@Purpose    :   To handle select type
	*******************************************************************************************/
	const handleSelectType = (field, index) => {
		const tempFilters = [...filters];
		const obj = {
			...tempFilters[index],
			selectedType: { ...field },
			errors: { ...tempFilters[index].errors, selectedType: '' },
			values: { value: '', greaterThan: '', lessThan: '' }
		};
		tempFilters.splice(index, 1, obj);
		dispatch(setFilters({ columnKey, filters: tempFilters }));
	};

	/******************************************************************************************
	@Purpose    :   To handle select value
	*******************************************************************************************/
	const handleSelectValue = (field, index) => {
		const tempFilters = [...filters];
		const obj = {
			...tempFilters[index],
			values: field,
			errors: { ...tempFilters[index].errors, values: '' },
		};
		tempFilters.splice(index, 1, obj);
		dispatch(setFilters({ columnKey, filters: tempFilters }));
	};

	/******************************************************************************************
	@Purpose    :   To handle the condition
	*******************************************************************************************/
	const handleCondition = (condition, index) => {
		const tempFilters = [...filters];
		const obj = { ...tempFilters[index], condition };
		tempFilters.splice(index, 1, obj);
		dispatch(setFilters({ columnKey, filters: tempFilters }));
	};

	/******************************************************************************************
	@Purpose    :   To handle greater than
	*******************************************************************************************/
	const handleGreaterThan = (greaterThan, index) => {
		const tempFilters = [...filters];
		const obj = {
			...tempFilters[index],
			errors: { ...tempFilters[index].errors, values: '' },
			values: { ...tempFilters[index].values, greaterThan }
		};
		tempFilters.splice(index, 1, obj);
		dispatch(setFilters({ columnKey, filters: tempFilters }));
	};

	/******************************************************************************************
	@Purpose    :   To handle less than
	*******************************************************************************************/
	const handleLessThan = (lessThan, index) => {
		const tempFilters = [...filters];
		const obj = {
			...tempFilters[index],
			errors: { ...tempFilters[index].errors, values: '' },
			values: { ...tempFilters[index].values, lessThan }
		};
		tempFilters.splice(index, 1, obj);
		dispatch(setFilters({ columnKey, filters: tempFilters }));
	};

	/******************************************************************************************
	@Purpose    :   To handle value
	*******************************************************************************************/
	const handleValue = (value, index) => {
		const tempFilters = [...filters];
		const obj = {
			...tempFilters[index],
			errors: { ...tempFilters[index].errors, values: '' },
			values: { value }
		};
		tempFilters.splice(index, 1, obj);
		dispatch(setFilters({ columnKey, filters: tempFilters }));
	};

	/******************************************************************************************
	@Purpose    :   To add new entity to filters
	*******************************************************************************************/
	const addField = (e) => {
		e.preventDefault();
		dispatch(setFilters({ columnKey, filters: [...filters, ...defaultFilters] }));
	};

	/******************************************************************************************
	@Purpose    :   To remove entity from filters
	*******************************************************************************************/
	const removeField = (e, i) => {
		e.preventDefault();
		let tempFilters = [...filters];
		tempFilters.splice(i, 1);
		dispatch(setFilters({ columnKey, filters: [...tempFilters] }));
	};

	/******************************************************************************************
	@Purpose    :   To clear all filters
	*******************************************************************************************/
	const clearFilters = () => {
		setOpen(!open);
		setFilterId('');
		setColor(false);
		setFilterName('');
		dispatch(setValidFilters({ columnKey, validFilters: [] }));
		dispatch(setFilters({ columnKey, filters: [...defaultFilters] }));
	};

	/******************************************************************************************
	@Purpose    :   To validate the form
	*******************************************************************************************/
	const validateForm = () => {
		let isValid = true;
		const errorFilters = filters.map(filter => {
			let newErrors = { ...filter.errors };
			if (filter.selectedField.type === 'boolean') {
				if (filter.values.value === '') {
					newErrors = { ...newErrors, values: MSG.REQUIRED_VALUE };
					isValid = false;
				} else newErrors = { values: '' };
			}
			else {
				if (filter.selectedField.label === 'Select Field') {
					newErrors = { ...newErrors, selectedField: MSG.REQUIRED_FIELD };
					isValid = false;
				} else {
					if (filter.selectedType.label === 'Select Type') {
						newErrors = { ...newErrors, selectedType: MSG.REQUIRED_TYPE };
						isValid = false;
					} else newErrors = { ...newErrors, selectedType: '' };
					if (filter.selectedType.label !== 'Select Type') {
						if (filter.values.value === '' && filter.selectedType.value === 'bt') {
							if (!filter.values.greaterThan || !filter.values.lessThan) {
								newErrors = { ...newErrors, values: MSG.REQUIRED_VALUE };
								isValid = false;
							}
						} else if (filter.values.value === '' && !['in', 'nn'].includes(filter.selectedType.value)) {
							newErrors = { ...newErrors, values: MSG.REQUIRED_VALUE };
							isValid = false;
						} else newErrors = { ...newErrors, values: '' };
					}
				}
			}
			const obj = { ...filter, errors: { ...newErrors } };
			return obj;
		});
		dispatch(setFilters({ columnKey, filters: [...errorFilters] }));
		return isValid;
	};

	/******************************************************************************************
	@Purpose    :   To handle filter form submit
	*******************************************************************************************/
	const handleSubmit = (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		const validFilters = filters.filter(filter => {
			if (['in', 'nn'].includes(filter.selectedType.value)) return filter;
			if (filter.values.value !== '') return filter;
			if (filter.values.greaterThan !== '' && filter.values.lessThan !== '') return filter;
			return null;
		});
		dispatch(setValidFilters({ columnKey, validFilters }));
		setOpen(!open);
	};

	/******************************************************************************************
	@Purpose    :   To validate the form
	*******************************************************************************************/
	const validateFilterForm = () => {
		//	Filter name
		filterName = filterName.trim();
		if (isEmpty(filterName)) errors.filterName = MSG.REQUIRED_FILTER_NAME;
		else errors.filterName = '';
		//	Color
		if (isEmpty(color)) errors.color = MSG.REQUIRED_COLOR;
		else errors.color = '';
		//  Errors
		setErrors({ ...errors });
		return isEmpty(errors.filterName) && isEmpty(errors.color) ? true : false;
	};

	/******************************************************************************************
	@Purpose    :   To handle the form submit
	*******************************************************************************************/
	const handleFormSubmit = (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		if (!validateFilterForm()) return;
		saveFilter();
		setOpen(!open);
	};

	/******************************************************************************************
	@Purpose    :   To save filter
	*******************************************************************************************/
	const saveFilter = async () => {
		try {
			const body = { filterSettings: filters, columnKey, filterName, color: color.value };
			let url = API.FILTER_SETTINGS;
			let method = 'post';
			//	Update-filterID
			if (!isEmpty(filterId)) {
				method = 'put';
				url = `${url}/${filterId}`;
			}
			await dispatch(apiCallBegan({ url, body, method, isAuthorized: true }));
			dispatch(setValidFilters({ columnKey, validFilters: [...filters] }));
		} catch (error) {
			console.error('error In ====>>>> saveFilter <<<<====', error);
			history.replace(URL.login);
		}
	};

	/******************************************************************************************
	@Purpose    :   To apply saved filter
	*******************************************************************************************/
	const applySavedFilter = (index, close) => {
		const newFilters = filterSettings[index].filterSettings;
		if (close) setOpen(!open);
		const colorEntity = colorOptions.find(colorOption => colorOption.value === filterSettings[index].color);
		setColor(colorEntity);
		setFilterId(filterSettings[index]._id);
		setFilterName(filterSettings[index].filterName);
		dispatch(setFilters({ columnKey, filters: newFilters }));
		dispatch(setValidFilters({ columnKey, validFilters: newFilters }));
	};

	/******************************************************************************************
	@Purpose    :   To delete saved filter
	*******************************************************************************************/
	const deleteSavedFilter = async (_id, index) => {
		try {
			console.log("_id ===>>>", _id);
			let tempFilterSettings = [...filterSettings];
			tempFilterSettings.splice(index, 1);
			await dispatch(apiCallBegan({ url: `${API.FILTER_SETTINGS}/${_id}`, method: 'delete', isAuthorized: true }));
			dispatch(setFilterSettings({ columnKey, filterSettings: tempFilterSettings }));
		} catch (error) {
			console.error('error In ====>>>> deleteSavedFilter <<<<====', error);
			history.replace(URL.login);
		}
	};

	return (
		<div ref={ref} className={open ? 'custom-dropdown filter-data-dropdown position-static ml-2 open' : 'custom-dropdown filter-data-dropdown position-static ml-2'}>
			<button onClick={() => setOpen(!open)} className='btn btn-default dropdown-toggle minW-md-0 btn-bg-white' type='button'>
				<i className='bx bx-filter d-lg-none'></i>
				<span className='d-none d-sm-none d-lg-inline-block'>Filter Data</span>
			</button>
			<div className='custom-dropdown-menu w-100 dropdown-icon'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-9'>
							<div className='row'>
								<div className='col-md-12'>
									<div className='form-repeat'>
										<form className='form repeater-default'>
											{filters.map((filter, i) => (
												<Fragment key={i}>
													<div className='field-form'>
														<div className='and-or-data text-center mt-2 mb-2'>
															{
																i >= 1 && <>
																	<button
																		type='button'
																		onClick={() => handleCondition('and', i)}
																		className={filter.condition === 'and' ? 'btn btn-outline-primary mr-3  condition-text' : 'btn btn-outline-primary mr-3'}
																	>AND</button>
																	<button
																		type='button'
																		onClick={() => handleCondition('or', i)}
																		className={filter.condition === 'or' ? 'btn btn-outline-primary condition-text' : 'btn btn-outline-primary'}
																	>OR</button>
																</>
															}
														</div>
														<div className='target-form'>
															<div className='target-details'>
																<div className='row'>
																	<div className='form-group col-md-6 col-lg-3 mb-3'>
																		<label className='text-left'>Select Field<sup className='text-danger'>*</sup></label>
																		<Select
																			className='w-100 custom-input filter-select'
																			value={filter.selectedField}
																			onChange={(option) => handleSelectField(option, i)}
																			options={columns}
																		/>
																		<span className='text-danger error-msg'>{filter.errors.selectedField}</span>
																	</div>
																	{!isEmpty(filter.selectedField.value) && (<div className='form-group col-md-6 col-lg-3 mb-3'>
																		<label className='text-left'>Select Type<sup className='text-danger'>*</sup></label>
																		{filter.selectedField.type === 'boolean' ? (<>
																			<Select
																				className='w-100 custom-input filter-select'
																				value={filter.values}
																				onChange={(option) => handleSelectValue(option, i)}
																				options={renderTypes(filter.selectedField)}
																			/>
																			<span className='text-danger error-msg'>{filter.errors.values}</span>
																		</>
																		) : (<>
																			<Select
																				className='w-100 custom-input filter-select'
																				value={filter.selectedType}
																				onChange={(option) => handleSelectType(option, i)}
																				options={renderTypes(filter.selectedField)}
																			/>
																			<span className='text-danger error-msg'>{filter.errors.selectedType}</span>
																		</>)}
																	</div>)}
																	{(!isEmpty(filter.selectedType.value) && !['in', 'nn'].includes(filter.selectedType.value)) && (
																		filter.selectedType.label === 'Between' ?
																			filter.selectedField.type === 'date' ? (
																				<>
																					<div className='form-group col-md-6 col-lg-3 mb-3'>
																						<label className='text-left'>From Date<sup className='text-danger'>*</sup></label>
																						<DateTime
																							className='w-100 custom-input filter-select'
																							inputProps={{ placeholder: 'From Date' }}
																							dateFormat={dateFormatUI}
																							timeFormat={false}
																							value={new Date(filter.values.greaterThan)}
																							onChange={(date) => handleGreaterThan(new Date(date), i)}
																							closeOnSelect={true}
																						/>
																						<span className='text-danger error-msg'>{filter.errors.values}</span>
																					</div>
																					<div className='form-group col-md-6 col-lg-3 mb-3'>
																						<label className='text-left'>To Date<sup className='text-danger'>*</sup></label>
																						<DateTime
																							className='w-100 custom-input filter-select'
																							inputProps={{ placeholder: 'To Date' }}
																							dateFormat={dateFormatUI}
																							timeFormat={false}
																							value={new Date(filter.values.lessThan)}
																							onChange={(date) => handleLessThan(new Date(date), i)}
																							closeOnSelect={true}
																						/>
																						<span className='text-danger error-msg'>
																							{filter.errors.values}
																						</span>
																					</div>
																				</>)
																				:
																				(<>
																					<div className='form-group col-md-6 col-lg-3 mb-3'>
																						<label className='text-left'>From<sup className='text-danger'>*</sup></label>
																						<input
																							type={filter.selectedField.type === 'number' ? 'number' : 'text'}
																							className='form-control'
																							placeholder='From'
																							value={filter.values.greaterThan}
																							onChange={(e) => handleGreaterThan(e.target.value, i)}
																						/>
																						<span className='text-danger error-msg'>
																							{filter.errors.values}
																						</span>
																					</div>
																					<div className='form-group col-md-6 col-lg-3 mb-3'>
																						<label className='text-left'>To<sup className='text-danger'>*</sup></label>
																						<input
																							type={filter.selectedField.type === 'number' ? 'number' : 'text'}
																							className='form-control'
																							placeholder='To'
																							value={filter.values.lessThan}
																							onChange={(e) => handleLessThan(e.target.value, i)}
																						/>
																						<span className='text-danger error-msg'>
																							{filter.errors.values}
																						</span>
																					</div>
																				</>)
																			: filter.selectedField.type === 'date' ? (
																				<div className='form-group col-md-6 col-lg-3 mb-3'>
																					<label className='text-left'>{filter.selectedType.label}<sup className='text-danger'>*</sup></label>
																					<div className='w-100 position-relative'>
																						<DateTime
																							className='w-100 custom-input filter-select'
																							inputProps={{ placeholder: filter.selectedType.label }}
																							dateFormat={dateFormatUI}
																							timeFormat={false}
																							value={new Date(filter.values.value)}
																							onChange={(date) => handleValue(new Date(date), i)}
																							closeOnSelect={true}
																						/>
																					</div>
																					<span className='text-danger error-msg'>
																						{filter.errors.values}
																					</span>
																				</div>)
																				: (
																					<div className='form-group col-md-6 col-lg-3 mb-3'>
																						<label className='text-left'>{filter.selectedType.label}<sup className='text-danger'>*</sup></label>
																						<input
																							type={filter.selectedField.type === 'number' ? 'number' : 'text'}
																							className='form-control'
																							placeholder={filter.selectedType.label}
																							value={filter.values.value}
																							onChange={(e) => handleValue(e.target.value, i)}
																						/>
																						<span className='text-danger error-msg'>
																							{filter.errors.values}
																						</span>
																					</div>
																				)
																	)}
																</div>
															</div>
															{filters.length !== 1 && <div className='add-row'>
																<Icon type='minus' onClick={(e) => removeField(e, i)} />
															</div>}
														</div>
													</div>
													<div className='add-row add-plus'>
														<Icon type='plus' onClick={(e) => addField(e)} />
													</div>
												</Fragment>
											))}
										</form>
										<div className='row'>
											<div className='col-md-12'>
												<div className='form-inline align-items-start filter-search mt-3'>
													<div className='form-group mr-sm-2 mb-2'>
														<button type='button' className='btn btn-primary' onClick={(event) => handleSubmit(event)}>Search</button>
													</div>
													<div className='form-group mx-sm-2 mb-2'>
														<button type='button' className='btn btn-secondary disabled' onClick={clearFilters}>Clear</button>
													</div>
													<form className='form-group mx-sm-2 mb-2' onSubmit={(e) => handleFormSubmit(e)}>
														<div>
															<div className='input-group'>
																<input
																	type='text'
																	name='filterName'
																	className='form-control'
																	value={filterName}
																	placeholder='Filter Name'
																	onChange={(e) => {
																		setFilterName(e.target.value);
																		setErrors({ ...errors, filterName: '' });
																	}}
																/>
																<div className='input-group-append'>
																	<button className='btn btn-default' type='submit'
																	>Save &amp; Search</button>
																</div>
															</div>
															<span className='error-block text-danger error-msg d-block text-left w-100 mt-1'>{errors.filterName}</span>
														</div>
													</form>
													<div className='form-group mx-sm-2 mb-2'>
														<Select
															className='custom-input color-dropdown color-box'
															placeholder='Select Color'
															style={{ width: 150 }}
															value={color}
															onChange={(option) => {
																setColor(option);
																setErrors({ ...errors, color: '' });
															}}
															options={colorOptions}
														/>
														<span className='error-block text-danger error-msg d-block text-left w-100 mt-1'>{errors.color}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-3'>
							<div className='label-container d-flex flex-wrap'>
								{
									filterSettings.length ? <>
										<div className='filter-title w-100'><h6>Saved Filters</h6></div>
										{filterSettings.map((filterSetting, i) => {
											return (
												<div className='d-flex align-items-center flex-wrap mr-3 mb-2' key={i}>
													<span className='filter-box user-save label d-flex align-items-center border rounded text-nowrap p-2 mr-2'
														onClick={() => applySavedFilter(i, true)}>
														<span className={`label-color-dot ${filterSetting.color} border rounded-circle`}></span>
														<span data-toggle='popover'>{filterSetting.filterName}</span>
													</span>
													<span onClick={() => applySavedFilter(i, false)} className='bx bx-edit text-primary cursor-pointer'></span>
													<span onClick={() => deleteSavedFilter(filterSetting._id, i)} className='bx bx-trash-alt text-danger cursor-pointer'></span>
												</div>
											)
										})}
									</> : <div className='filter-title'>No saved Filters</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default memo(Filters);