export const columnsOrdering = (list, columnSettings) => {
    let columns = [...list];
    columnSettings = columnSettings.filter(columnSetting => columnSetting.status);

    const swapArray = (array, from, to) => {
        let temp = array[from];
        array[from] = array[to];
        array[to] = temp;
        return array;
    };

    const moveArray = (items) => {
        for (let i = 0; i < items.length; i++) {
            const index = columnSettings.findIndex(columnSetting => columnSetting.label === items[i].label);
            if (index !== -1) {
                if (i - 1 !== index) {
                    columns = swapArray(columns, i, index + 1);
                    moveArray(columns);
                }
            }
            if (i === items.length - 1) return columns;
        }
    };

    return moveArray(columns);
};