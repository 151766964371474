//	Internals
import { setItems, setTotal, setColumnSettings, setFilterSettings, setTemplateSettings } from '../../../store/reducers/entities';
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import UpcomingPayoutsTable from './upcomingPayoutsTable';
import { usePrevious } from '../../../hooks/usePrevious';
import ValidFilters from '../../common/validFilters';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import SearchBox from '../../common/searchBox';
import withCommon from '../../hoc/withCommon';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
import Download from '../../common/download';
import Filters from '../../common/filters';
//	Externals
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
//	Pre-defined values
const columnKey = 'upcomingPayouts';
//	Component starts
const UpcomingPayouts = ({ handleColumnSettings }) => {
	//	Redux-store
	const dispatch = useDispatch();
	const { page, limit, searchText, validFilters, sort } = useSelector(state => state.entities[columnKey]);
	//  History
	const history = useHistory();
	//	Custom hooks
	const previousSearchText = usePrevious(searchText);
	//	State values
	const [totalAmount, setTotalAmount] = useState(0);

	/******************************************************************************************
	@Purpose    :   To get the upcoming payouts list
	*******************************************************************************************/
	const getUpcomingPayouts = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const body = { page, limit, columnKey };
			//	Search text
			if (previousSearchText !== searchText) body.searchText = searchText;
			//	Sorting
			if (!isEmpty(sort.path) && ([1, -1].includes(sort.order))) body.sort = { [sort.path]: sort.order };
			//	Filters
			if (!isEmpty(validFilters)) body.filters = validFilters;
			//	Get the response from API
			const response = await CS.callApi({ url: API.UPCOMING_PAYOUTS, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				const { total, data } = response;
				const { columnSettings, filterSettings, templateSettings, listing } = data;
				dispatch(setTotal({ columnKey, total }));
				dispatch(setColumnSettings({ columnKey, columnSettings }));
				dispatch(setFilterSettings({ columnKey, filterSettings }));
				dispatch(setTemplateSettings({ columnKey, templateSettings }));
				dispatch(setItems({ columnKey, items: listing.map(list => list) }));
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getUpcomingPayouts <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	}, [dispatch, history, limit, page, previousSearchText, searchText, sort.order, sort.path, validFilters]);

	/******************************************************************************************
	@Purpose    :   To get the and total amount
	*******************************************************************************************/
	const getPayoutData = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const body = {};
			//	Get the response from API
			const response = await CS.callApi({ url: API.PAYOUT_DATA, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				setTotalAmount(response.data.totalAmount.toFixed(2));
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getPayoutData <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	}, [dispatch, history]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getUpcomingPayouts(), [sort, page, limit, searchText, validFilters]);

	useEffect(() => getPayoutData(), [getPayoutData]);

	/******************************************************************************************
	@Purpose    :   Payout to restaurant
	*******************************************************************************************/
	const payOutToRestaurant = useCallback(async (restaurantId) => {
		try {
			dispatch(apiCallBegan());
			const body = { restaurantId };
			//	Get the response from API
			const response = await CS.callApi({ url: API.PAYOUT_TO_RESTAURANT, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				CS.showSuccessMessage(response.message);
				getUpcomingPayouts();
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> payOutToRestaurant <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	}, [dispatch, getUpcomingPayouts, history]);

	return (
		<>
			<p>Total Amount - {totalAmount}</p>
			<BreadCrumbs title='Payouts Management' data={[{ label: 'Upcoming Payouts' }]} />
			<div className='dataTables_wrapper'>
				<div className='content-area position-relative'>
					<div className='grid'>
						<div className='grid-content'>
							<div className='user-listing-filterOptions mb-2 mt-3 d-block'>
								<div className='row mb-2'>
									<div className='col-sm-8 mb-2 position-static'>
										<div className='left-buttons d-flex'>
											<SearchBox
												columnKey={columnKey}
												searchText={searchText}
											/>
											<Filters columnKey={columnKey} />
										</div>
									</div>
									<div className='col-sm-4'>
										<div className='right-buttons d-flex justify-content-end'>
											<Download columnKey={columnKey} />
										</div>
									</div>
								</div>
								<ValidFilters columnKey={columnKey} />
							</div>
							<UpcomingPayoutsTable
								columnKey={columnKey}
								onColumnSettings={handleColumnSettings}
								onPayoutToRestaurant={payOutToRestaurant}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withCommon({ component: UpcomingPayouts, columnKey });