//	Externals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import { setColumnSettings, setItems, setTotal } from '../../../store/reducers/entities';
import MultipleStatusSelect from '../../common/multipleStatusSelect';
import MultipleDeleteBox from '../../common/multipleDeleteBox';
import { usePrevious } from '../../../hooks/usePrevious';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import HelpTopicsTable from './helpTopicsTable';
import SearchBox from '../../common/searchBox';
import withCommon from '../../hoc/withCommon';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
import Modal from '../../common/Modal';
import AddUpdate from './addUpdate';
//	Internals
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
//	Pre-defined values
const columnKey = 'helpTopics';
//	Component starts
const HelpTopics = ({ selectAll, setSelectAll, handleSelectSingle, handleSelectAll, handleColumnSettings, handleChangeStatus, handleMultipleStatus, handleDelete, handleMultipleDelete }) => {
	//	Redux-store
	const dispatch = useDispatch();
	const { page, limit, searchText, items, sort } = useSelector(state => state.entities[columnKey]);
	//  History
	const history = useHistory();
	//	Custom hooks
	const previousSearchText = usePrevious(searchText);
	//	State values
	const [helpTopic, setHelpTopic] = useState({});
	const [open, setOpen] = useState(false);
	//	Manipulate the data
	const checked = items.map(item => item.checked).includes(true);

	/******************************************************************************************
	@Purpose    :   To get the help topics list
	*******************************************************************************************/
	const getHelpTopics = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const body = { page, limit, columnKey };
			//	Search text
			if (previousSearchText !== searchText) body.searchText = searchText;
			//	Sorting
			if (!isEmpty(sort.path) && ([1, -1].includes(sort.order))) body.sort = { [sort.path]: sort.order };
			//	Get the response from API
			const response = await CS.callApi({ url: API.HELP_TOPICS, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				const { data } = response;
				const { columnSettings, listing, total } = data;
				//	Store the Data in Redux
				dispatch(setItems({ columnKey, items: listing.map(list => { list.checked = false; return list; }) }));
				dispatch(setColumnSettings({ columnKey, columnSettings }));
				dispatch(setTotal({ columnKey, total }));
				//	Store the Data in local state
				setSelectAll(false);
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getHelpTopics <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	}, [dispatch, page, limit, previousSearchText, searchText, sort.path, sort.order, setSelectAll, history]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getHelpTopics(), [page, limit, searchText, sort]);

	/******************************************************************************************
	@Purpose    :   To close the modal
	*******************************************************************************************/
	const closeModal = useCallback((reload) => {
		setHelpTopic({});
		setOpen(false);
		if (reload === true) getHelpTopics();
	}, [getHelpTopics]);

	return (
		<>
			<BreadCrumbs
				title='Content Management'
				data={[{ label: 'Help' }, { label: 'Help Topics' }]} />
			<div className='dataTables_wrapper'>
				<div className='content-area position-relative'>
					<div className='grid'>
						<div className='grid-content'>
							<div className='user-listing-filterOptions mb-2 mt-3 d-block'>
								<div className='row mb-2'>
									<div className='col-sm-8 mb-2 position-static'>
										<div className='left-buttons d-flex'>
											<SearchBox
												columnKey={columnKey}
												searchText={searchText}
											/>
											{checked && <>
												<MultipleDeleteBox onClick={handleMultipleDelete} />
												<MultipleStatusSelect onChange={handleMultipleStatus} />
											</>}
										</div>
									</div>
									<div className='col-sm-4'>
										<div className='right-buttons d-flex justify-content-end'>
											<button
												onClick={() => setOpen(true)}
												id='addNewHelp'
												type='button'
												className='btn glow-primary btn-primary'
											>
												<i className='bx bx-user-plus d-lg-none' />
												<span className='d-none d-sm-none d-lg-inline-block'>Add Help Topic</span>
											</button>
										</div>
									</div>
								</div>
							</div>
							<Modal
								open={open}
								onCloseModal={closeModal} >
								<AddUpdate
									columnKey={columnKey}
									helpTopic={helpTopic}
									onCloseModal={closeModal}
								/>
							</Modal>
							<HelpTopicsTable
								setOpen={setOpen}
								columnKey={columnKey}
								selectAll={selectAll}
								onDelete={handleDelete}
								setHelpTopic={setHelpTopic}
								onSelectAll={handleSelectAll}
								onSelectSingle={handleSelectSingle}
								onChangeStatus={handleChangeStatus}
								onColumnSettings={handleColumnSettings}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withCommon({ component: HelpTopics, columnKey });