//	Internals
import Table from '../../common/table';
//	Externals
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
//	Components starts
const PayoutOrders = ({ columnKey }) => {
	//	Pre-defined values
	const columns = [
		{
			label: 'Order Id',
			type: 'string',
			sort: true,
			path: 'orderNumber'
		},
		{
			label: 'Date',
			type: 'date',
			sort: true,
			path: 'createdAt',
			content: (item) => moment(item.createdAt).format('DD-MM-YYYY')
		},
		{
			label: 'Amount',
			type: 'boolean',
			sort: true,
			path: 'amount'
		}
	];

	return (
		<Table
			columns={columns}
			columnKey={columnKey}
			showColumnSettings={false}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	slug: PropTypes.string.isRequire,
	columnKey: PropTypes.string.isRequire
};

export default memo(PayoutOrders);