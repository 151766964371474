//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
import * as MSG from '../../../constants/MSG';
import Icon from '../../icons/index';
//  Externals
import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
//	Components starts
const AddUpdate = () => {
    //	Params
    const { slug } = useParams();
    //	History
    const history = useHistory();
    //	Redux-store
    const dispatch = useDispatch();
    //	State values
    const [errors, setErrors] = useState({ descriptions: '', pageTitle: '', pageId: '' });
    const [descriptions, setDescriptions] = useState(['']);
    let [pageTitle, setPageTitle] = useState('');
    let [pageId, setPageId] = useState('');

    /******************************************************************************************
    @Purpose    :   To handle descriptions
    *******************************************************************************************/
    const handleDescriptions = (i, data) => {
        descriptions[i] = data;
        setDescriptions([...descriptions]);
    };

    /******************************************************************************************
    @Purpose    :   Add description
    *******************************************************************************************/
    const handleAdd = () => {
        descriptions.push('');
        setDescriptions([...descriptions]);
    };

    /******************************************************************************************
    @Purpose    :   Remove description
    *******************************************************************************************/
    const handleRemove = (i) => {
        descriptions.splice(i, 1);
        setDescriptions([...descriptions]);
    };

    /******************************************************************************************
    @Purpose    :   To get the cms details
    *******************************************************************************************/
    const getDetails = useCallback(async () => {
        try {
            dispatch(apiCallBegan());
            const response = await CS.callApi({ url: `${API.CMS}/${slug}`, isAuthorized: true });
            if (response.status === 1) {
                const { data } = response;
                //	Store the Data in local state
                setDescriptions(data.descriptions);
                setPageTitle(data.pageTitle);
                setPageId(data.pageId);
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> getDetails <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    }, [dispatch, history, slug]);

    useEffect(() => {
        if (slug !== 'add') getDetails();
        dispatch(resetLoading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, slug]);

    /******************************************************************************************
    @Purpose    :   To validate form
    *******************************************************************************************/
    const validateForm = () => {
        pageTitle = pageTitle.trim();
        pageId = pageId.trim();
        //  Page title
        if (isEmpty(pageTitle)) errors.pageTitle = MSG.REQUIRED_PAGE_TITLE;
        else errors.pageTitle = '';
        //  Meta title
        if (isEmpty(pageId)) errors.pageId = MSG.REQUIRED_META_TITLE;
        else errors.pageId = '';
        //  Description
        const index = descriptions.indexOf('');
        if (index > -1 || isEmpty(descriptions)) errors.descriptions = MSG.REQUIRED_DESCRIPTION;
        else errors.descriptions = '';
        //  Errors
        setErrors({ ...errors });
        return isEmpty(errors.pageTitle) && isEmpty(errors.pageId) && isEmpty(errors.descriptions) ? true : false;
    };

    /******************************************************************************************
    @Purpose    :   To update CMS
    *******************************************************************************************/
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        try {
            dispatch(apiCallBegan());
            const body = { descriptions, pageTitle, pageId };
            let url = API.CMS;
            let method = 'post';
            if (slug !== 'add') {
                url = `${API.CMS}/${slug}`;
                method = 'put';
            }
            const response = await CS.callApi({ url, body, method, isAuthorized: true });
            if (response.status) {
                CS.showSuccessMessage(response.message);
                history.replace(URL.CMS);
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> Add-Update CMS <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    };

    return (
        <form onSubmit={(event) => handleSubmit(event)}>
            {slug === 'add' ? <BreadCrumbs
                title={'Content Management'}
                data={[{ label: 'CMS', path: URL.CMS }, { label: 'Add CMS' }]}
            /> : <BreadCrumbs
                title={'Content Management'}
                data={[{ label: 'CMS', path: URL.CMS }, { label: 'Update CMS' }]}
            />}
            <div className="blog-content-block mr-0">
                <div className="card card-media">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-md-5 mb-3">
                                        <label htmlFor="pageTitle">Page Title<sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pageTitle"
                                            name="pageTitle"
                                            placeholder="Page Title"
                                            value={pageTitle}
                                            maxLength="70"
                                            onChange={(e) => {
                                                setPageTitle(e.target.value);
                                                setPageId(e.target.value.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase());
                                            }}
                                        />
                                        <span className="text-danger d-block">{errors.pageTitle}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {<div className="form-group mb-md-5 mb-3">
                                        <label htmlFor="pageId">Meta Title<sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pageId"
                                            name="pageId"
                                            placeholder="Meta Title"
                                            value={pageId}
                                            disabled
                                            onChange={(e) => setPageId(e.target.value)}
                                        />
                                        <span className="text-danger d-block">{errors.pageId}</span>
                                    </div>}
                                </div>
                            </div>
                            <div className="form-group mb-md-5 mb-3">
                                <label htmlFor="descriptions">descriptions<sup className="text-danger">*</sup></label>
                                {descriptions.map((description, i) => (
                                    <Fragment key={i}>
                                        <textarea
                                            rows="6"
                                            className="form-control"
                                            placeholder="Describe this a bit"
                                            id={i}
                                            name={i}
                                            value={description}
                                            onChange={(e) => handleDescriptions(i, e.target.value)}
                                        />
                                        {isEmpty(description) && <span className="text-danger d-block">{errors.descriptions}</span>}
                                        <div className="add-description text-center my-4">
                                            <div className="inner-content">
                                                {descriptions.length > 1 && <Icon type='minus' onClick={() => handleRemove(i)} />}
                                                {descriptions.length === (i + 1) && <Icon type='plus' onClick={() => handleAdd()} />}
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-3">
                        <div className="form-group">
                            <div className="button-group-container">
                                <button
                                    className="btn btn-primary mr-3 mb-sm-0 mb-2"
                                    type='submit'
                                >{slug === 'add' ? 'Add' : 'Update'}</button>
                                <Link
                                    className="btn btn-outline-primary"
                                    to={URL.CMS}
                                >Cancel</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default AddUpdate;