//	Internals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import { setItems, setDefaultPagination } from '../../../store/reducers/entities';
import CS from '../../../services/commonService';
import CropImage from '../../common/cropImage';
import * as API from '../../../constants/API';
import * as MSG from '../../../constants/MSG';
//	Externals
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import DateTime from 'react-datetime';
import Select from 'react-dropdown';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
//	Component starts
const AddUpdate = ({ coupon, columnKey, onCloseModal }) => {
	//	Pre-defined values
	if (isEmpty(coupon)) coupon = { featured: true, offered: true, status: true, shortDescription: '', discountRate: 10, description: '', startDate: new Date(), position: '', endDate: new Date(), photo: '', title: '', code: '' };
	const dateFormatUI = 'DD-MM-YYYY';
	const timeFormatUI = 'h:mm A';
	const statusOptions = [
		{ value: true, label: 'Active' },
		{ value: false, label: 'Inactive' }
	];
	const offeredOptions = [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' }
	];
	const featuredOptions = [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' }
	];
	const defaultErrors = { featured: '', offered: '', status: '', shortDescription: '', discountRate: '', description: '', startDate: '', position: '', endDate: '', photo: '', title: '', code: '' };
	//	Redux-store
	const dispatch = useDispatch();
	const { items } = useSelector((state) => state.entities[columnKey]);
	//  History
	const history = useHistory();
	//	State values
	const [featured, setFeatured] = useState(featuredOptions.find((option) => option.value === coupon.featured));
	const [offered, setOffered] = useState(offeredOptions.find((option) => option.value === coupon.offered));
	const [status, setStatus] = useState(statusOptions.find((option) => option.value === coupon.status));
	const [shortDescription, setShortDescription] = useState(coupon.shortDescription);
	const [discountRate, setDiscountRate] = useState(coupon.discountRate);
	const [description, setDescription] = useState(coupon.description);
	const [startDate, setStartDate] = useState(coupon.startDate);
	const [position, setPosition] = useState(coupon.position);
	const [endDate, setEndDate] = useState(coupon.endDate);
	const [errors, setErrors] = useState(defaultErrors);
	const [photo, setPhoto] = useState(coupon.photo);
	let [title, setTitle] = useState(coupon.title);
	let [code, setCode] = useState(coupon.code);
	const [id] = useState(coupon._id);

	/******************************************************************************************
	@Purpose    :   To validate form
	*******************************************************************************************/
	const validateForm = () => {
		title = title.trim();
		code = code.trim();
		//	Description
		if (isEmpty(description)) errors.description = MSG.REQUIRED_DESCRIPTION;
		else errors.description = '';
		//	Discount rate
		if (!discountRate) errors.discountRate = MSG.REQUIRED_DISCOUNT_RATE;
		else if (!(discountRate <= 100 && discountRate >= 0)) errors.discountRate = MSG.REQUIRED_PROPER_DISCOUNT_RATE;
		else errors.discountRate = '';
		//	Start date
		if (!startDate || isNaN(new Date(startDate).getTime())) errors.startDate = MSG.REQUIRED_START_DATE_AND_TIME;
		else errors.startDate = '';
		//	End date
		if (!endDate || isNaN(new Date(endDate).getTime())) errors.endDate = MSG.REQUIRED_END_DATE_AND_TIME;
		else if (isEmpty(id) && moment(endDate).diff(moment(startDate)) <= 0) errors.endDate = MSG.REQUIRED_PROPER_END_DATE_AND_TIME;
		else errors.endDate = '';
		//	Position	
		if (!position) errors.position = MSG.REQUIRED_POSITION;
		else errors.position = '';
		//  Photo
		if (isEmpty(photo)) errors.photo = MSG.REQUIRED_LOGO;
		else errors.photo = '';
		//	Title
		if (isEmpty(title)) errors.title = MSG.REQUIRED_COUPON_NAME;
		else errors.title = '';
		//	Code
		if (isEmpty(code)) errors.code = MSG.REQUIRED_CODE;
		else errors.code = '';
		//	Errors
		setErrors({ ...errors });
		return isEmpty(errors.startDate) && isEmpty(errors.endDate) && isEmpty(errors.position) && isEmpty(errors.discountRate) && isEmpty(errors.title) && isEmpty(errors.code) ? true : false;
	};

	/******************************************************************************************
	@Purpose    :   To add-update coupon
	*******************************************************************************************/
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		try {
			dispatch(apiCallBegan());
			const body = { code, title, photo, description, shortDescription, endDate, position, startDate, discountRate, offered: offered.value, status: status.value, featured: featured.value };
			let url = API.COUPON;
			let method = 'post';
			if (!isEmpty(id)) {
				url = `${API.COUPON}/${id}`;
				method = 'put';
			}
			const response = await CS.callApi({ url, body, method, isAuthorized: true });
			if (response.status) {
				CS.showSuccessMessage(response.message);
				if (isEmpty(id)) await dispatch(setDefaultPagination({ columnKey }));
				else {
					const updatedItems = items.map((item) => {
						const entity = { ...item };
						if (item._id === id) {
							entity.discountRate = discountRate;
							entity.featured = featured.value;
							entity.offered = offered.value;
							entity.status = status.value;
							entity.startDate = startDate;
							entity.position = position;
							entity.endDate = endDate;
							entity.title = title;
							entity.code = code;
						}
						return entity;
					});
					dispatch(setItems({ columnKey, items: updatedItems }));
				}
				await onCloseModal(isEmpty(id));
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> Add-Update Coupon <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	};

	/******************************************************************************************
	@Purpose    :   To file upload
	*******************************************************************************************/
	const handleFileUpload = async (file) => {
		const fileData = new FormData();
		fileData.append('file', file);
		try {
			dispatch(apiCallBegan());
			const response = await CS.callApi({ url: API.FILE_UPLOAD, body: fileData, method: 'post', isAuthorized: true, isForm: true });
			if (response.status === 1) setPhoto(response.data.filePath);
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> handleFileUpload <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	};

	return (
		<div className='modal-dialog modal-dialog-scrollable m-0 w-100'>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title'>{isEmpty(id) ? 'Add Coupon' : 'Update Coupon'}</h5>
				</div>
				<div className='notification-form modal-body card profile-card password-modal'>
					<div className='row'>
						<div className='col-md-12'>
							<form onSubmit={(event) => handleSubmit(event)}>
								<div className='mb-md-4 mb-3'>
									<div className='form-group upload-user-img mb-0'>
										<label className='label xl-label'>Logo<sup className='text-danger'>*</sup></label>
										<div className='edit-image ml-0'>
											<div className='user-image'>
												{CS.renderImageUrl(photo)}
												<label className='img-upload' htmlFor='attach-file'>
													<CropImage onFileUpload={handleFileUpload} />
													<em className='bx bxs-edit-alt' />
												</label>
											</div>
										</div>
										<div className='error-message-password'>
											<span className='text-danger error-msg d-block mb-2'>{errors.photo}</span>
										</div>
									</div>
								</div>
								<div className='mb-md-4 mb-3'>
									<div className='form-group mb-0'>
										<label className='label xl-label'>
											Coupon Name<sup className='text-danger'>*</sup>
										</label>
										<div className='w-100 position-relative'>
											<input
												className='form-control'
												type='text'
												id='title'
												name='title'
												placeholder='Coupon Name'
												value={title}
												onChange={(e) => {
													setTitle(e.target.value);
													setErrors({ ...errors, title: '' });
												}}
											/>
											<div className='error-message-password'>
												<span className='text-danger error-msg d-block mb-2'>{errors.title}</span>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-md-4 mb-3'>
									<div className='form-group mb-0'>
										<label className='label xl-label'>
											Code<sup className='text-danger'>*</sup>
										</label>
										<div className='w-100 position-relative'>
											<input
												className='form-control'
												type='text'
												id='code'
												name='code'
												placeholder='Code'
												value={code}
												onChange={(e) => {
													setCode(e.target.value);
													setErrors({ ...errors, code: '' });
												}}
											/>
											<div className='error-message-password'>
												<span className='text-danger error-msg d-block mb-2' >{errors.code}</span>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-md-4 mb-3'>
									<div className='form-group mb-0'>
										<label className='label xl-label'>
											Description<sup className='text-danger'>*</sup>
										</label>
										<div className='w-100 position-relative'>
											<textarea
												rows='3'
												className='form-control'
												placeholder='Description'
												id='description'
												name='description'
												value={description}
												onChange={(e) => {
													setDescription(e.target.value);
													setErrors({ ...errors, description: '' });
												}}
											/>
											<div className='error-message-password'>
												<span className='text-danger error-msg d-block mb-2' >{errors.description}</span>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-md-4 mb-3'>
									<div className='form-group mb-0'>
										<label className='label xl-label'>Short Description</label>
										<div className='w-100 position-relative'>
											<textarea
												rows='6'
												className='form-control'
												placeholder='Short Description'
												id='shortDescription'
												name='shortDescription'
												value={shortDescription}
												onChange={(e) => {
													setShortDescription(e.target.value);
													setErrors({ ...errors, shortDescription: '' });
												}}
											/>
											<div className='error-message-password'>
												<span className='text-danger error-msg d-block mb-2' >{errors.shortDescription}</span>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-3'>
									<div className='row'>
										<div className='col-md-6 mb-md-0 mb-3'>
											<div className='form-group mb-0'>
												<label className='label xl-label'>
													Start Date & Time<sup className='text-danger'>*</sup>
												</label>
												<div className='w-100 position-relative date-icon'>
													<DateTime
														className='d-block w-100'
														inputProps={{ placeholder: 'Start Date & Time' }}
														dateFormat={dateFormatUI}
														timeFormat={timeFormatUI}
														value={new Date(startDate)}
														onChange={(date) => setStartDate(new Date(date))}
														closeOnSelect={true}
														isValidDate={current => current.isAfter(moment().subtract(1, 'day'))}
													/>
													<div className='error-message-password'>
														<span className='text-danger error-msg d-block mb-2'>{errors.startDate}</span>
													</div>
												</div>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group mb-0'>
												<label className='label xl-label date-label'>
													End Date & Time<sup className='text-danger'>*</sup>
												</label>
												<div className='w-100 position-relative'>
													<DateTime
														className='d-block w-100'
														inputProps={{ placeholder: 'End Date & Time' }}
														dateFormat={dateFormatUI}
														timeFormat={timeFormatUI}
														value={new Date(endDate)}
														onChange={(date) => setEndDate(new Date(date))}
														closeOnSelect={true}
														isValidDate={current => current.isAfter(moment(startDate).subtract(1, 'day'))}
													/>
													<div className='error-message-password'>
														<span className='text-danger error-msg d-block mb-2'>{errors.endDate}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-md-4 mb-3'>
									<div className='form-group mb-0'>
										<label className='label xl-label'>
											Discount Rate<sup className='text-danger'>*</sup>
										</label>
										<div className='w-100 position-relative'>
											<input
												className='form-control'
												type='text'
												id='discountRate'
												name='discountRate'
												placeholder='Discount Rate'
												value={discountRate}
												onChange={(e) => {
													(CS.allowNumbers(e.target.value) || e.target.value === '') && setDiscountRate(e.target.value);
													setErrors({ ...errors, discountRate: '' });
												}}
											/>
											<div className='error-message-password'>
												<span className='text-danger error-msg d-block mb-2'>{errors.discountRate}</span>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-md-4 mb-3'>
									<div className='form-group mb-0'>
										<label className='label xl-label'>
											Position<sup className='text-danger'>*</sup>
										</label>
										<div className='w-100 position-relative'>
											<input
												className='form-control'
												type='text'
												id='position'
												name='position'
												placeholder='Position'
												value={position}
												onChange={(e) => {
													(CS.allowNumbers(e.target.value) ||
														e.target.value === '') &&
														setPosition(Number(e.target.value));
													setErrors({ ...errors, position: '' });
												}}
											/>
											<div className='error-message-password'>
												<span className='text-danger error-msg d-block mb-2'>{errors.position}</span>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-md-4 mb-3'>
									<div className='form-group mb-0'>
										<label className='label xl-label'>
											Offers<sup className='text-danger'>*</sup>
										</label>
										<div className='w-100 position-relative'>
											<Select
												value={offered}
												onChange={(option) => setOffered(option)}
												options={offeredOptions}
											/>
										</div>
									</div>
								</div>
								<div className='mb-md-4 mb-3'>
									<div className='form-group mb-0'>
										<label className='label xl-label'>
											Featured<sup className='text-danger'>*</sup>
										</label>
										<div className='w-100 position-relative'>
											<Select
												value={featured}
												onChange={(option) => setFeatured(option)}
												options={featuredOptions}
											/>
										</div>
									</div>
								</div>
								{!isEmpty(id) && (
									<div className='mb-md-4 mb-3'>
										<div className='form-group mb-0'>
											<label className='label xl-label'>
												Status<sup className='text-danger'>*</sup>
											</label>
											<div className='w-100 position-relative'>
												<Select
													value={status}
													onChange={(option) => setStatus(option)}
													options={statusOptions}
												/>
											</div>
										</div>
									</div>
								)}
								<div className='mt-5 text-right'>
									<button className='btn btn-primary' type='submit'>
										{isEmpty(id) ? 'Add' : 'Update'}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	coupon: PropTypes.object,
	columnKey: PropTypes.string.isRequired,
	onCloseModal: PropTypes.func.isRequired,
};

export default memo(AddUpdate);