import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from './middleware/logger';
import toast from './middleware/toast';
import api from './middleware/api';
import reducer from './reducer';

const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [
		...getDefaultMiddleware({ serializableCheck: false }),
		logger({}),
		api,
		toast
	]
});

export const persistor = persistStore(store);