//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../store/reducers/loading';
import { setTemplateSettings } from '../../store/reducers/entities';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import CS from '../../services/commonService';
import * as API from '../../constants/API';
import * as MSG from '../../constants/MSG';
import * as URL from '../../constants/URL';
//  Externals
import React, { useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-dropdown';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
//	Pre-defined values
const colorOptions = [
    {
        label: <span className='dropdown-item' href='#'><span className='color-shade bg-gray'></span><span className='color-text'>Gray</span></span>,
        value: 'gray'
    },
    {
        label: <span className='dropdown-item' href='#'><span className='color-shade bg-yellow'></span><span className='color-text'>Yellow</span></span>,
        value: 'yellow'
    },
    {
        label: <span className='dropdown-item' href='#'><span className='color-shade bg-green'></span><span className='color-text'>Green</span></span>,
        value: 'green'
    },
    {
        label: <span className='dropdown-item' href='#'><span className='color-shade bg-purple'></span><span className='color-text'>Purple</span></span>,
        value: 'purple'
    },
    {
        label: <span className='dropdown-item' href='#'><span className='color-shade bg-rose'></span><span className='color-text'>Rose</span></span>,
        value: 'rose'
    },
    {
        label: <span className='dropdown-item' href='#'><span className='color-shade bg-skyBlue'></span><span className='color-text'>Sky Blue</span></span>,
        value: 'skyBlue'
    }
];
//  Component starts
const Download = ({ columnKey }) => {
    //	Redux-store
    const dispatch = useDispatch();
    //	History
    const history = useHistory();
    const { columnSettings, templateSettings } = useSelector(state => state.entities[columnKey]);
    //	State values
    const [errors, setErrors] = useState({ columns: '', templateName: '', color: '' });
    let [templateName, setTemplateName] = useState('');
    const [templateId, setTemplateId] = useState('');
    const [columns, setColumns] = useState([]);
    const [color, setColor] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => setColumns(columnSettings), [columnSettings]);

    /******************************************************************************************
    @Purpose    :   To handle the check box
    *******************************************************************************************/
    const handleCheckbox = (index) => {
        const tempColumns = columns.map((column, i) => {
            let obj = { ...column };
            if (i === index) obj = { ...obj, status: !obj.status };
            return obj;
        });
        setColumns([...tempColumns]);
        setErrors({ ...errors, columns: '' });
    };

    /******************************************************************************************
    @Purpose    :   To validate form
    *******************************************************************************************/
    const validateForm = () => {
        templateName = templateName.trim();
        //  Template name
        if (isEmpty(templateName)) errors.templateName = MSG.REQUIRED_TEMPLATE_NAME;
        else errors.templateName = '';
        //  Color
        if (isEmpty(color)) errors.color = MSG.REQUIRED_COLOR;
        else errors.color = '';
        if (columns.map(column => column.status).includes(true)) errors.columns = '';
        else errors.columns = MSG.REQUIRED_COLUMNS;
        //  Errors
        setErrors({ ...errors });
        return isEmpty(errors.templateName) && isEmpty(errors.columns) && isEmpty(errors.color) ? true : false;
    };

    /******************************************************************************************
    @Purpose    :   To add-update template form
    *******************************************************************************************/
    const handleFormSubmit = async () => {
        if (!validateForm()) return;
        try {
            const body = { columnSettings: columns, columnKey, templateName, color: color.value };
            let url = API.TEMPLATE_SETTINGS;
            let method = 'post';
            if (!isEmpty(templateId)) {
                url = `${API.TEMPLATE_SETTINGS}/${templateId}`;
                method = 'put';
            }
            const response = await CS.callApi({ url, body, method, isAuthorized: true });
            if (response.status === 1) {
                dispatch(setTemplateSettings({ columnKey, templateSettings: [...response.data] }));
                handleReset();
            }
            else CS.showErrorMessage(response.message);
        } catch (error) {
            console.error('error In ====>>>> handleFormSubmit <<<<====', error);
            history.replace(URL.login);
        }
    };

    /******************************************************************************************
    @Purpose    :   To reset the form
    *******************************************************************************************/
    const handleReset = () => {
        setColumns([...columnSettings]);
        setTemplateName('');
        setTemplateId('');
        setColor('');
    };

    /******************************************************************************************
    @Purpose    :   To apply the filter
    *******************************************************************************************/
    const applySavedTemplate = (index, close) => {
        const { columnSettings: tempColumns } = templateSettings[index];
        if (close) setOpen(!open);
        const colorEntity = colorOptions.find(colorOption => colorOption.value === templateSettings[index].color);
        setTemplateName(templateSettings[index].templateName);
        setTemplateId(templateSettings[index]._id);
        setColumns([...tempColumns]);
        setColor(colorEntity);
    };

    /******************************************************************************************
    @Purpose    :   To delete the filter
    *******************************************************************************************/
    const deleteSavedTemplate = async (_id, index) => {
        try {
            let tempTemplateSettings = [...templateSettings];
            tempTemplateSettings.splice(index, 1);
            await dispatch(apiCallBegan({ url: `${API.TEMPLATE_SETTINGS}/${_id}`, method: 'delete', isAuthorized: true }));
            dispatch(setTemplateSettings({ columnKey, templateSettings: tempTemplateSettings }));
        } catch (error) {
            console.error('error In ====>>>> deleteSavedTemplate <<<<====', error);
            history.replace(URL.login);
        }
    };

    /******************************************************************************************
    @Purpose    :   To validate the download form
    *******************************************************************************************/
    const validateDownloadForm = () => {
        if (columns.map(column => column.status).includes(true)) errors.columns = '';
        else errors.columns = MSG.REQUIRED_COLUMNS;
        //  Errors
        setErrors({ ...errors });
        return isEmpty(errors.columns) ? true : false;
    };

    /******************************************************************************************
    @Purpose    :   To download the file
    *******************************************************************************************/
    const downloadFile = async (type) => {
        if (!validateDownloadForm()) return;
        try {
            dispatch(apiCallBegan());
            const body = { columnKey, columnSettings: columns, templateName };
            let url = API.DOWNLOAD_EXCEL;
            if (type === 'csv') url = API.DOWNLOAD_CSV
            const response = await CS.callApi({ url, body, method: 'post', isAuthorized: true });
            if (response.status === 1) window.open(response.data, '_blank');
            else CS.showErrorMessage(response.message);
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> downloadFile <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
        setOpen(!open);
        handleReset();
    };

    // Create a ref that we add to the element for which we want to detect outside clicks
    const ref = useRef();
    const onClose = useCallback(() => setOpen(false), []);
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(ref, onClose);
    return (
        <div ref={ref} className={open ? 'custom-dropdown download-dropdown dropdown download-btn open' : 'custom-dropdown download-dropdown dropdown download-btn'}>
            <button
                onClick={() => {
                    if (open) { setErrors({ columns: '', templateName: '', color: '' }) }
                    setOpen(!open)
                }}
                className='btn btn-default dropdown-toggle minW-md-0 btn-bg-white'
                type='button'
                data-toggle='dropdown'
                aria-expanded='false'
            >
                <i className='bx bx-download d-lg-none'></i>
                <span className='d-none d-sm-none d-lg-inline-block'>Download</span>
            </button>
            <div className='pt-3 pr-3 pb-3 pl-0 download-dropdown-menu'>
                <div className='container-fluid'>
                    <div className='row flex-nowrap'>
                        <div className='left-col p-0'>
                            <span className='error-block error-msg text-danger d-block text-left p-2'>{errors.columns}</span>
                            {columns.map((column, i) => {
                                return (
                                    <div className='list-group-item' key={i}>
                                        <div className='custom-checkbox'>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    checked={column.status}
                                                    onChange={() => handleCheckbox(i)}
                                                /><span></span>
                                                {column.label}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='right-col p-0 border-top-0 border-bottom-0 border-right-0 border pl-3'>
                            <div className='row m-0'>
                                <div className='col-left'>
                                    <div className='input-group mb-3'>
                                        <input
                                            className='form-control'
                                            type='text'
                                            value={templateName}
                                            name='templateName'
                                            placeholder='Template Name'
                                            onChange={(e) => {
                                                setTemplateName(e.target.value);
                                                setErrors({ columns: '', templateName: '' });
                                            }}
                                        />
                                        <div className='input-group-append'>
                                            <button className='btn btn-primary' type='button' onClick={handleFormSubmit}>Save Template</button>
                                        </div>
                                    </div>
                                    <span className='error-block error-msg  text-danger d-block text-left'>{errors.templateName}</span>
                                </div>
                                <div className='col-right'>
                                    <Select
                                        className='custom-input color-dropdown color-box w-100'
                                        placeholder='Select Color'
                                        style={{ width: 150 }}
                                        value={color}
                                        onChange={(option) => {
                                            setColor(option);
                                            setErrors({ ...errors, color: '' });
                                        }}
                                        options={colorOptions}
                                    />
                                    <span className='error-block error-msg  text-danger d-block text-left'>{errors.color}</span>
                                </div>
                            </div>
                            <div className='row m-0 flex-column'>
                                <div className='scrollable template-list-outer right-col-list'>
                                    <div className='label-container d-flex flex-wrap'>
                                        {
                                            templateSettings.length ? templateSettings.map((templateSetting, i) => {
                                                return (
                                                    <div className='d-flex align-items-center mr-3 mb-2' key={i}>
                                                        <span className='filter-box border filter-label d-flex align-items-center rounded text-nowrap p-2 mr-2'>
                                                            <span className={`label-color-dot ${templateSetting.color} border rounded-circle`}></span>
                                                            <span
                                                                data-toggle='popover'
                                                                onClick={() => applySavedTemplate(i)}>{templateSetting.templateName}</span>
                                                        </span>
                                                        <span onClick={() => applySavedTemplate(i)} className='bx bx-edit text-primary cursor-pointer'></span>
                                                        <span className='bx bx-trash-alt text-danger cursor-pointer' onClick={() => deleteSavedTemplate(templateSetting._id, i)}></span>
                                                    </div>
                                                )
                                            })
                                                : <div className='filter-title'>No Saved Templates</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                <div className='bottom-buttons border-left-0 border-bottom-0 border-right-0 border mt-2 mb-2 pt-3'>
                                    <button onClick={() => downloadFile('excel')} type='button' className='btn border mr-2' >
                                        <span className='mr-2'><img className='img-fluid' src='/assets/images/excel-icon.svg' alt='Excel' /></span>
                                        Excel
                                        <span className='bx bx-download ml-2'></span>
                                    </button>
                                    <button onClick={() => downloadFile('csv')} type='button' className='btn border'>
                                        <span className='mr-2' ><img className='img-fluid' src='/assets/images/csv-icon.svg' alt='Excel' /></span>
                                        CSV
                                        <span className='bx bx-download ml-2'></span>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    columnKey: PropTypes.string.isRequired
};

export default Download;