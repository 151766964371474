//	Internals
import SingleStatusToggle from '../../common/singleStatusToggle';
import { columnsOrdering } from '../../../util/columnsOrdering';
import CS from '../../../services/commonService';
import * as URL from '../../../constants/URL';
import CheckBox from '../../common/checkBox';
import Table from '../../common/table';
import Icon from '../../icons/index';
//	Externals
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
//	Component starts
const UsersTable = ({ columnKey, selectAll, onDelete, onSelectAll, onSelectSingle, onChangeStatus, onColumnSettings }) => {
	//	Pre-defined values
	const columns = [
		{
			label: <CheckBox checked={selectAll} onChange={onSelectAll} />,
			type: 'checkbox',
			content: (item) => <CheckBox checked={item.checked} onChange={() => onSelectSingle(item._id)} />
		},
		{
			label: 'Joined Date',
			type: 'date',
			sort: true,
			path: 'createdAt',
			content: (item) => moment(item.createdAt).format('DD-MM-YYYY')
		},
		{
			label: 'User Name',
			type: 'string',
			path: 'name',
			sort: true,
			content: (item) => <Link to={`${URL.users}/${item._id}`} >
				<div className="d-flex align-items-center">
					<div className="flex-auto">{CS.renderImageUrl(item.photo)}</div>
					<div className="d-flex flex-column flex-wrap">
						<span className='pl-2'>{item.name}</span>
					</div>
				</div>
			</Link>
		},
		{
			label: 'Email',
			type: 'string',
			sort: true,
			path: 'emailId',
			content: (item) => <div className='email-col d-flex align-items-center'>
				<div className='badge-outer circle round d-flex align-items-center mr-2'>
					<span className={item.emailVerificationStatus ? 'badge badge-success circle' : 'badge badge-danger circle'}></span>
				</div>
				<span>{item.emailId}</span>
			</div>
		},
		{
			label: 'No. of Orders',
			type: 'number',
			sort: true,
			path: 'ordersCount'
		},
		{
			label: 'Phone No',
			type: 'string',
			sort: true,
			path: 'phoneNumber'
		},
		{
			label: 'Status',
			type: 'boolean',
			content: (item) => <SingleStatusToggle _id={item._id} status={item.status} onToggleChange={() => onChangeStatus([item._id], !item.status)} htmlFor={item._id} />
		},
		{
			label: 'Action',
			type: 'boolean',
			content: (item) => <>
				<Icon
					type='show'
					data-tip
					data-for='show'
					onClick={() => history.push(`${URL.users}/${item._id}`)}
				/>
				<ReactTooltip
					id='show'
					place='top'
					effect='solid'>Show</ReactTooltip>
				<Icon
					type='delete'
					data-tip
					data-for='delete'
					onClick={() => onDelete([item._id])} />
				<ReactTooltip
					id='delete'
					place='top'
					effect='solid'>Delete</ReactTooltip>
			</>
		}
	];
	//	History
	const history = useHistory();
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	//	Manipulate the data
	const availableColumns = columns.filter(column => {
		const entity = columnSettings.find(c => c.label === column.label);
		if (isEmpty(entity)) return column;
		if (entity.status) return column;
		return null;
	});
	const updatedColumns = columnsOrdering(availableColumns, columnSettings);

	return (
		<Table
			columnKey={columnKey}
			columns={updatedColumns}
			showColumnSettings={true}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	onDelete: PropTypes.func.isRequired,
	selectAll: PropTypes.bool.isRequired,
	columnKey: PropTypes.string.isRequired,
	onSelectAll: PropTypes.func.isRequired,
	onSelectSingle: PropTypes.func.isRequired,
	onChangeStatus: PropTypes.func.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default memo(UsersTable);