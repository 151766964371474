//	Internals
import SingleStatusToggle from '../../common/singleStatusToggle';
import { columnsOrdering } from '../../../util/columnsOrdering';
import CheckBox from '../../common/checkBox';
import Table from '../../common/table';
import Icon from '../../icons/index';
//	Externals
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
//	Componet starts
const CategoriesTable = ({ setOpen, columnKey, selectAll, onDelete, setCategory, onSelectAll, onSelectSingle, onChangeStatus, onColumnSettings }) => {
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	//	Pre-defined values
	const columns = [
		{
			label: <CheckBox checked={selectAll} onChange={onSelectAll} />,
			type: 'checkbox',
			content: (item) => <CheckBox checked={item.checked} onChange={() => onSelectSingle(item._id)} />
		},
		{
			label: 'Created On',
			type: 'date',
			sort: true,
			path: 'createdAt',
			content: (item) => moment(item.createdAt).format('DD-MM-YYYY')
		},
		{
			label: 'Category Name',
			type: 'string',
			sort: true,
			path: 'title',
			content: (item) => <a href='#' onClick={() => { setOpen(true); setCategory(item); }}>{item.title}</a>
		},
		{
			label: 'Status',
			type: 'boolean',
			content: (item) => <SingleStatusToggle _id={item._id} status={item.status} onToggleChange={() => onChangeStatus([item._id], !item.status)} htmlFor={item._id} />
		},
		{
			label: 'Action',
			type: 'boolean',
			content: (item) => <>
				<Icon
					type='edit'
					data-tip
					data-for='edit'
					onClick={() => { setOpen(true); setCategory(item); }} />
				<ReactTooltip
					id='edit'
					place='top'
					effect='solid'>Edit</ReactTooltip>
				<Icon
					type='delete'
					data-tip
					data-for='delete'
					onClick={() => onDelete([item._id])} />
				<ReactTooltip
					id='delete'
					place='top'
					effect='solid'>Delete</ReactTooltip>
			</>
		}
	];

	/******************************************************************************************
	@Purpose    :   To available columns
	*******************************************************************************************/
	const availableColumns = columns.filter(column => {
		const entity = columnSettings.find(c => c.label === column.label);
		if (isEmpty(entity)) return column;
		if (isEmpty(entity) || entity.status) return column;
		return null;
	});
	//	Manipulate the data
	const updatedColumns = columnsOrdering(availableColumns, columnSettings);

	return (
		<Table
			columnKey={columnKey}
			columns={updatedColumns}
			showColumnSettings={true}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	setOpen: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	selectAll: PropTypes.bool.isRequired,
	columnKey: PropTypes.string.isRequired,
	setCategory: PropTypes.func.isRequired,
	onSelectAll: PropTypes.func.isRequired,
	onSelectSingle: PropTypes.func.isRequired,
	onChangeStatus: PropTypes.func.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default memo(CategoriesTable);