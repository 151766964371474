import { combineReducers } from 'redux';
import language from './reducers/language';
import entities from './reducers/entities';
import loading from './reducers/loading';
import auth from './reducers/auth';

export default combineReducers({
	auth,
	language,
	loading,
	entities
});