//  Externals
import React from 'react';
//  Component starts
const Details = ({ query: { query, message } }) => {
    return (
        <div className='modal-content'>
            <div className='modal-header'>
                <h5 className='modal-title'>Message</h5>
            </div>
            <div className='notification-form modal-body card profile-card password-modal'>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item px-0 pt-0">
                        <h5 className="card-title mb-1">Query</h5>
                        <p className="card-text">{query}</p>
                    </li>
                    <li className="list-group-item px-0">
                        <h5 className="card-title mb-1">Message</h5>
                        <p className="card-text">{message}</p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Details;