//  Internals
import { resetLoading } from '../../store/reducers/loading';
import CS from '../../services/commonService';
import * as API from '../../constants/API';
import * as MSG from '../../constants/MSG';
import * as URL from '../../constants/URL';
import Theme from '../common/theme';
//  Externals
import { useHistory, useLocation } from 'react-router';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
//	Component starts
const ResetPassword = () => {
	//	Redux-store
	const dispatch = useDispatch();
	//	Location
	const location = useLocation();
	// 	History
	const history = useHistory();
	//	State values
	const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
	const [hideNewPassword, setHideNewPassword] = useState(true);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [token, setToken] = useState('');
	//	Errors
	const [errors, setErrors] = useState({ newPassword: '', confirmPassword: '' });

	useEffect(() => {
		dispatch(resetLoading());
		const { token: resetPasswordToken } = queryString.parse(location.search);
		setToken(resetPasswordToken);
	}, [location, dispatch]);

	/******************************************************************************************
	@Purpose    :   To validate the reset password form
	*******************************************************************************************/
	const validateForm = () => {
		//	New password
		if (isEmpty(newPassword)) errors.newPassword = MSG.REQUIRED_PASSWORD;
		else if (!CS.validatePassword(newPassword)) errors.newPassword = MSG.PROVIDE_PROPER_PASSWORD;
		else errors.newPassword = '';
		//	Confirm password
		if (isEmpty(confirmPassword)) errors.confirmPassword = MSG.REQUIRED_PASSWORD;
		else if (!CS.validatePassword(confirmPassword)) errors.confirmPassword = MSG.PROVIDE_PROPER_PASSWORD;
		else errors.confirmPassword = '';
		//  Errors
		setErrors({ ...errors });
		return (isEmpty(errors.confirmPassword) && isEmpty(errors.newPassword)) ? true : false;
	};

	/******************************************************************************************
	@Purpose    :   To submit the reset password form
	*******************************************************************************************/
	const handleSubmit = async (event) => {
		event.preventDefault();
		const body = { password: newPassword, token };
		if (!validateForm()) return;
		const response = await CS.callApi({ url: API.RESET_PASSWORD, body, method: 'post' });
		if (response.status === 1) {
			CS.showSuccessMessage(response.message);
			history.replace(URL.login);
		}
	};

	/******************************************************************************************
	@Purpose    :   To show the tooltip for new password show/hid
	*******************************************************************************************/
	const renderTooltipForNewPassword = () => hideNewPassword ? 'Show Password' : 'Hide Password';

	/******************************************************************************************
	@Purpose    :   To show the tooltip for old password show/hid
	*******************************************************************************************/
	const renderToolTipForOldPassword = () => hideConfirmPassword ? 'Show Password' : 'Hide Password';

	return (
		<>
			<div className='login-wrapper' style={{ background: 'url(assets/images/login-bg.jpg) no-repeat center center', backgroundSize: 'cover' }}>
				<div className='login-body'>
					<section className='login-container row m-0'>
						<div className='col-xl-8 col-11'>
							<div className='card bg-login-card'>
								<div className='row m-0'>
									<div className='col-md-6 col-12 pl-0 pr-0'>
										<div className='card rounded-left mb-0 p-4 d-flex justify-content-center h-100'>
											<div className='card-header'>
												<div className='card-title text-center mb-4'>
													<h4 className='mb-2 mt-2'>Reset Your Password</h4>
												</div>
											</div>
											<div className='card-content'>
												<div className='card-body'>
													<form autoComplete='off' onSubmit={(e) => handleSubmit(e)}>
														<div className='form-group position-relative'>
															<label className='label'>New Password<sup className='text-danger'>*</sup></label>
															<input
																className='form-control'
																type={hideNewPassword ? 'password' : 'text'}
																placeholder='New Password'
																id='newPassword'
																name='newPassword'
																value={newPassword}
																onChange={(e) => {
																	setNewPassword(e.target.value);
																	setErrors({ ...errors, newPassword: '' });
																}}
															/>
															<span
																onClick={() => setHideNewPassword(!hideNewPassword)}
																data-tip
																data-for='newPassword'
																className={hideNewPassword ? 'bx bx-hide field-icon toggle-password' : 'bx bx-show field-icon toggle-password'}
															></span>
															<ReactTooltip
																id='newPassword'
																place='top'
																effect='solid'>{renderTooltipForNewPassword()}</ReactTooltip>
															<span className='text-danger error-msg'>{errors.newPassword}</span>
														</div>
														<div className='form-group position-relative'>
															<label className='label'>Confirm Password<sup className='text-danger'>*</sup></label>
															<input
																className='form-control'
																type={hideConfirmPassword ? 'password' : 'text'}
																placeholder='Confirm Password'
																id='confirmPassword'
																name='confirmPassword'
																value={confirmPassword}
																onChange={(e) => {
																	setConfirmPassword(e.target.value);
																	setErrors({ ...errors, confirmPassword: '' });
																}}
															/>
															<span
																onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
																data-tip
																data-for='confirmPassword'
																className={hideConfirmPassword ? 'bx bx-hide field-icon toggle-password' : 'bx bx-show field-icon toggle-password'}
															></span>
															<ReactTooltip
																id='confirmPassword'
																place='top'
																effect='solid'>{renderToolTipForOldPassword()}</ReactTooltip>
															<span className='text-danger error-msg'>{errors.confirmPassword}</span>
														</div>
														<div className='mt-5 text-center'>
															<button className='btn btn-primary glow position-relative btn-block' type='submit' >Reset Password</button>
														</div>
													</form>
													<div className=' d-flex flex-md-row flex-column justify-content-between align-items-center mt-4 mb-3'>
														<div className='text-left common-small-text'>
															<small> <Link to={URL.login}>Sign in</Link></small>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-md-6 d-md-block d-none text-center align-self-center p-7'>
										<div className='card-content'>
											<picture>
												<source srcSet='/assets/images/forgot-password.webp' type='image/webp' />
												<source srcSet='/assets/images/forgot-password.png' type='image/png' />
												<img className='img-fluid' src='/assets/images/forgot-password.png' alt='forgot-password' width={300} />
											</picture>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<Theme />
		</>
	);
}

export default ResetPassword;