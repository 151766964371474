//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
import * as MSG from '../../../constants/MSG';
import Editor from '../../common/editor';
//  Externals
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
//  Component starts
const EmailTemplateAddUpdate = () => {
    //	Params
    const { slug } = useParams();
    //	History
    const history = useHistory();
    //	Redux-store
    const dispatch = useDispatch();
    //	State values
    const [errors, setErrors] = useState({ subject: '', description: '', emailTitle: '', emailKey: '' });
    const [description, setDescription] = useState('');
    let [subject, setSubject] = useState('');
    let [emailTitle, setEmailTitle] = useState('');
    let [emailKey, setEmailKey] = useState('');
    /******************************************************************************************
    @Purpose    :   To get the email template details
    *******************************************************************************************/
    const getDetails = useCallback(async () => {
        try {
            dispatch(apiCallBegan());
            const response = await CS.callApi({ url: `${API.EMAIL_TEMPLATE}/${slug}`, isAuthorized: true });
            if (response.status === 1) {
                const { data } = response;
                //	Store the Data in local state
                setSubject(data.subject);
                setDescription(data.emailContent);
                setEmailTitle(data.emailTitle);
                setEmailKey(data.emailKey);
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> getDetails <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    }, [dispatch, history, slug]);

    useEffect(() => {
        if (slug !== 'add') getDetails();
        dispatch(resetLoading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, slug]);

    /******************************************************************************************
    @Purpose    :   To validate form
    *******************************************************************************************/
    const validateForm = () => {
        subject = subject.trim();
        emailTitle = emailTitle.trim();
        emailKey = emailKey.trim();
        //  Subject
        if (isEmpty(subject)) errors.subject = MSG.REQUIRED_SUBJECT;
        else errors.subject = '';
        //  Email title
        if (isEmpty(emailTitle)) errors.emailTitle = MSG.REQUIRED_EMAIL_TITLE;
        else errors.emailTitle = '';
        //  Email key
        if (isEmpty(emailKey)) errors.emailKey = MSG.REQUIRED_EMAIL_KEY;
        else errors.emailKey = '';
        //  Description
        if (isEmpty(description)) errors.description = MSG.REQUIRED_DESCRIPTION;
        else errors.description = '';
        //  Errors
        setErrors({ ...errors });
        return isEmpty(errors.subject) && isEmpty(errors.emailTitle) && isEmpty(errors.emailKey) && isEmpty(errors.description) ? true : false;
    };
    /******************************************************************************************
    @Purpose    :   To update email template
    *******************************************************************************************/
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        try {
            dispatch(apiCallBegan());
            const body = { subject, emailContent: description, emailTitle, emailKey };
            let url = API.EMAIL_TEMPLATE;
            let method = 'post';
            if (slug !== 'add') {
                url = `${API.EMAIL_TEMPLATE}/${slug}`;
                method = 'put';
            }
            const response = await CS.callApi({ url, body, method, isAuthorized: true });
            if (response.status) {
                CS.showSuccessMessage(response.message);
                history.replace(URL.emailTemplates);
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> Add-Update Email templates <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    };

    return (
        <form onSubmit={(event) => handleSubmit(event)}>
            {slug === 'add' ? <BreadCrumbs
                title={'Email Templates'}
                data={[{ label: 'Email Templates', path: URL.emailTemplates }, { label: 'Add Email Template' }]}
            /> : <BreadCrumbs
                title={'Email Templates'}
                data={[{ label: 'Email Templates', path: URL.emailTemplates }, { label: 'Update Email Templates' }]}
            />}
            <div className="blog-content-block w-100">
                <div className="card card-media">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group mb-md-5 mb-3">
                                <label htmlFor="emailTitle">Email Title<sup className="text-danger">*</sup></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="emailTitle"
                                    name="emailTitle"
                                    placeholder="Email Title"
                                    value={emailTitle}
                                    maxLength="70"
                                    onChange={(e) => setEmailTitle(e.target.value)}
                                />
                                <span className="text-danger d-block">{errors.emailTitle}</span>
                            </div>
                            <div className="form-group mb-md-5 mb-3">
                                <label htmlFor="emailKey">Email Key<sup className="text-danger">*</sup></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="emailKey"
                                    name="emailKey"
                                    placeholder="Email Key"
                                    disabled={slug !== 'add'}
                                    value={emailKey}
                                    onChange={(e) => setEmailKey(e.target.value)}
                                />
                                <span className="text-danger d-block">{errors.emailKey}</span>
                            </div>
                            <div className="form-group mb-md-5 mb-3">
                                <label htmlFor="subject">Subject<sup className="text-danger">*</sup></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subject"
                                    name="subject"
                                    placeholder="Subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                                <span className="text-danger d-block">{errors.subject}</span>
                            </div>
                        </div>
                    </div>
                    <div className="content-img-block">
                        <div className="img-drag-section">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-0">
                                        <label htmlFor="caption">Content<sup className="text-danger">*</sup></label>
                                        <Editor
                                            description={description}
                                            setDescription={setDescription}
                                        />
                                        <span className="text-danger d-block">{errors.description}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-3">
                        <div className="form-group">
                            <div className="button-group-container">
                                <button
                                    className="btn btn-primary mr-3 mb-sm-0 mb-2"
                                    type='submit'
                                >{slug === 'add' ? 'Add' : 'Update'}</button>
                                <Link
                                    className="btn btn-outline-primary"
                                    to={URL.emailTemplates}
                                >Cancel</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default EmailTemplateAddUpdate;