//  Externals
import ReactCrop from 'react-image-crop';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
//  Internals
import Modal from './Modal';

const CropImage = ({ onFileUpload }) => {

    const [src, setSrc] = useState(null);
    const [open, setOpen] = useState(false);
    const [imageRef, setImageRef] = useState(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '50px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    /******************************************************************************************
    @Purpose    :   To close model
    *******************************************************************************************/
    const closeModal = () => {
        setSrc(null);
        setOpen(false);
        setImageRef(null);
        setCrop({ unit: '%', width: 30, aspect: 1 });
    };

    /******************************************************************************************
    @Purpose    :   To handle the file
    *******************************************************************************************/
    const handleFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () =>
                setSrc(fileReader.result)
            );
            fileReader.readAsDataURL(e.target.files[0]);
        }
        setOpen(true);
        e.target.value = '';
    };

    /******************************************************************************************
    @Purpose    :   To handle file submit
    *******************************************************************************************/
    const handleSubmit = () => getCroppedImg();

    /******************************************************************************************
    @Purpose    :   To image load on crop
    *******************************************************************************************/
    // If you setState the crop in here you should return false.
    const onImageLoaded = async image => {
        if (!imageRef) setImageRef(image);
        // console.log('--------------------- ON IMAGE LOADED ---------------------');
    };

    /******************************************************************************************
    @Purpose    :   To handle the crop change
    *******************************************************************************************/
    const onCropChange = (dupCrop, percentCrop) => setCrop({ ...percentCrop });

    /******************************************************************************************
    @Purpose    :   To handle the crop complete
    *******************************************************************************************/
    const onCropComplete = async dupCrop => {
        // console.log('--------------------- CROP COMPLETED ---------------------', imageRef);
        setCrop({ ...dupCrop });
    };

    /******************************************************************************************
    @Purpose    :   To get the cropped image
    *******************************************************************************************/
    const getCroppedImg = () => {
        const canvas = document.createElement('canvas');
        const scaleX = imageRef.naturalWidth / imageRef.width;
        const scaleY = imageRef.naturalHeight / imageRef.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            imageRef,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        const reader = new FileReader();
        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
                await dataURLtoFile(reader.result);
            };
        });
    };

    /******************************************************************************************
    @Purpose    :   To convert date to file
    *******************************************************************************************/
    const dataURLtoFile = async (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const imageUrl = await new File([u8arr], `${Date.now()}.jpeg`, { type: mime });
        onFileUpload(imageUrl);
        closeModal();
    };

    return (
        <>
            <input
                type='file'
                name='image'
                id='attach-file'
                accept='image/*'
                data-title='Select File'
                onChange={handleFile} />
            {src && (
                <Modal open={open} styles={customStyles} onCloseModal={() => closeModal()}>
                    <div className='modal-header w-100'>
                        <h5 className='modal-title'>Crop Image</h5>
                    </div>
                    <ReactCrop
                        src={src}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onChange={onCropChange}
                        onComplete={onCropComplete}
                    />
                    <div className='text-right w-100 mt-3 mb-3 mr-5'>
                        <div className='text-right'>
                            <button className='btn btn-primary' type='button' onClick={() => handleSubmit()} >Upload</button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    onFileUpload: PropTypes.func.isRequired
};

export default CropImage;