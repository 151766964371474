/*************************************************************
@Purpose    :   Error messages
*************************************************************/
module.exports = {
    REQUIRED_QUERY: '*Required query',
    REQUIRED_CODE: '*Code is required',
    REQUIRED_TYPE: '*Type is required',
    REQUIRED_LOGO: '*Logo is required.',
    REQUIRED_FIELD: '*Field is required',
    REQUIRED_COLOR: '*Color is required',
    REQUIRED_EMAIL: '*Email is required.',
    REQUIRED_VALUE: '*Value is required.',
    REQUIRED_SUBJECT: '*Subject is required',
    LOGOUT_SUCCESS: 'Logged out successfully',
    REQUIRED_COLUMNS: '*Columns are required.',
    REQUIRED_POSITION: '*Position is required.',
    REQUIRED_PASSWORD: '*Password is required.',
    REQUIRED_EMAIL_KEY: '*Email key is required',
    REQUIRED_LAST_NAME: '*Last name is required.',
    REQUIRED_PAGE_TITLE: '*Page title is required',
    REQUIRED_META_TITLE: '*Meta title is required',
    REQUIRED_SECRET_KEY: '*Secret key is required',
    REQUIRED_GLOBAL_TAX: '*Global tax is required',
    REQUIRED_FIRST_NAME: '*First name is required.',
    REQUIRED_BRAND_NAME: '*Brand name is required.',
    REQUIRED_EMAIL_TITLE: '*Email title is required',
    REQUIRED_FILTER_NAME: '*Filter name is required',
    REQUIRED_COLUMN_NAME: '*Column name is required',
    REQUIRED_DESCRIPTION: '*Description is required',
    REQUIRED_PUBLISH_KEY: '*Publish key is required',
    REQUIRED_COUPON_NAME: '*Coupon name is required.',
    REQUIRED_CUISINE_NAME: '*Cuisine name is required',
    PROVIDE_MOBILE_NUMBER: '*Mobile number is required',
    REQUIRED_OLD_PASSWORD: '*Old password is required.',
    REQUIRED_NEW_PASSWORD: '*New password is required.',
    REQUIRED_TEMPLATE_NAME: '*Template name is required',
    REQUIRED_DISCOUNT_RATE: '*Discount rate is required',
    REQUIRED_CATEGORY_NAME: '*Category name is required.',
    REQUIRED_OPA_COMMISSION: '*Opa commission is required',
    REQUIRED_PROPER_EMAIL: '*Required proper proper email.',
    REQUIRED_PAYOUT_CYCLE: '*Payout cycle days are required',
    PHOTO_REMOVED_SUCCESSFULLY: 'Photo removed successfully',
    REQUIRED_RESTAURANT_NAME: '*Restaurant name is required.',
    REQUIRED_REFERRAL_POINTS: '*Referral points are required',
    PHOTO_UPLOADED_SUCCESSFULLY: 'Photo uploaded successfully',
    USER_UPDATED_SUCCESSFULLY: 'User data updated successfully',
    REQUIRED_END_DATE_AND_TIME: '*End date & time is required.',
    REQUIRED_GLOBAL_COMMISSION: '*Global commission is required',
    REQUIRED_OPA_DEBIT_CARD_TAX: '*Opa debit card tax is required',
    REQUIRED_OPA_CREDIT_CARD_TAX: '*Opa credit card tax is required',
    REQUIRED_START_DATE_AND_TIME: '*Start date & time is required.',
    REQUIRED_WALLET_REWARD_DEBIT: 'Wallet reward debit is required',
    REQUIRED_WALLET_REWARD_CREDIT: 'Wallet reward credit is required',
    REQUIRED_PROPER_MOBILE_NUMBER: '*Proper mobile number is required',
    REQUIRED_DEFAULT_TOP_UP_VALUES: "Default TopUp values are required",
    REQUIRED_REFERRAL_REWARD_POINT: '*Referral reward points are required',
    REQUIRED_PROPER_DISCOUNT_RATE: 'Discount should be equal or between to 0 and 100',
    REQUIRED_PROPER_END_DATE_AND_TIME: 'End date and time should be grater than the start date',
    PROVIDE_PROPER_PASSWORD: `* Password should contain at least a special character (@,$,& etc), a capital letter and a number and length should be between 6-20`
};