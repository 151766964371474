/*************************************************************************************
	C O M M O N - S E R V I C E S
**************************************************************************************/
//  Externals
import { isEmpty } from 'lodash';
import swal from 'sweetalert';
import axios from 'axios';
import React from 'react';
//	Internals
import { API_URL, IMG_URL } from '../config/configs.js';

const commonService = {

	/*************************************************************************************
	@purpose	:	Store Data To local Storage
	**************************************************************************************/
	setItem(key, value) {
		localStorage.setItem(key, value);
	},

	/*************************************************************************************
	@purpose	:	Get Data From local Storage
	**************************************************************************************/
	getItem(key) {
		return localStorage.getItem(key);
	},

	/*************************************************************************************
	@purpose	:	Remove Data in local Storage
	**************************************************************************************/
	removeItem(key) {
		localStorage.removeItem(key);
	},

	/*************************************************************************************
	@purpose	:	Email Validation
	**************************************************************************************/
	validateEmail(email) {
		const pattern = new RegExp(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return pattern.test(email);
	},

	/*************************************************************************************
	@purpose	:	Password Validation
	**************************************************************************************/
	validatePassword(pass) {
		const pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/);
		return pattern.test(pass);
	},

	/*************************************************************************************
	@purpose	:	First and Last Name Validation
	**************************************************************************************/
	validateMobileNumber(format, mobileNumber) {
		return (format.match(/\./g) || []).length === mobileNumber.match(/\d/g).length;
	},

	/*************************************************************************************
	@purpose	:	Character Validation
	**************************************************************************************/
	allowChar(e) {
		const pattern = new RegExp(/^[a-zA-Z\s]{0,255}$/);
		return pattern.test(e);
	},

	/*************************************************************************************
	@purpose	:	Number Validation
	**************************************************************************************/
	allowNumbers(e) {
		const pattern = new RegExp(/^[0-9\b]+$/);
		return pattern.test(e);
	},

	/*************************************************************************************
	@purpose	:	Set Success Message
	**************************************************************************************/
	showSuccessMessage(msg) {
		swal({
			position: 'center',
			icon: 'success',
			text: msg,
			buttons: false,
			timer: 2500,
			className: 'custom-toaster'
		});
	},

	/*************************************************************************************
	@purpose	:	Set Error Message
	**************************************************************************************/
	showErrorMessage(msg) {
		swal({
			position: 'center',
			icon: 'error',
			text: msg,
			buttons: false,
			timer: 3000,
			className: 'custom-toaster'
		});
	},

	/*************************************************************************************
	@purpose	:	Set Warning Message
	**************************************************************************************/
	showWarningMessage(msg) {
		swal({
			position: 'center',
			icon: 'warning',
			text: msg,
			buttons: false,
			timer: 3000,
			className: 'custom-toaster'
		});
	},

	/*************************************************************************************
	@purpose	:	Get full image url
	**************************************************************************************/
	renderImageUrl(path) {
		let src = '/assets/images/default-user.png';
		if (!isEmpty(path)) src = IMG_URL + path;
		return <img className="round" src={src} alt="avatar" height="40" width="40" />;
	},

	/*******************************************************************************************
	@PURPOSE    : Call api.
	@Parameters :	{
						url : <url of api>
						data : <data object (JSON)>
						method : String (get, post)
						isForm (Optional) : Boolean - to call api with form data
						isPublic (Optional) : Boolean - to call api without auth header
					}
	/*****************************************************************************************/
	callApi({ url, body, method, isForm = false, isAuthorized = false }) {
		let errorMessage = '';
		//	Interceptors -- Start
		axios.interceptors.response.use(null, error => {
			// const expectedError =
			// 	error.response &&
			// 	error.response.status >= 400 &&
			// 	error.response.status < 500;
			console.log("error ===>>>", error);
			console.log("error.response.status ===>>>", error.response.status);
			if (error.response.status === 401) {
				this.setItem('accessToken', '');
				this.showErrorMessage('Session expired');
			}
			if (error.response.status === 404) {
				this.showErrorMessage('Network error');
			} else {
				errorMessage = error.response.data.message;
				if (typeof error.response.data.message === 'string') this.showErrorMessage(errorMessage);
				else this.showErrorMessage(errorMessage[0].msg);
				console.error('error In ====>>>> axios.interceptors <<<<====', error);
			}
			return Promise.reject(error);
		});
		//	Interceptors -- End
		if (body === '') body = {};
		const headers = { 'Content-Type': 'application/json' };
		if (isAuthorized) headers.Authorization = this.getItem('accessToken');
		if (isForm) headers['Content-Type'] = 'multipart/form-data';
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios({ baseURL: API_URL, url, method, headers, data: body });
				const { data } = response;
				if (data.status) {
					if (url === '/admin/login') this.setItem('accessToken', data.data.accessToken);
					return resolve(data);
				}
				if (data.status === 0) this.showErrorMessage(data.message);
				return resolve(data);
			} catch (error) {
				console.error('error In ====>>>> callApi <<<<====', error);
				return reject(error);
			}
		});
	}
	//	End
};

export default commonService;