//	Internals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import { setItems, setTotal } from '../../../store/reducers/entities';
import { usePrevious } from '../../../hooks/usePrevious';
import CS from '../../../services/commonService';
import SearchBox from '../../common/searchBox';
import Table from '../../common/table';
//	Externals
import React, { useEffect, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
//	Component starts
const TabsTable = ({ slug, columnKey, columns, API }) => {
    //	Redux-store
    const dispatch = useDispatch();
    const { page, limit, sort, searchText } = useSelector(state => state.entities[columnKey]);
    //	Custom hooks
    const previousSearchText = usePrevious(searchText);

    /******************************************************************************************
    @Purpose    :   To get the list of items
    *******************************************************************************************/
    const getListOfItems = useCallback(async () => {
        try {
            dispatch(apiCallBegan());
            const body = { page, limit, columnKey, restaurantId: slug };
            //	Search text
            if (previousSearchText !== searchText) body.searchText = searchText;
            //	Sorting
            if (!isEmpty(sort.path) && ([1, -1].includes(sort.order))) body.sort = { [sort.path]: sort.order };
            //	Get the response from API
            const response = await CS.callApi({ url: API, body, method: 'post', isAuthorized: true });
            if (response.status === 1) {
                const { data } = response;
                const { listing, total } = data;
                dispatch(setItems({ columnKey, items: listing }));
                dispatch(setTotal({ columnKey, total }));
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> getListOfItems <<<<====', error);
            dispatch(apiCallFailed());
        }
    }, [dispatch, page, limit, columnKey, slug, previousSearchText, searchText, sort.path, sort.order, API]);

    useEffect(() => {
        if (!isEmpty(slug)) getListOfItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, searchText, sort, slug]);

    return (
        <div className='dataTables_wrapper'>
            <div className='content-area position-relative'>
                <div className='grid'>
                    <div className='grid-content'>
                        <div className='user-listing-filterOptions mb-2 mt-3 d-block'>
                            <div className='row mb-2'>
                                <div className='col-sm-8 mb-2 position-static'>
                                    <div className='left-buttons d-flex'>
                                        <SearchBox
                                            columnKey={columnKey}
                                            searchText={searchText}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            columnKey={columnKey}
                            showColumnSettings={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    slug: PropTypes.string.isRequire
};

export default memo(TabsTable);