//	Internals
import { columnsOrdering } from '../../../util/columnsOrdering';
import CS from '../../../services/commonService';
import Table from '../../common/table';
//	Externals
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
//	Component starts
const PayoutsHistoryTable = ({ columnKey, onColumnSettings }) => {
	//	Pre-defined values
	const columns = [
		{
			label: '', status: false
		},
		{
			label: 'Date',
			type: 'date',
			sort: true,
			path: 'createdAt',
			content: (item) => moment(item.createdAt).format('DD-MM-YYYY')
		},
		{
			label: 'Transaction Id',
			type: 'string',
			sort: true,
			path: 'transferId'
		},
		{
			label: 'Amount',
			type: 'date',
			sort: true,
			path: 'amountPaid'
		},
		{
			label: 'Restaurant Info',
			type: 'string',
			sort: true,
			path: 'restaurantInfo.restaurantName',
			content: (item) => <>
				<span>{CS.renderImageUrl(item.photo)}</span>
				<span className='pl-2'>{item.restaurantName}</span>
			</>
		},
		// {
		// 	label: 'Bank/Card',
		// 	type: 'string',
		// 	sort: false,
		// 	path: 'phoneNo',
		// 	content: (item) => <div className="d-flex align-items-center">
		// 		<div className="d-flex flex-column flex-wrap">
		// 			<span className='pl-2 user-head'>{item.restaurantInfo[0].bankDetails.bankname}</span>
		// 			<span className='pl-2'>{item.restaurantInfo[0].bankDetails.accountno}</span>
		// 		</div>
		// 	</div>
		// }
	];
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	//	Manipulate the data
	// const availableColumns = columns.filter(column => {
	// 	const entity = columnSettings.find(c => c.label === column.label);
	// 	if (isEmpty(entity)) return column;
	// 	if (entity.status) return column;
	// 	return null;
	// });
	const updatedColumns = columns;

	return (
		<Table
			columnKey={columnKey}
			columns={columns}
			showColumnSettings={false}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	columnKey: PropTypes.string.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default PayoutsHistoryTable;