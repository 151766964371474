import { createSlice } from '@reduxjs/toolkit';

const page = 1;
const limit = 10;
const sort = { path: '', order: '' };
const searchText = '';
const defaultFilters = [{
    selectedField: { label: 'Select Field', value: '', path: '' },
    selectedType: {},
    values: { value: '', greaterThan: '', lessThan: '' },
    condition: 'and',
    errors: { selectedField: '', selectedType: '', values: '' }
}];

const common = { page, limit, sort, searchText, filters: defaultFilters, defaultFilters, validFilters: [], total: 0, items: [], filterSettings: [], columnSettings: [], templateSettings: [] };

const slice = createSlice({
    name: 'entities',
    initialState: {
        users: { name: 'Users', ...common },
        userOrders: { name: 'Orders', ...common },
        userGiftOrders: { name: 'Gift Orders', ...common },
        userHelpQueries: { name: 'Help Queries Users', ...common },
        restaurants: { name: 'Restaurants', ...common },
        restaurantMenuItems: { name: 'Menu Items', ...common },
        restaurantOrders: { name: 'Orders', ...common },
        restaurantGiftOrders: { name: 'Gift Orders', ...common },
        restaurantHelpQueries: { name: 'Help Queries Restaurants', ...common },
        orders: { name: 'Orders', ...common },
        giftOrders: { name: 'Gift Orders', ...common },
        upcomingPayouts: { name: 'Upcoming Payouts', ...common },
        payoutOrdersTable: { name: 'Orders', ...common },
        payoutsHistory: { name: 'Payouts History', ...common },
        cms: { name: 'CMS', ...common },
        emailTemplates: { name: 'Email Templates', ...common },
        brands: { name: 'Brands', ...common },
        categories: { name: 'Categories', ...common },
        coupons: { name: 'Coupons', ...common },
        cuisines: { name: 'Cuisines', ...common },
        helpTopics: { name: 'Help Topics', ...common },
        helpQueriesUsers: { name: 'Help Queries Users', ...common },
        helpQueriesRestaurants: { name: 'Help Queries Restaurants', ...common }
    },
    reducers: {
        setPage: (entities, action) => {
            entities[action.payload.columnKey].page = action.payload.page;
        },
        setLimit: (entities, action) => {
            entities[action.payload.columnKey].limit = action.payload.limit;
            entities[action.payload.columnKey].page = 1;
        },
        setSort: (entities, action) => {
            entities[action.payload.columnKey].sort = action.payload.sort;
        },
        setSearchText: (entities, action) => {
            entities[action.payload.columnKey].page = page;
            entities[action.payload.columnKey].sort = sort;
            entities[action.payload.columnKey].limit = limit;
            entities[action.payload.columnKey].searchText = action.payload.searchText;
        },
        setFilters: (entities, action) => {
            entities[action.payload.columnKey].filters = action.payload.filters;
        },
        setValidFilters: (entities, action) => {
            entities[action.payload.columnKey].validFilters = action.payload.validFilters;
        },
        setTotal: (entities, action) => {
            entities[action.payload.columnKey].total = action.payload.total;
        },
        setItems: (entities, action) => {
            entities[action.payload.columnKey].items = action.payload.items;
        },
        setColumnSettings: (entities, action) => {
            entities[action.payload.columnKey].columnSettings = action.payload.columnSettings;
        },
        setFilterSettings: (entities, action) => {
            entities[action.payload.columnKey].filterSettings = action.payload.filterSettings;
        },
        setTemplateSettings: (entities, action) => {
            entities[action.payload.columnKey].templateSettings = action.payload.templateSettings;
        },
        setDefaultPagination: (entities, action) => {
            entities[action.payload.columnKey].page = page;
            entities[action.payload.columnKey].sort = sort;
            entities[action.payload.columnKey].limit = limit;
            entities[action.payload.columnKey].searchText = '';
        }
    }
});

export default slice.reducer;

export const { setPage, setLimit, setSort, setSearchText, setFilters, setValidFilters, setTotal, setItems, setColumnSettings, setFilterSettings, setTemplateSettings, setDefaultPagination } = slice.actions;