//	Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import getBadgeClasses from '../../../util/badgeClass';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import * as URL from '../../../constants/URL';
import * as API from '../../../constants/API';
import Icon from '../../icons/index';
import TabsTable from './tabsTable';
//	Externals
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback, memo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';
import swal from 'sweetalert2';
import moment from 'moment';
//	Component starts
const UserDetails = () => {
	//	Params
	const { slug } = useParams();
	//	Location
	const location = useLocation();
	//	History
	const history = useHistory();
	//	Redux-store
	const dispatch = useDispatch();
	//	State values
	const [userData, setUserData] = useState({});
	const [key, setKey] = useState('orders');
	//	Manipulate the data
	const { firstname: firstName, lastname: lastName, emailId, mobile, dialCode, photo, totalCompletedOrders, totalPlacedOrders, totalOrdersAmount, totalCommission, totalTax, totalAmountToAdmin } = userData;

	/******************************************************************************************
	@Purpose    :   To get the user details
	*******************************************************************************************/
	const getDetails = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const response = await CS.callApi({ url: API.GET_USER + slug, isAuthorized: true });
			if (response.status === 1) {
				const { data } = response;
				//	Store the Data in local state
				setUserData({ ...data });
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getDetails <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	}, [dispatch, history, slug]);

	useEffect(() => {
		if (!isEmpty(location.state) && !isEmpty(location.state.key)) setKey(location.state.key);
		if (!isEmpty(slug)) getDetails();
		dispatch(resetLoading());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, slug, dispatch]);

	/******************************************************************************************
	@Purpose    :   To delete the user details
	*******************************************************************************************/
	const handleDelete = useCallback(async (ids) => {
		swal.fire({
			title: 'Are you sure, you want to delete ?',
			text: `You won't be able to revert this!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					dispatch(apiCallBegan());
					const body = { ids, columnKey: 'users' };
					const response = await CS.callApi({ url: API.DELETE, body, method: 'delete', isAuthorized: true });
					if (response.status === 1) {
						CS.showSuccessMessage(response.message);
						history.replace(URL.users);
					}
					dispatch(apiCallEnd());
				} catch (error) {
					console.error('error In ====>>>> handleDelete <<<<====', error);
					dispatch(apiCallFailed());
					history.replace(URL.login);
				}
			}
		});
	}, [dispatch, history]);

	/******************************************************************************************
	@Purpose    :   Render phone number
	*******************************************************************************************/
	const renderPhoneNumber = () => isEmpty(dialCode) || isEmpty(mobile) ? '-' : `(${dialCode}) ${mobile}`;

	return (
		<>
			<div className='d-flex align-items-center justify-content-between flex-wrap mb-2'>
				<BreadCrumbs
					title='Users Management'
					data={[{ label: 'Users', path: URL.users }, { label: 'User Details' }]} />
				<div className='text-right'>
					<button className='btn btn-danger ml-3' type='button' onClick={() => handleDelete([slug])}><span className='bx bx-trash-alt mr-2'></span> Delete</button>
				</div>
			</div>
			<div className='card-deck user-details-deck'>
				<div className='card persona-info-card profile-card mb-5'>
					<h4 className='d-flex align-items-center title mb-4'><i className='bx bx-user mr-1' />Personal Information</h4>
					<div className='d-flex info-content'>
						<div className='personal-info'>
							<ul className='info-list'>
								<li>
									<h6 className='info-title'>FIRST NAME</h6>
									<span className='sub-content'>{firstName}</span>
								</li>
								<li>
									<h6 className='info-title'>LAST NAME</h6>
									<span className='sub-content'>{lastName}</span>
								</li>
								<li>
									<h6 className='info-title'>PHONE NUMBER</h6>
									<span className='sub-content'>{renderPhoneNumber()}</span>
								</li>
								<li>
									<h6 className='info-title'>EMAIL ADDRESS</h6>
									<span className='sub-content'>{emailId}</span>
								</li>
							</ul>
						</div>
						<div className='user-item'>
							<div className='user-image'>{CS.renderImageUrl(photo)}</div>
						</div>
					</div>
				</div>
				<div className='card persona-info-card profile-card mb-5'>
					<h4 className='d-flex align-items-center title mb-4'><i className='bx bx-package mr-1' />Order Info</h4>
					<div className='d-flex info-content'>
						<div className='personal-info'>
							<ul className='info-list'>
								<li>
									<h6 className='info-title'>TOTAL PLACED ORDERS</h6>
									<span className='sub-content'>{totalPlacedOrders}</span>
								</li>
								<li>
									<h6 className='info-title'>TOTAL COMPLETED ORDERS</h6>
									<span className='sub-content'>{totalCompletedOrders}</span>
								</li>
								<li>
									<h6 className='info-title'>TOTAL ORDERS AMOUNT</h6>
									<span className='sub-content'>$ {totalOrdersAmount}</span>
								</li>
								<li>
									<h6 className='info-title'>TOTAL COMMISSION</h6>
									<span className='sub-content'>$ {totalCommission}</span>
								</li>
								<li>
									<h6 className='info-title'>TOTAL TAX AMOUNT</h6>
									<span className='sub-content'>$ {totalTax}</span>
								</li>
								<li>
									<h6 className='info-title'>TOTAL EARNINGS</h6>
									<span className='sub-content'>$ {totalAmountToAdmin}</span>
								</li>

							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="card notification-card tabs-block p-0">
				<Tabs
					className="pl-5 pt-3 tab-block"
					id="controlled-tab-example"
					activeKey={key}
					onSelect={(k) => setKey(k)}
				>
					<Tab eventKey="orders" title="Orders">
						<TabsTable
							slug={slug}
							columnKey='userOrders'
							columns={[
								{
									label: 'Order Id',
									type: 'string',
									sort: true,
									path: 'orderId'
								},
								{
									label: 'Date',
									type: 'date',
									sort: true,
									path: 'createdAt',
									content: (item) => moment(item.createdAt).format('DD-MM-YYYY, h:mm a')
								},
								{
									label: 'Restaurant Info',
									type: 'string',
									sort: true,
									path: 'restaurantName',
									content: (item) => <Link to={{
										pathname: `${URL.restaurants}/${item.restaurantId}`,
										state: { key: 'orders' }
									}}>
										<div className="d-flex align-items-center">
											<div className="flex-auto">{CS.renderImageUrl(item.restaurantPhoto)}</div>
											<div className="d-flex flex-column flex-wrap">
												<span className='pl-2 user-head'>{item.restaurantName}</span>
												<span className='pl-2'>{item.restaurantEmailId}</span>
											</div>
										</div>
									</Link>
								},
								{
									label: 'Order Type',
									type: 'boolean',
									sort: true,
									path: 'orderType'
								},
								{
									label: 'Quantity',
									type: 'number',
									sort: true,
									path: 'totalItem'
								},
								{
									label: 'Total Amount',
									type: 'number',
									sort: true,
									path: 'subTotal',
									content: item => `$ ${item.subTotal}`
								},
								{
									label: 'Status',
									type: 'boolean',
									content: (item) => <p className={getBadgeClasses(item.orderStatus)}>{item.orderStatus}</p>
								},
								{
									label: 'Action',
									type: 'boolean',
									content: (item) => <>
										<Icon
											type='show'
											data-tip
											data-for='show'
											onClick={() => history.push(`${URL.orders}/${item._id}`)} />
										<ReactTooltip
											id='show'
											place='top'
											effect='solid'>Show</ReactTooltip>
									</>
								}
							]}
							API={API.ORDERS}
						/>
					</Tab>
					<Tab eventKey="giftOrders" title="Gift-Orders">
						<TabsTable
							slug={slug}
							columnKey='userGiftOrders'
							columns={[
								{
									label: 'Order Id',
									type: 'string',
									sort: true,
									path: 'orderId'
								},
								{
									label: 'Date',
									type: 'date',
									sort: true,
									path: 'createdAt',
									content: (item) => moment(item.createdAt).format('DD-MM-YYYY, h:mm a')
								},
								{
									label: 'Restaurant Info',
									type: 'string',
									sort: true,
									path: 'restaurantName',
									content: (item) => <Link to={{
										pathname: `${URL.restaurants}/${item.restaurantId}`,
										state: { key: 'orders' }
									}}>
										<div className="d-flex align-items-center">
											<div className="flex-auto">{CS.renderImageUrl(item.restaurantPhoto)}</div>
											<div className="d-flex flex-column flex-wrap">
												<span className='pl-2 user-head'>{item.restaurantName}</span>
												<span className='pl-2'>{item.restaurantEmailId}</span>
											</div>
										</div>
									</Link>
								},
								{
									label: 'Gifted To',
									type: 'string',
									sort: true,
									path: 'giftedToName',
									content: (item) => <Link to={{
										pathname: `${URL.users}/${item.giftedTo}`,
										state: { key: 'giftOrders' }
									}}>
										<div className="d-flex align-items-center pr-3">
											<div className="flex-auto">{CS.renderImageUrl(item.giftedToPhoto)}</div>
											<div className="d-flex flex-column flex-wrap">
												<span className='pl-2 user-head'>{item.giftedToName}</span>
												<span className='pl-2'>{item.giftedToEmailId}</span>
											</div>
										</div>
									</Link>
								},
								{
									label: 'Order Type',
									type: 'boolean',
									sort: true,
									path: 'orderType'
								},
								{
									label: 'Quantity',
									type: 'number',
									sort: true,
									path: 'totalItem'
								},
								{
									label: 'Total Amount',
									type: 'number',
									sort: true,
									path: 'subTotal',
									content: item => `$ ${item.subTotal}`
								},
								{
									label: 'Status',
									type: 'boolean',
									content: (item) => <p className={getBadgeClasses(item.orderStatus)}>{item.orderStatus}</p>
								},
								{
									label: 'Action',
									type: 'boolean',
									content: (item) => <>
										<Icon
											type='show'
											data-tip
											data-for='show'
											onClick={() => history.push(`${URL.giftOrders}/${item._id}`)} />
										<ReactTooltip
											id='show'
											place='top'
											effect='solid'>Show</ReactTooltip>
									</>
								}
							]}
							API={API.GIFT_ORDERS}
						/>
					</Tab>
					<Tab eventKey="helpQueries" title="Help Queries">
						<TabsTable
							slug={slug}
							columnKey='userHelpQueries'
							columns={[
								{
									label: 'Created On',
									type: 'date',
									sort: true,
									path: 'createdAt',
									content: (item) => moment(item.createdAt).format('DD-MM-YYYY')
								},
								{
									label: 'Query',
									type: 'string',
									sort: true,
									path: 'query'
								},
								{
									label: 'Message',
									type: 'string',
									sort: true,
									path: 'message'
								},
								{
									label: 'Status',
									type: 'boolean',
									path: 'status',
									sort: true,
									content: (item) => <p className={getBadgeClasses(item.status)}>{item.status}</p>
								}
							]}
							API={API.HELP_QUERIES}
						/>
					</Tab>
				</Tabs>
			</div>
		</>
	);
}

export default memo(UserDetails);