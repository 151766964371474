import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';
import { setMainMenu, setSubMenu } from '../../store/reducers/auth';
import * as URL from '../../constants/URL';

const Sidebar = () => {

	const dispatch = useDispatch();

	const { mainMenu, subMenu } = useSelector(state => state.auth.currentUser);

	const openSideBar = () => {
		document.body.classList.remove('sidebar-open');
	};

	const dispatchMainMenu = (menu) => {
		if (mainMenu === menu) dispatch(setMainMenu({ mainMenu: '' }));
		else dispatch(setMainMenu({ mainMenu: menu }));
	};

	const dispatchSubMenu = (menu) => {
		if (subMenu === menu) dispatch(setSubMenu({ subMenu: '' }));
		else dispatch(setSubMenu({ subMenu: menu }));
		document.body.classList.remove('sidebar-open');
	};

	return (
		<div className='app'>
			<aside className='sidebar'>
				<div className='sidebar-inner-wrapper'>
					<div className='sidebar-heading'>
						<div className='d-flex align-items-end justify-content-between w-100'>
							<Link className="navbar-brand" to='/'>
								<div className="brand-logo">
									<img className='img-fluid' src='/assets/images/logo.svg' alt='branding logo' />
								</div>
							</Link>
							<span onClick={openSideBar} className='sidebar-close'><em className='bx bx-x'></em></span>
						</div>
					</div>
					<div className='sidebar-menu'>
						<ul className='sidebar-menu-list'>
							<li className='menu-list-item'>
								<Link onClick={() => {
									dispatchMainMenu(URL.dashboard);
									dispatchSubMenu('');
								}}
									className={mainMenu === URL.dashboard ? 'menu-link active-link' : 'menu-link'}
									to={URL.dashboard}>
									<i className='bx bx-home-alt' />
									<span className='menu-title'>Dashboard</span>
								</Link>
							</li>
							<li
								className={mainMenu === 'users' ? 'menu-list-item has-child-subMenu level1 subMenu-open' : 'menu-list-item has-child-subMenu level1'} >
								<a onClick={() => dispatchMainMenu('users')}
									className='menu-link'>
									<i className='bx bxs-group' />
									<span className='menu-title'>Users</span>
									<i className='bx bxs-chevron-right' />
								</a>
								<ul className={mainMenu === 'users' ? 'sidebar-menu-list sub-menu-list show' : 'sidebar-menu-list sub-menu-list'}
									style={mainMenu === 'users' ? { 'display': 'block' } : { 'display': 'none' }} >
									<li className='menu-list-item'>
										<Link to={URL.users}
											onClick={() => dispatchSubMenu(URL.users)}
											className={subMenu === URL.users ? 'menu-link active-link' : 'menu-link'} >
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Users</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.restaurants}
											onClick={() => dispatchSubMenu(URL.restaurants)}
											className={subMenu === URL.restaurants ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Restaurants</span>
										</Link>
									</li>
								</ul>
							</li>

							<li
								className={mainMenu === 'orders' ? 'menu-list-item has-child-subMenu level1 subMenu-open' : 'menu-list-item has-child-subMenu level1'} >
								<a onClick={() => dispatchMainMenu('orders')}
									className='menu-link'>
									<i className='bx bx-package' />
									<span className='menu-title'>Orders</span>
									<i className='bx bxs-chevron-right' />
								</a>
								<ul className={mainMenu === 'orders' ? 'sidebar-menu-list sub-menu-list show' : 'sidebar-menu-list sub-menu-list'}
									style={mainMenu === 'orders' ? { 'display': 'block' } : { 'display': 'none' }} >
									<li className='menu-list-item'>
										<Link to={URL.orders}
											onClick={() => dispatchSubMenu(URL.orders)}
											className={subMenu === URL.orders ? 'menu-link active-link' : 'menu-link'} >
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Orders</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.giftOrders}
											onClick={() => dispatchSubMenu(URL.giftOrders)}
											className={subMenu === URL.giftOrders ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Gift Orders</span>
										</Link>
									</li>
								</ul>
							</li>
							<li
								className={mainMenu === 'payouts' ? 'menu-list-item has-child-subMenu level1 subMenu-open' : 'menu-list-item has-child-subMenu level1'} >
								<a onClick={() => dispatchMainMenu('payouts')}
									className='menu-link'>
									<i className='bx bx-money' />
									<span className='menu-title'>Payouts</span>
									<i className='bx bxs-chevron-right' />
								</a>
								<ul className={mainMenu === 'payouts' ? 'sidebar-menu-list sub-menu-list show' : 'sidebar-menu-list sub-menu-list'}
									style={mainMenu === 'payouts' ? { 'display': 'block' } : { 'display': 'none' }} >
									<li className='menu-list-item'>
										<Link to={URL.upcomingPayouts}
											onClick={() => dispatchSubMenu(URL.upcomingPayouts)}
											className={subMenu === URL.upcomingPayouts ? 'menu-link active-link' : 'menu-link'} >
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Upcoming Payouts</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.payoutsHistory}
											onClick={() => dispatchSubMenu(URL.payoutsHistory)}
											className={subMenu === URL.payoutsHistory ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Payouts History</span>
										</Link>
									</li>
								</ul>
							</li>
							<li className='menu-list-item'>
								<Link to={URL.emailTemplates}
									onClick={() => {
										dispatchSubMenu('');
										dispatchMainMenu(URL.emailTemplates);
									}}
									className={mainMenu === URL.emailTemplates ? 'menu-link active-link' : 'menu-link'}>
									<i className='bx bx-envelope' />
									<span className='menu-title'>Email Templates</span>
								</Link>
							</li>
							<li
								className={mainMenu === 'content' ? 'menu-list-item has-child-subMenu level1 subMenu-open' : 'menu-list-item has-child-subMenu level1'} >
								<a
									onClick={() => dispatchMainMenu('content')}
									className='menu-link'>
									<i className='bx bx-link-alt' />
									<span className='menu-title'>Content</span>
									<i className='bx bxs-chevron-right' />
								</a>
								<ul className={mainMenu === 'content' ? 'sidebar-menu-list sub-menu-list show' : 'sidebar-menu-list sub-menu-list'}
									style={mainMenu === 'content' ? { 'display': 'block' } : { 'display': 'none' }} >
									<li className='menu-list-item'>
										<Link to={URL.CMS}
											onClick={() => dispatchSubMenu(URL.CMS)}
											className={subMenu === URL.CMS ? 'menu-link active-link' : 'menu-link'} >
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>CMS - Mobile</span>
										</Link>
									</li>
								</ul>
							</li>
							<li
								className={mainMenu === 'master' ? 'menu-list-item has-child-subMenu level1 subMenu-open' : 'menu-list-item has-child-subMenu level1'} >
								<a
									onClick={() => dispatchMainMenu('master')}
									className='menu-link'>
									<i className='bx bx-category-alt' />
									<span className='menu-title'>Master Management</span>
									<i className='bx bxs-chevron-right' />
								</a>
								<ul
									className={mainMenu === 'master' ? 'sidebar-menu-list sub-menu-list show' : 'sidebar-menu-list sub-menu-list'}
									style={mainMenu === 'master' ? { 'display': 'block' } : { 'display': 'none' }} >
									<li className='menu-list-item'>
										<Link to={URL.brands}
											onClick={() => dispatchSubMenu(URL.brands)}
											className={subMenu === URL.brands ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Brands</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.categories}
											onClick={() => dispatchSubMenu(URL.categories)}
											className={subMenu === URL.categories ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Categories</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.coupons}
											onClick={() => dispatchSubMenu(URL.coupons)}
											className={subMenu === URL.coupons ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Coupons</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.cuisines}
											onClick={() => dispatchSubMenu(URL.cuisines)}
											className={subMenu === URL.cuisines ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Cuisines</span>
										</Link>
									</li>
								</ul>
							</li>
							<li
								className={mainMenu === 'helpManagement' ? 'menu-list-item has-child-subMenu level1 subMenu-open' : 'menu-list-item has-child-subMenu level1'} >
								<a onClick={() => dispatchMainMenu('helpManagement')}
									className='menu-link'>
									<i className='bx bx-help-circle' />
									<span className='menu-title'>Help Management</span>
									<i className='bx bxs-chevron-right' />
								</a>
								<ul className={mainMenu === 'helpManagement' ? 'sidebar-menu-list sub-menu-list show' : 'sidebar-menu-list sub-menu-list'}
									style={mainMenu === 'helpManagement' ? { 'display': 'block' } : { 'display': 'none' }} >
									<li className='menu-list-item'>
										<Link to={URL.helpQueriesUsers}
											onClick={() => dispatchSubMenu(URL.helpQueriesUsers)}
											className={subMenu === URL.helpQueriesUsers ? 'menu-link active-link' : 'menu-link'} >
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Help Queries - Users</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.helpQueriesRestaurants}
											onClick={() => dispatchSubMenu(URL.helpQueriesRestaurants)}
											className={subMenu === URL.helpQueriesRestaurants ? 'menu-link active-link' : 'menu-link'} >
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Help Queries - Restaurants</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.helpTopics}
											onClick={() => dispatchSubMenu(URL.helpTopics)}
											className={subMenu === URL.helpTopics ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Help Topics</span>
										</Link>
									</li>
								</ul>
							</li>
							<li
								className={mainMenu === 'settings' ? 'menu-list-item has-child-subMenu level1 subMenu-open' : 'menu-list-item has-child-subMenu level1'}>
								<a onClick={() => dispatchMainMenu('settings')}
									className='menu-link'>
									<i className='bx bx-cog' />
									<span className='menu-title'>Settings</span>
									<i className='bx bxs-chevron-right' />
								</a>
								<ul
									className='sidebar-menu-list sub-menu-list'
									style={mainMenu === 'settings' ? { 'display': 'block' } : { 'display': 'none' }}>
									<li className='menu-list-item'>
										<Link to={URL.generalSettings}
											onClick={() => dispatchSubMenu(URL.generalSettings)}
											className={subMenu === URL.generalSettings ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>General Settings</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.paymentGatewaySettings}
											onClick={() => dispatchSubMenu(URL.paymentGatewaySettings)}
											className={subMenu === URL.paymentGatewaySettings ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Payment Gateway SDK Details</span>
										</Link>
									</li>
									<li className='menu-list-item'>
										<Link to={URL.contactSettings}
											onClick={() => dispatchSubMenu(URL.contactSettings)}
											className={subMenu === URL.contactSettings ? 'menu-link active-link' : 'menu-link'}>
											<i className='bx bxs-right-arrow-alt' />
											<span className='menu-title'>Contact Details</span>
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</aside>
		</div >
	);
}

export default Sidebar;