//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import { setItems, setDefaultPagination } from '../../../store/reducers/entities';
import CS from '../../../services/commonService';
import CropImage from '../../common/cropImage';
import * as API from '../../../constants/API';
import * as MSG from '../../../constants/MSG';
import * as URL from '../../../constants/URL';
//  Externals
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-dropdown';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
//  Component starts
const AddUpdate = ({ brand, columnKey, onCloseModal }) => {
    //	Pre-defined values
    if (isEmpty(brand)) brand = { brandName: '' };
    const statusOptions = [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' }
    ];
    const featuredOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
    ];
    //	Redux-store
    const dispatch = useDispatch();
    const { items } = useSelector(state => state.entities[columnKey]);
    //  History
    const history = useHistory();
    //	State values
    const [featured, setFeatured] = useState(featuredOptions.find(option => option.value === brand.featured) || { value: true, label: 'Yes' });
    const [status, setStatus] = useState(statusOptions.find(option => option.value === brand.status) || { value: true, label: 'Active' });
    const [errors, setErrors] = useState({ brandName: '', photo: '' });
    let [brandName, setBrandName] = useState(brand.brandName);
    const [photo, setPhoto] = useState(brand.photo);
    const [id] = useState(brand._id);

    /******************************************************************************************
    @Purpose    :   To validate form
    *******************************************************************************************/
    const validateForm = () => {
        brandName = brandName.trim();
        //  Brand name
        if (isEmpty(brandName)) errors.brandName = MSG.REQUIRED_BRAND_NAME;
        else errors.brandName = '';
        //  Photo
        if (isEmpty(photo)) errors.photo = MSG.REQUIRED_LOGO;
        else errors.photo = '';
        //  Errors
        setErrors({ ...errors });
        return isEmpty(errors.brandName) && isEmpty(errors.photo) ? true : false;
    };

    /******************************************************************************************
    @Purpose    :   To add-update brand
    *******************************************************************************************/
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        try {
            dispatch(apiCallBegan());
            const body = { brandName, photo, featured: featured.value, status: status.value };
            let url = API.BRAND;
            let method = 'post';
            if (!isEmpty(id)) {
                url = `${API.BRAND}/${id}`;
                method = 'put';
            }
            const response = await CS.callApi({ url, body, method, isAuthorized: true });
            if (response.status) {
                CS.showSuccessMessage(response.message);
                if (isEmpty(id)) await dispatch(setDefaultPagination({ columnKey }));
                else {
                    const updatedItems = items.map(item => {
                        const entity = { ...item };
                        if (item._id === id) {
                            entity.featured = featured.value;
                            entity.status = status.value;
                            entity.brandName = brandName;
                            entity.photo = photo;
                        }
                        return entity;
                    });
                    dispatch(setItems({ columnKey, items: updatedItems }));
                }
                await onCloseModal(isEmpty(id));
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> Add-Update Brand <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    };

    /******************************************************************************************
    @Purpose    :   To file upload
    *******************************************************************************************/
    const handleFileUpload = async (file) => {
        const fileData = new FormData();
        fileData.append('file', file);
        try {
            dispatch(apiCallBegan());
            const response = await CS.callApi({ url: API.FILE_UPLOAD, body: fileData, method: 'post', isAuthorized: true, isForm: true });
            if (response.status === 1) setPhoto(response.data.filePath);
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> handleFileUpload <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    };

    return (
        <div className='modal-content'>
            <div className='modal-header'>
                <h5 className='modal-title'> {isEmpty(id) ? 'Add Brand' : 'Update Brand'}</h5>
            </div>
            <div className='notification-form modal-body card profile-card brand-modal'>
                <div className='row'>
                    <div className='col-md-12'>
                        <form onSubmit={(event) => handleSubmit(event)}>
                            <div className='mb-md-4 mb-3'>
                                <div className='form-group upload-user-img mb-0'>
                                    <label className='label xl-label'>Logo<sup className='text-danger'>*</sup></label>
                                    <div className='edit-image ml-0'>
                                        <div className='user-image'>
                                            {CS.renderImageUrl(photo)}
                                            <label className='img-upload' htmlFor='attach-file'>
                                                <CropImage onFileUpload={handleFileUpload} />
                                                <em className='bx bxs-edit-alt' />
                                            </label>
                                        </div>
                                    </div>
                                    <div className='error-message-password'>
                                        <span className='text-danger error-msg d-block mb-2'>{errors.photo}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-md-4 mb-3'>
                                <div className='form-group mb-0'>
                                    <label className='label xl-label'>Brand Name<sup className='text-danger'>*</sup></label>
                                    <div className='w-100 position-relative'>
                                        <input
                                            className='form-control'
                                            type='text'
                                            id='brandName'
                                            name='brandName'
                                            placeholder='Brand Name'
                                            value={brandName}
                                            onChange={(e) => {
                                                setBrandName(e.target.value);
                                                setErrors({ ...errors, brandName: '' });
                                            }}
                                        />
                                        <div className='error-message-password'>
                                            <span className='text-danger error-msg d-block mb-2'>{errors.brandName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-md-4 mb-3'>
                                <div className='form-group mb-0'>
                                    <label className='label xl-label'>Featured<sup className='text-danger'>*</sup></label>
                                    <div className='w-100 position-relative'>
                                        <Select
                                            value={featured}
                                            onChange={(option) => setFeatured(option)}
                                            options={featuredOptions}
                                        />
                                    </div>
                                </div>
                            </div>
                            {!isEmpty(id) && <div className='mb-md-4 mb-3'>
                                <div className='form-group mb-0'>
                                    <label className='label xl-label'>Status<sup className='text-danger'>*</sup></label>
                                    <div className='w-100 position-relative'>
                                        <Select
                                            value={status}
                                            onChange={(option) => setStatus(option)}
                                            options={statusOptions}
                                        />
                                    </div>
                                </div>
                            </div>}

                            <div className='mt-5 text-right'>
                                <button className='btn btn-primary' type='submit' >{isEmpty(id) ? 'Add' : 'Update'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    brand: PropTypes.object,
    columnKey: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired
};

export default memo(AddUpdate);