import { useEffect } from 'react';

export const useOnClickOutside = (ref, onClose) => {
	console.log('Use On Click Outside');
	useEffect(
		() => {
			const escapeListener = (e) => {
				e.preventDefault();
				if (e.key === 'Escape') onClose();
			};
			const clickListener = (e) => {
				// e.preventDefault();
				if (e.target.className === 'bx bx-minus-circle mr-1 text-danger') {
					return;
				}
				if (ref.current && !ref.current.contains(e.target)) onClose();
			};

			document.addEventListener('click', clickListener);
			document.addEventListener('keyup', escapeListener);

			return () => {
				document.removeEventListener('click', clickListener);
				document.removeEventListener('keyup', escapeListener);
			};
		},
		[ref, onClose]
	);
};