//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import * as API from '../../../constants/API';
import * as MSG from '../../../constants/MSG';
import * as URL from '../../../constants/URL';
import Icon from '../../icons/index';
//  Externals
import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Select from 'react-dropdown';
import { isEmpty } from 'lodash';
import Slider from 'rc-slider';
//  Pre-defined values
const payoutCycleOptions = [
    { label: 'Monthly Once', value: 'monthlyOnce' },
    { label: 'Monthly Twice', value: 'monthlyTwice' },
    { label: 'Monthly Thrice', value: 'monthlyThrice' },
    { label: 'Weekly Once', value: 'weeklyOnce' },
    { label: 'Weekly Twice', value: 'weeklyTwice' },
];
const dates = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
];
const weekDays = [
    { label: 'Sunday', value: 'sunday' },
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' }
];
//  Component starts
const General = () => {
    //	Redux-store
    const dispatch = useDispatch();
    //  History
    const history = useHistory();
    //	State values
    const [errors, setErrors] = useState({ globalCommission: '', globalTax: '', referralPointRate: '', referralRewardPoint: '', walletRewardCredit: '', setWalletRewardDebit: '' });
    const [defaultWalletTopUpValues, setDefaultWalletTopUpValues] = useState([
        { value: 50.0, status: false, error: '' },
        { value: 100.0, status: true, error: '' },
        { value: 200.0, status: false, error: '' },
        { value: 300.0, status: false, error: '' }
    ]);
    const [payoutCycleDays, setPayoutCycleDays] = useState([{ ...dates[0] }]);
    const [payoutCycle, setPayoutCycle] = useState(payoutCycleOptions[0]);
    const [referralRewardPoint, setReferralRewardPoint] = useState(0);
    const [walletRewardCredit, setWalletRewardCredit] = useState(1);
    const [referralPointRate, setReferralPointRate] = useState(0);
    const [walletRewardDebit, setWalletRewardDebit] = useState(3);
    const [globalCommission, setGlobalCommission] = useState(5);
    const [opaCreditCardTax, setOpaCreditCardTax] = useState(0);
    const [opaDebitCardTax, setOpaDebitCardTax] = useState(0);
    const [opaCommission, setOpaCommission] = useState(0);
    const [globalTax, setGlobalTax] = useState(5);

    /******************************************************************************************
    @Purpose    :   To get the payment settings
    *******************************************************************************************/
    const getDetails = useCallback(async () => {
        try {
            dispatch(apiCallBegan());
            const response = await CS.callApi({ url: API.PAYMENT_SETTINGS, isAuthorized: true });
            if (response.status === 1) {
                const { data } = response;
                //  Get the payout lifecycle options
                const payoutCycleOption = payoutCycleOptions.find(o => o.value === data.payoutCycle) || payoutCycleOptions[0];
                setPayoutCycle(payoutCycleOption);
                //  Get the payout 
                const payoutCycleDaysOptions = data.payoutCycleDays.map(payoutCycleDay => {
                    if (['monthlyOnce', 'monthlyTwice', 'monthlyThrice'].includes(payoutCycleOption.value)) return dates.find(date => date.value === payoutCycleDay);
                    return weekDays.find(date => date.value === payoutCycleDay);
                });
                setPayoutCycleDays([...payoutCycleDaysOptions]);
                setGlobalTax(data.globalTax);
                setOpaCommission(data.opaCommission);
                setOpaDebitCardTax(data.opaDebitCardTax);
                setOpaCreditCardTax(data.opaCreditCardTax);
                setGlobalCommission(data.globalCommission);
                setWalletRewardDebit(data.walletRewardDebit);
                setReferralPointRate(data.referralPointRate);
                setWalletRewardCredit(data.walletRewardCredit);
                setReferralRewardPoint(data.referralRewardPoint);
                setDefaultWalletTopUpValues(data.defaultWalletTopUpValues.map(o => {
                    o.error = '';
                    return o;
                }));
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> getDetails <<<<====', error);
            dispatch(apiCallFailed());
            history.push(URL.login);
        }
    }, [dispatch, history]);

    useEffect(() => {
        getDetails();
        dispatch(resetLoading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    /******************************************************************************************
    @Purpose    :   To handle payout cycle option
    *******************************************************************************************/
    const handlePayoutCycleOption = (opt) => {
        setPayoutCycle(opt);
        // Set the initial values
        if (opt.value === 'monthlyOnce') setPayoutCycleDays([{ label: '1', value: '1' }]);
        if (opt.value === 'monthlyTwice') setPayoutCycleDays([{ label: '1', value: '1' }, { label: '15', value: '150' }]);
        if (opt.value === 'monthlyThrice') setPayoutCycleDays([{ label: '1', value: '1' }, { label: '10', value: '10' }, { label: '20', value: '20' }]);
        if (opt.value === 'weeklyOnce') setPayoutCycleDays([{ label: 'Sunday', value: 'sunday' }]);
        if (opt.value === 'weeklyTwice') setPayoutCycleDays([{ label: 'Sunday', value: 'sunday' }, { label: 'Thursday', value: 'thursday' }]);
    };

    /******************************************************************************************
    @Purpose    :   To render the payout days label
    *******************************************************************************************/
    const renderPayoutDaysLabel = () => {
        if (['monthlyOnce', 'monthlyTwice', 'monthlyThrice'].includes(payoutCycle.value)) return 'Select Date';
        if (['weeklyOnce', 'weeklyTwice'].includes(payoutCycle.value)) return 'Select Day';
    };

    /******************************************************************************************
    @Purpose    :   To render the payout days values
    *******************************************************************************************/
    const renderPayoutDaysValues = () => {
        if (['monthlyOnce', 'monthlyTwice', 'monthlyThrice'].includes(payoutCycle.value)) return dates;
        if (['weeklyOnce', 'weeklyTwice'].includes(payoutCycle.value)) return weekDays;
    };

    /******************************************************************************************
    @Purpose    :   To handle the payout cycle day
    *******************************************************************************************/
    const handlePayoutCycleDays = (opt, i) => {
        payoutCycleDays[i] = opt;
        setPayoutCycleDays([...payoutCycleDays]);
    };

    /******************************************************************************************
    @Purpose    :   To validate form
    *******************************************************************************************/
    const validateForm = () => {
        //  Global commission
        if (globalCommission < 1) errors.globalCommission = MSG.REQUIRED_GLOBAL_COMMISSION;
        else errors.globalCommission = '';
        //  Global tax
        if (globalTax < 1) errors.globalTax = MSG.REQUIRED_GLOBAL_TAX;
        else errors.globalTax = '';
        //  Referral point rate
        if (referralPointRate < 1) errors.referralPointRate = MSG.REQUIRED_REFERRAL_POINTS;
        else errors.referralPointRate = '';
        //  Referral reward point
        if (referralRewardPoint < 1) errors.referralRewardPoint = MSG.REQUIRED_REFERRAL_REWARD_POINT;
        else errors.referralRewardPoint = '';
        //  Referral point rate
        if (walletRewardCredit < 1) errors.walletRewardCredit = MSG.REQUIRED_WALLET_REWARD_CREDIT;
        else errors.walletRewardCredit = '';
        //  Referral reward point
        if (walletRewardDebit < 1) errors.walletRewardDebit = MSG.REQUIRED_WALLET_REWARD_DEBIT;
        else errors.walletRewardDebit = '';
        //  Opa commission
        if (opaCommission < 1) errors.opaCommission = MSG.REQUIRED_OPA_COMMISSION;
        else errors.opaCommission = '';
        //  Opa credit card tax
        if (opaCreditCardTax < 1) errors.opaCreditCardTax = MSG.REQUIRED_OPA_CREDIT_CARD_TAX;
        else errors.opaCreditCardTax = '';
        //  Opa debit card tax
        if (opaDebitCardTax < 1) errors.opaDebitCardTax = MSG.REQUIRED_OPA_DEBIT_CARD_TAX;
        else errors.opaDebitCardTax = '';
        //  Default Wallet TopUp Values
        if (isEmpty(defaultWalletTopUpValues)) errors.defaultWalletTopUpValues = MSG.REQUIRED_DEFAULT_TOP_UP_VALUES;
        else {
            //  Check whether the value is zero or empty
            const entity = defaultWalletTopUpValues.find(o => o.value === 0 || o.value === '');
            if (isEmpty(entity)) errors.defaultWalletTopUpValues = '';
            else {
                errors.defaultWalletTopUpValues = MSG.REQUIRED_VALUE;
                const updatedValues = defaultWalletTopUpValues.map((item) => {
                    const element = { ...item };
                    if (item.value === 0 || item.value === '') element.error = MSG.REQUIRED_VALUE;
                    else element.error = '';
                    return element;
                });
                setDefaultWalletTopUpValues([...updatedValues]);
            }
        }
        //  Errors
        setErrors({ ...errors });
        return isEmpty(errors.globalCommission) && isEmpty(errors.globalTax) && isEmpty(errors.referralPointRate) && isEmpty(errors.referralRewardPoint) && isEmpty(errors.walletRewardCredit) && isEmpty(errors.walletRewardDebit) && isEmpty(errors.defaultWalletTopUpValues) && isEmpty(errors.opaCommission) && isEmpty(errors.opaCreditCardTax) && isEmpty(errors.opaDebitCardTax) ? true : false;
    };

    /******************************************************************************************
    @Purpose    :   To update the payment settings
    *******************************************************************************************/
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        try {
            dispatch(apiCallBegan());
            const body = { globalTax, globalCommission, walletRewardDebit, walletRewardCredit, referralRewardPoint, defaultWalletTopUpValues: defaultWalletTopUpValues.map(o => ({ value: o.value, status: o.status })) };
            body.referralPointRate = Number(referralPointRate);
            body.referralRewardPoint = Number(referralRewardPoint);
            body.walletRewardCredit = Number(walletRewardCredit);
            body.walletRewardDebit = Number(walletRewardDebit);
            body.opaCreditCardTax = Number(opaCreditCardTax);
            body.opaDebitCardTax = Number(opaDebitCardTax);
            body.opaCommission = Number(opaCommission);
            //  Create the payout schedule
            body.payoutCycle = payoutCycle.value;
            body.payoutCycleDays = payoutCycleDays.map(o => o.value);
            if (['monthlyOnce', 'monthlyTwice', 'monthlyThrice'].includes(payoutCycle.value)) {
                body.payoutCycleDays = payoutCycleDays.map(o => o.value).sort((a, b) => a - b);
                body.payoutCycleSchedule = body.payoutCycleDays.map(o => `* * * ${o} * *`);
            }
            else body.payoutCycleSchedule = body.payoutCycleDays.map(o => `* * * * * ${o}`);
            const response = await CS.callApi({ url: API.PAYMENT_SETTINGS, body, method: 'put', isAuthorized: true });
            if (response.status) CS.showSuccessMessage(response.message);
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> Add-Update payment settings <<<<====', error);
            dispatch(apiCallFailed());
            history.push(URL.login);
        }
    };

    /******************************************************************************************
    @Purpose    :   Add description
    *******************************************************************************************/
    const handleAdd = () => {
        defaultWalletTopUpValues.push({ value: 0, status: false });
        setDefaultWalletTopUpValues([...defaultWalletTopUpValues]);
    };

    /******************************************************************************************
    @Purpose    :   Remove description
    *******************************************************************************************/
    const handleRemove = (i) => {
        defaultWalletTopUpValues.splice(i, 1);
        setDefaultWalletTopUpValues([...defaultWalletTopUpValues]);
    };

    /******************************************************************************************
    @Purpose    :   handle the change value
    *******************************************************************************************/
    const handleChange = (i, value) => {
        const updatedValues = defaultWalletTopUpValues.map((item, index) => {
            const entity = { ...item };
            if (i === index) entity.value = value;
            return entity;
        });
        setDefaultWalletTopUpValues([...updatedValues]);
    };

    /******************************************************************************************
    @Purpose    :   handle the change status
    *******************************************************************************************/
    const handleChangeStatus = (i, status) => {
        const updatedValues = defaultWalletTopUpValues.map((item, index) => {
            const entity = { ...item };
            if (i === index) entity.status = status;
            else entity.status = !status;
            return entity;
        });
        setDefaultWalletTopUpValues([...updatedValues]);
    };

    return (
        <>
            <BreadCrumbs title='Settings' data={[{ label: 'General Settings' }]} />
            <div className='card'>
                <form onSubmit={(event) => handleSubmit(event)}>
                    <div id='accordion' className='collapse-setting'>
                        <div className='card mb-5 bg-transparent'>
                            <div id='collapseOne' className='collapse show' aria-labelledby='headingOne' data-parent='#accordion'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='card-strip h-100'>
                                            <div className='modal-header p-0 pb-3 mb-4 w-100'>
                                                <h5 className='modal-title'>Commissions</h5>
                                            </div>
                                            <div className='form-group mb-md-5 mb-3'>
                                                <label htmlFor='globalCommission'>Global Commission (%)</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    id='globalCommission'
                                                    name='globalCommission'
                                                    value={globalCommission}
                                                    onChange={(e) => setGlobalCommission(e.target.value)}
                                                />
                                                <span className='text-danger d-block'>{errors.globalCommission}</span>
                                                <Slider
                                                    className='mt-3'
                                                    min={0}
                                                    max={100}
                                                    value={globalCommission}
                                                    onChange={(v) => setGlobalCommission(v)}
                                                />
                                            </div>
                                            <div className='form-group mb-md-5 mb-3'>
                                                <label htmlFor='globalTax'>Global Tax (%)</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    id='globalTax'
                                                    name='globalTax'
                                                    value={globalTax}
                                                    onChange={(e) => setGlobalTax(e.target.value)}
                                                />
                                                <span className='text-danger d-block'>{errors.globalTax}</span>
                                                <Slider
                                                    className='mt-3'
                                                    min={0}
                                                    max={100}
                                                    value={globalTax}
                                                    onChange={(v) => setGlobalTax(v)}
                                                />
                                            </div>
                                            <div className='form-group mb-md-5 mb-3'>
                                                <label htmlFor='opaCommission'>Opa Commission (%)</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    id='opaCommission'
                                                    name='opaCommission'
                                                    value={opaCommission}
                                                    onChange={(e) => setOpaCommission(e.target.value)}
                                                />
                                                <span className='text-danger d-block'>{errors.opaCommission}</span>
                                                <Slider
                                                    className='mt-3'
                                                    min={0}
                                                    max={100}
                                                    value={opaCommission}
                                                    onChange={(v) => setOpaCommission(v)}
                                                />
                                            </div>
                                            <div className='form-group mb-md-5 mb-3'>
                                                <label htmlFor='opaCreditCardTax'>Opa Credit Card Tax (%)</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    id='opaCreditCardTax'
                                                    name='opaCreditCardTax'
                                                    value={opaCreditCardTax}
                                                    onChange={(e) => setOpaCreditCardTax(e.target.value)}
                                                />
                                                <span className='text-danger d-block'>{errors.opaCreditCardTax}</span>
                                                <Slider
                                                    className='mt-3'
                                                    min={0}
                                                    max={100}
                                                    value={opaCreditCardTax}
                                                    onChange={(v) => setOpaCreditCardTax(v)}
                                                />
                                            </div>
                                            <div className='form-group mb-md-5 mb-3'>
                                                <label htmlFor='opaDebitCardTax'>Opa Debit Card Tax (%)</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    id='opaDebitCardTax'
                                                    name='opaDebitCardTax'
                                                    value={opaDebitCardTax}
                                                    onChange={(e) => setOpaDebitCardTax(e.target.value)}
                                                />
                                                <span className='text-danger d-block'>{errors.opaDebitCardTax}</span>
                                                <Slider
                                                    className='mt-3'
                                                    min={0}
                                                    max={100}
                                                    value={opaDebitCardTax}
                                                    onChange={(v) => setOpaDebitCardTax(v)}
                                                />
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col-lg-6 mb-md-5 mb-5'>
                                                    <label htmlFor='referralPointRate'>1 USD = X referral Points</label>
                                                    <div className='sm-input'>
                                                        <span className='sub-text'>1 USD  =</span>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id='referralPointRate'
                                                            name='referralPointRate'
                                                            value={referralPointRate}
                                                            onChange={(e) => setReferralPointRate(e.target.value)}
                                                        />
                                                        <span className='sub-text'>Points</span>
                                                    </div>
                                                    <span className='text-danger d-block'>{errors.referralPointRate}</span>
                                                </div>
                                                <div className='form-group col-lg-6 mb-md-5 mb-5'>
                                                    <label htmlFor='referralRewardPoint'>Referral Rewards</label>
                                                    <div className='sm-input'>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id='referralRewardPoint'
                                                            name='referralRewardPoint'
                                                            value={referralRewardPoint}
                                                            onChange={(e) => setReferralRewardPoint(e.target.value)}
                                                        />
                                                        <span className='sub-text'>Points</span>
                                                    </div>
                                                    <span className='text-danger d-block'>{errors.referralRewardPoint}</span>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col-lg-6 mb-md-5 mb-5'>
                                                    <label htmlFor='walletRewardCredit'>Wallet TopUp with Credit Card</label>
                                                    <div className='sm-input'>
                                                        <span className='sub-text'>1 USD  =</span>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id='walletRewardCredit'
                                                            name='walletRewardCredit'
                                                            value={walletRewardCredit}
                                                            onChange={(e) => setWalletRewardCredit(e.target.value)}
                                                        />
                                                        <span className='sub-text'>Points</span>
                                                    </div>
                                                    <span className='text-danger d-block'>{errors.walletRewardCredit}</span>
                                                </div>
                                                <div className='form-group col-lg-6 mb-md-5 mb-5'>
                                                    <label htmlFor='walletRewardDebit'>Wallet TopUp with Debit Card</label>
                                                    <div className='sm-input'>
                                                        <span className='sub-text'>1 USD  =</span>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id='walletRewardDebit'
                                                            name='walletRewardDebit'
                                                            value={walletRewardDebit}
                                                            onChange={(e) => setWalletRewardDebit(e.target.value)}
                                                        />
                                                        <span className='sub-text'>Points</span>
                                                    </div>
                                                    <span className='text-danger d-block'>{errors.walletRewardDebit}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='card-strip h-100'>
                                            <div className='modal-header p-0 pb-3 mb-4 w-100'>
                                                <h5 className='modal-title'>Payouts</h5>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6 form-group mb-4'>
                                                    <label className='text-left'>Select Option<sup className='text-danger'>*</sup></label>
                                                    <Select
                                                        className='w-100 custom-input filter-select'
                                                        value={payoutCycle}
                                                        onChange={(opt) => handlePayoutCycleOption(opt)}
                                                        options={payoutCycleOptions}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                {!isEmpty(payoutCycle) &&
                                                    payoutCycleDays.map((payoutCycleDay, i) => <div className='col-lg-4 form-group mb-2' key={i}>
                                                        <label className='text-left'>{renderPayoutDaysLabel()}<sup className='text-danger'>*</sup></label>
                                                        <Select
                                                            className='w-100 custom-input filter-select'
                                                            value={payoutCycleDay}
                                                            onChange={(opt) => handlePayoutCycleDays(opt, i)}
                                                            options={renderPayoutDaysValues()}
                                                        />
                                                    </div>)
                                                }
                                            </div>
                                            <div className='modal-header p-0 pb-3 mb-4 mt-4 w-100'>
                                                <h5 className='modal-title'>Default Wallet TopUp Values</h5>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table row-border nowrap common-dataTable">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width="30%">Value</th>
                                                            <th scope="col" className="text-center">Default Active</th>
                                                            <th scope="col" className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {defaultWalletTopUpValues.map((value, i) => (
                                                            <Fragment key={i}>
                                                                <tr>
                                                                    <td>
                                                                        <input
                                                                            type='number'
                                                                            className='form-control'
                                                                            id='walletRewardCredit'
                                                                            name='walletRewardCredit'
                                                                            value={value.value}
                                                                            onChange={(e) => handleChange(i, e.target.value)}
                                                                        />
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <span className='custom-switch light '>
                                                                            <input
                                                                                type='checkbox'
                                                                                id={i}
                                                                                className='custom-control-input'
                                                                                checked={value.status}
                                                                                onChange={() => handleChangeStatus(i, !value.status)}
                                                                            />
                                                                            <label
                                                                                className='custom-control-label'
                                                                                htmlFor={i}
                                                                            ></label>
                                                                        </span>
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {defaultWalletTopUpValues.length > 1 &&
                                                                            <>
                                                                                <Icon
                                                                                    type='minus'
                                                                                    onClick={() => handleRemove(i)} />
                                                                                {defaultWalletTopUpValues.length === (i + 1) &&
                                                                                    <Icon
                                                                                        type='plus'
                                                                                        onClick={() => handleAdd()}
                                                                                    />}
                                                                            </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <span className='text-danger d-block'>{value.error}</span>
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 btn-blocks text-right'>
                        <button className='btn btn-primary glow-primary mr-4' type='submit'>Save Changes</button>
                        <Link to={URL.dashboard} className='btn btn-outline-primary'>Cancel</Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default General;