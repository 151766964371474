//  Externals
import { setItems, setColumnSettings, setTotal, setPage } from '../../store/reducers/entities';
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../store/reducers/loading';
import CS from '../../services/commonService';
import * as API from '../../constants/API';
import * as URL from '../../constants/URL';
//  Internals
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert2';
//  Component starts
const withCommon = ({ component: WrappedComponent, columnKey }) => {

    const WithCommon = (props) => {
        //	Redux-store
        const dispatch = useDispatch();
        const { items, limit } = useSelector(state => state.entities[columnKey]);
        //  History
        const history = useHistory();
        //	State values
        const [selectAll, setSelectAll] = useState(false);
        //  Manipulate the data
        let API_DELETE = API.DELETE;
        let API_LIST = '';
        if (columnKey === 'users') API_LIST = API.USERS;
        if (columnKey === 'restaurants') API_LIST = API.RESTAURANTS;
        if (columnKey === 'brands') API_LIST = API.BRANDS;
        if (columnKey === 'categories') API_LIST = API.CATEGORIES;
        if (columnKey === 'coupons') API_LIST = API.COUPONS;
        if (columnKey === 'cuisines') API_LIST = API.CUISINES;
        if (columnKey === 'helpTopics') API_LIST = API.HELP_TOPICS;
        if (['helpQueriesUsers', 'helpQueriesRestaurants'].includes(columnKey)) {
            API_LIST = API.HELP_QUERIES;
            API_DELETE = API.DELETE_HELP_QUERIES;
        }
        if (columnKey === 'cms') API_LIST = API.CMSES;
        if (columnKey === 'emailTemplates') API_LIST = API.EMAIL_TEMPLATES;

        /******************************************************************************************
        @Purpose    :   To get all items
        *******************************************************************************************/
        const getItems = useCallback(async () => {
            try {
                dispatch(apiCallBegan());
                const body = { page: 1, limit, columnKey };
                //	Get the response from API
                const response = await CS.callApi({ url: API_LIST, body, method: 'post', isAuthorized: true });
                if (response.status === 1) {
                    const { data } = response;
                    const { listing, total } = data;
                    //	Store the Data in Redux
                    dispatch(setItems({ columnKey, items: listing.map(list => { list.checked = false; return list; }) }));
                    dispatch(setTotal({ columnKey, total }));
                    dispatch(setPage({ columnKey, page: 1 }));
                    //	Store the Data in local state
                    setSelectAll(false);
                }
                dispatch(apiCallEnd());
            } catch (error) {
                console.error('error In ====>>>> getItems <<<<====', error);
                dispatch(apiCallFailed());
                history.replace(URL.login);
            }
        }, [API_LIST, dispatch, history, limit]);

        /******************************************************************************************
        @Purpose    :   To select all
        *******************************************************************************************/
        const handleSelectAll = useCallback(() => {
            let selectedItems = items.map(item => ({ ...item, checked: !selectAll }));
            dispatch(setItems({ columnKey, items: selectedItems }));
            setSelectAll(!selectAll);
        }, [dispatch, items, selectAll]);

        /******************************************************************************************
        @Purpose    :   To select single
        *******************************************************************************************/
        const handleSelectSingle = useCallback((_id) => {
            const selectedItems = items.map(item => {
                const entity = { ...item };
                if (item._id === _id) entity.checked = !item.checked;
                return entity;
            });
            dispatch(setItems({ columnKey, items: selectedItems }));
        }, [dispatch, items]);

        /******************************************************************************************
        @Purpose    :   To change the status of the categories
        *******************************************************************************************/
        const handleChangeStatus = useCallback(async (ids, status) => {
            try {
                dispatch(apiCallBegan());
                const body = { ids, status, columnKey };
                const response = await CS.callApi({ url: API.STATUS_CHANGE, body, method: 'post', isAuthorized: true });
                if (response.status === 1) {
                    CS.showSuccessMessage(response.message);
                    const updatedItems = items.map(item => {
                        const entity = { ...item };
                        if (ids.indexOf(item._id) !== -1) {
                            entity.status = status;
                            entity.checked = false;
                        }
                        return entity;
                    });
                    dispatch(setItems({ columnKey, items: updatedItems }));
                    setSelectAll(false);
                }
                dispatch(apiCallEnd());
            } catch (error) {
                console.error('error In ====>>>> handleChangeStatus <<<<====', error);
                dispatch(apiCallFailed());
                history.replace(URL.login);
            }
        }, [dispatch, history, items]);

        /******************************************************************************************
        @Purpose    :   To delete the coupons
        *******************************************************************************************/
        const handleDelete = useCallback(async (ids) => {
            swal.fire({
                title: 'Are you sure, you want to delete ?',
                text: `You won't be able to revert this!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        dispatch(apiCallBegan());
                        const body = { ids, columnKey };
                        if (['helpQueriesUsers', 'helpQueriesRestaurants'].includes(columnKey)) delete body.columnKey;
                        const response = await CS.callApi({ url: API_DELETE, body, method: 'delete', isAuthorized: true });
                        if (response.status === 1) {
                            CS.showSuccessMessage(response.message);
                            getItems();
                        }
                        dispatch(apiCallEnd());
                    } catch (error) {
                        console.error('error In ====>>>> handleDelete <<<<====', error);
                        dispatch(apiCallFailed());
                        history.replace(URL.login);
                    }
                }
            });
        }, [API_DELETE, dispatch, getItems, history]);

        /******************************************************************************************
        @Purpose    :   To change the featured status of the all modules
        *******************************************************************************************/
        const handleChangeFeatured = useCallback(async (ids, featured) => {
            try {
                dispatch(apiCallBegan());
                const body = { ids, featured, columnKey };
                const response = await CS.callApi({ url: API.FEATURED_CHANGE, body, method: 'post', isAuthorized: true });
                if (response.status === 1) {
                    CS.showSuccessMessage(response.message);
                    const updatedItems = items.map(item => {
                        const entity = { ...item };
                        if (ids.indexOf(item._id) !== -1) {
                            entity.featured = featured;
                            entity.checked = false;
                        }
                        return entity;
                    });
                    dispatch(setItems({ columnKey, items: updatedItems }));
                    setSelectAll(false);
                }
                dispatch(apiCallEnd());
            } catch (error) {
                console.error('error In ====>>>> handleChangeFeatured <<<<====', error);
                dispatch(apiCallFailed());
                history.replace(URL.login);
            }
        }, [dispatch, history, items]);

        /******************************************************************************************
        @Purpose    :   To multiple delete coupons
        *******************************************************************************************/
        const handleMultipleDelete = useCallback(() => {
            const ids = items.filter(item => item.checked).map(item => item._id);
            handleDelete(ids);
        }, [handleDelete, items]);

        /******************************************************************************************
        @Purpose    :   To multiple status change
        *******************************************************************************************/
        const handleMultipleStatus = useCallback((value) => {
            const ids = items.filter(item => item.checked).map(item => item._id);
            handleChangeStatus(ids, value);
        }, [handleChangeStatus, items]);

        /******************************************************************************************
        @Purpose    :   To multiple featured status change
        *******************************************************************************************/
        const handleMultipleFeatured = useCallback((value) => {
            const ids = items.filter(item => item.checked).map(item => item._id);
            handleChangeFeatured(ids, value);
        }, [handleChangeFeatured, items]);

        /******************************************************************************************
        @Purpose    :   To update the column settings
        *******************************************************************************************/
        const handleColumnSettings = useCallback(async (columnSettings) => {
            try {
                const body = { columnKey, columnSettings };
                const response = await CS.callApi({ url: API.COLUMN_SETTINGS, body, method: 'post', isAuthorized: true });
                if (response.status === 1) dispatch(setColumnSettings({ columnKey, columnSettings }));
            } catch (error) {
                console.error('error In ====>>>> handleColumnSettings <<<<====', error);
            }
        }, [dispatch]);

        return (
            <WrappedComponent
                {...props}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                handleSelectSingle={handleSelectSingle}
                handleSelectAll={handleSelectAll}
                handleColumnSettings={handleColumnSettings}
                handleChangeStatus={handleChangeStatus}
                handleMultipleStatus={handleMultipleStatus}
                handleDelete={handleDelete}
                handleMultipleDelete={handleMultipleDelete}
                handleChangeFeatured={handleChangeFeatured}
                handleMultipleFeatured={handleMultipleFeatured}
            />
        );
    };

    return WithCommon;
};

export default withCommon;