//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import * as API from '../../../constants/API';
import URL from '../../../constants/URL';
import MSG from '../../../constants/MSG';
//	Externals
import React, { useEffect, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
//	Component starts
const ContactSettings = () => {
	//	Redux-store
	const dispatch = useDispatch();
	//  History
	const history = useHistory();
	//	State values
	const [numberFormat, setNumberFormat] = useState('');
	const [emailId, setEmailId] = useState('');
	const [mobile, setMobile] = useState('');
	//	Errors
	const [errors, setErrors] = useState({ emailId: '', mobile: '' });

	/******************************************************************************************
	@Purpose    :   To get contact settings
	*******************************************************************************************/
	const getDetails = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const response = await CS.callApi({ url: API.CONTACT_SETTINGS, isAuthorized: true });
			if (response.status === 1) {
				const { contactInfo } = response.data;
				setEmailId(contactInfo.emailId);
				setMobile(contactInfo.mobile);
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getDetails <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	}, [dispatch, history]);

	useEffect(() => {
		getDetails();
		dispatch(resetLoading());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	/******************************************************************************************
	@Purpose    :   To change the mobile number with country code and number
	*******************************************************************************************/
	const handleMobileChange = (value, country) => {
		const { dialCode } = country;
		const mobileNumber = value.slice(dialCode.length);
		setMobile(`+${dialCode}-${mobileNumber}`);
		setNumberFormat(country.format);
	};

	/******************************************************************************************
	@Purpose    :   To validate form
	*******************************************************************************************/
	const validateForm = () => {
		//	Email id
		if (isEmpty(emailId)) errors.emailId = MSG.REQUIRED_EMAIL;
		else if (!CS.validateEmail(emailId)) errors.emailId = MSG.REQUIRED_PROPER_EMAIL;
		else errors.emailId = '';
		//	Phone number
		if (isEmpty(mobile)) errors.mobile = MSG.PROVIDE_MOBILE_NUMBER;
		else if (!isEmpty(numberFormat) && !CS.validateMobileNumber(numberFormat, mobile)) errors.mobile = MSG.REQUIRED_PROPER_MOBILE_NUMBER;
		else errors.mobile = '';
		//  Errors
		setErrors({ ...errors });
		return (isEmpty(errors.emailId) && isEmpty(errors.mobile)) ? true : false;
	};

	/******************************************************************************************
	@Purpose    :   To update the contact settings
	*******************************************************************************************/
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		try {
			dispatch(apiCallBegan());
			const body = { contactInfo: { emailId, mobile } };
			const response = await CS.callApi({ url: API.CONTACT_SETTINGS, body, method: 'put', isAuthorized: true });
			if (response.status) CS.showSuccessMessage(response.message);
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> Add-Update Stripe Settings <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	};

	return (
		<>
			<BreadCrumbs title='Settings' data={[{ label: 'Contact Details' }]} />
			<div className='card strip-card'>
				<form onSubmit={(event) => handleSubmit(event)}>
					<div id='accordion' className='collapse-setting'>
						<div className='card mb-5'>
							<div id='collapseOne' className='collapse show' aria-labelledby='headingOne' data-parent='#accordion'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='card-strip'>
											<div className='modal-header p-0 pb-3 mb-4 w-100'>
												<h5 className='modal-title'>Contact Details</h5>
											</div>
											<div className='row'>
												<div className='form-group col-md-6'>
													<label htmlFor='emailId'>Email</label>
													<input
														value={emailId}
														onChange={(e) => setEmailId(e.target.value)}
														type='text'
														id='emailId'
														className='form-control'
														placeholder='Email' />
													<span className='text-danger error-msg'>{errors.emailId}</span>
												</div>
												<div className='form-group col-md-6'>
													<label className='mb-1 xl-label' htmlFor='phone'>Phone number<sup className='text-danger'>*</sup></label>
													<div className='w-100'>
														<PhoneInput
															className='form-control w-100'
															name='phone'
															country={'in'}
															masks={''}
															countryCodeEditable={false}
															value={mobile}
															onChange={handleMobileChange}
														/>
														<span className='text-danger d-block'>{errors.mobile}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='text-right mt-2'>
						<button className='btn btn-primary glow-primary mr-4' type='submit'>Save Changes</button>
						<Link to={URL.dashboard} className='btn btn-outline-primary'>Cancel</Link>
					</div>
				</form>
			</div >
		</>
	);
}

export default ContactSettings;