//  Internals
import * as URL from '../../constants/URL';
//  Externals
import { Link } from 'react-router-dom';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
//  Component starts
const BreadCrumbs = ({ title, data }) => {
    return (
        <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
                <li className='content-header-title' key={0}>{title}</li>
                <li className='breadcrumb-item' key={1}><Link to={URL.dashboard}><i className='bx bx-home-alt' /></Link></li>
                {data.map((o, i) => o.path ? <li className='breadcrumb-item' key={i + 2}><Link to={o.path}>{o.label}</Link></li> : <li className='breadcrumb-item active' key={i + 2}>{o.label}</li>)}
            </ol>
        </nav>
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

export default memo(BreadCrumbs);