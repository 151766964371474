//	Internals
import { setItems, setTotal, setColumnSettings, setFilterSettings, setTemplateSettings } from '../../../store/reducers/entities';
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import { usePrevious } from '../../../hooks/usePrevious';
import ValidFilters from '../../common/validFilters';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import GiftOrdersTable from './giftOrdersTable';
import SearchBox from '../../common/searchBox';
import withCommon from '../../hoc/withCommon';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
import Download from '../../common/download';
import Filters from '../../common/filters';
//	Externals
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
//	Pre-defined values
const columnKey = 'giftOrders';
//	Component starts
const GiftOrders = ({ handleColumnSettings }) => {
	//	Redux-store
	const dispatch = useDispatch();
	const { page, limit, searchText, validFilters, sort } = useSelector(state => state.entities[columnKey]);
	//  History
	const history = useHistory();
	//	Custom hooks
	const previousSearchText = usePrevious(searchText);

	/******************************************************************************************
	@Purpose    :   To get the orders list
	*******************************************************************************************/
	const getOrders = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const body = { page, limit, columnKey };
			//	Search text
			if (previousSearchText !== searchText) body.searchText = searchText;
			//	Sorting
			if (!isEmpty(sort.path) && ([1, -1].includes(sort.order))) body.sort = { [sort.path]: sort.order };
			//	Filters
			if (!isEmpty(validFilters)) body.filters = validFilters;
			//	Get the response from API
			const response = await CS.callApi({ url: API.GIFT_ORDERS, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				const { data } = response;
				const { columnSettings, filterSettings, templateSettings, listing, total } = data;
				//	Store the Data in Redux
				dispatch(setItems({ columnKey, items: listing }));
				dispatch(setTotal({ columnKey, total }));
				dispatch(setColumnSettings({ columnKey, columnSettings }));
				dispatch(setFilterSettings({ columnKey, filterSettings }));
				dispatch(setTemplateSettings({ columnKey, templateSettings }));
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getOrders <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	}, [dispatch, page, limit, previousSearchText, searchText, sort, validFilters, history]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getOrders(), [page, limit, searchText, sort, validFilters]);

	return (
		<>
			<BreadCrumbs
				title='Orders Management'
				data={[{ label: 'Gift Orders' }]} />
			<div className='dataTables_wrapper'>
				<div className='content-area position-relative'>
					<div className='grid'>
						<div className='grid-content'>
							<div className='user-listing-filterOptions mb-2 mt-3 d-block'>
								<div className='row mb-2'>
									<div className='col-sm-8 mb-2 position-static'>
										<div className='left-buttons d-flex'>
											<SearchBox
												columnKey={columnKey}
												searchText={searchText}
											/>
											<Filters columnKey={columnKey} />
										</div>
									</div>
									<div className='col-sm-4'>
										<div className='right-buttons d-flex justify-content-end'>
											<Download columnKey={columnKey} />
										</div>
									</div>
								</div>
								<ValidFilters columnKey={columnKey} />
							</div>
							<GiftOrdersTable
								columnKey={columnKey}
								onColumnSettings={handleColumnSettings}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withCommon({ component: GiftOrders, columnKey });