//  Internals
import { setFilters, setValidFilters } from '../../store/reducers/entities';
//  Externals
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
//  Component starts
const ValidFilters = ({ columnKey }) => {
    //	Redux-store
    const dispatch = useDispatch();
    const { validFilters, filters, defaultFilters } = useSelector(state => state.entities[columnKey]);
    /******************************************************************************************
    @Purpose    :   To remove the filter
    *******************************************************************************************/
    const removeFilter = (index) => {
        //  For filters
        let tempFilters = [...filters];
        tempFilters.splice(index, 1);
        if (filters.length === 1) tempFilters = [...defaultFilters];
        dispatch(setFilters({ columnKey, filters: tempFilters }));
        //  For valid filters
        let tempValidFilters = [...validFilters];
        tempValidFilters.splice(index, 1);
        dispatch(setValidFilters({ columnKey, validFilters: tempValidFilters }));
    };

    return (<div className='search-label'>
        <div className='multiple-text'>
            {
                validFilters.map((filter, i) => filter.selectedField.label !== 'Select Field' && (
                    <div className='text-label mr-2' key={i} >
                        {
                            filter.selectedField.type === 'boolean' ? <>
                                <label className='mb-0' >
                                    {filter.selectedField.label}<span className='text-success'> is </span>{filter.values.label}
                                </label>
                            </> : filter.selectedField.type === 'date' ? filter.selectedType.value === 'bt' ? <>
                                <label className='mb-0' >
                                    {filter.selectedField.label} <span className='text-success'> {filter.selectedType.label} </span>
                                    {moment(filter.values.greaterThan).format('DD-MM-YYYY')} <span className='text-success'> And </span> {moment(filter.values.lessThan).format('DD-MM-YYYY')}</label>
                            </> : ['in', 'nn'].includes(filter.selectedType.value) ? <><label className='mb-0' >
                                {filter.selectedField.label} <span className='text-success'> {filter.selectedType.label} </span>
                            </label></> : <>
                                <label className='mb-0' >
                                    {filter.selectedField.label} <span className='text-success'> {filter.selectedType.label} </span>
                                    {moment(filter.values.value).format('DD-MM-YYYY')}</label>
                            </> : filter.selectedType.value === 'bt' ? <><label className='mb-0' >
                                {filter.selectedField.label} <span className='text-success'> {filter.selectedType.label} </span> {filter.values.greaterThan} <span className='text-success'> And </span> {filter.values.lessThan}</label>
                            </> : <label className='mb-0' >
                                {filter.selectedField.label} <span className='text-success'> {filter.selectedType.label} </span> {filter.values.value}</label>
                        }
                        <label className='remove-label' onClick={() => removeFilter(i)}><em className='bx bx-x'></em></label>
                    </div>
                ))
            }
        </div>
    </div>);
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    columnKey: PropTypes.string.isRequired
};

export default ValidFilters;