//  Internals
import { setSort } from '../../store/reducers/entities';
//  Externals
import { useSelector, useDispatch } from 'react-redux';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
//  Component starts
const TableHeader = ({ columns, columnKey }) => {
    //  Redux-store
    const dispatch = useDispatch();
    const { sort } = useSelector(state => state.entities[columnKey]);

    /******************************************************************************************
    @Purpose    :   To render the class dynamically
    *******************************************************************************************/
    const renderClass = (column) => {
        let classes = '';
        if (column.sort) classes += 'cursor-pointer ';
        if (column.type === 'string') classes += 'text-left';
        if (column.type === 'number') classes += 'text-right';
        if (['date', 'boolean', 'checkbox'].includes(column.type)) classes += 'text-center';
        return classes;
    };

    /******************************************************************************************
    @Purpose    :   To handle the sort
    *******************************************************************************************/
    const raiseSort = (column) => {
        if (column.sort) {
            const { path } = column;
            const sortColumn = { path, order: 1 };
            if (sort.path === path && sort.order === 1) sortColumn.order = -1;
            dispatch(setSort({ columnKey, sort: sortColumn }));
        }
    };

    /******************************************************************************************
    @Purpose    :   To render the sort icon
    *******************************************************************************************/
    const renderSortIcon = (column) => {
        if (column.sort) {
            if (column.path !== sort.path) return <i className='bx bxs-sort-alt'></i>;
            if (sort.order === 1) return <i className={'bx bxs-chevron-up'}></i>
            return <i className={'bx bxs-chevron-down'}></i>
        }
    };

    return (<thead>
        <tr>{columns.map((column, i) => {
            if (column.type === 'checkbox') return (<th className={renderClass(column)} key={i} >{column.label}</th>);
            return (<th
                className={renderClass(column)}
                key={i}
                onClick={() => raiseSort(column)}>
                {column.label} {renderSortIcon(column)}
            </th>)
        })}
        </tr>
    </thead>);
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    columns: PropTypes.array.isRequired,
    columnKey: PropTypes.string.isRequired
};

export default memo(TableHeader);