//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import CS from '../../../services/commonService';
import API from '../../../constants/API';
//  Externals
import React, { useEffect, useReducer, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import DateTime from 'react-datetime';
import { isEmpty } from 'lodash';
//	Pre-defined values
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const borderColor = [...Array.from({ length: 12 }, (_) => '#39DA8A')];
const values = [...Array.from({ length: 12 }, (_, x) => x + 1)];
const initialState = { year: new Date().getFullYear(), max: 10, stepSize: 4, values };
//  Reducer
const reducer = (state, action) => {
    const newState = { ...state };
    //  Orders
    if (action.type === 'year') newState.year = action.value;
    if (action.type === 'max') newState.max = action.value;
    if (action.type === 'stepSize') newState.stepSize = action.value;
    if (action.type === 'values') newState.values = action.value;
    return newState;
};
//  Component starts
const Dashboard = () => {
    //	History
    const history = useHistory();
    //	Redux-store
    const dispatch = useDispatch();
    //  Counts
    const [totalRevenueCount, setTotalRevenueCount] = useState(0);
    const [ongoingOrders, setOngoingOrders] = useState(0);
    const [ordersCount, setOrdersCount] = useState(0);
    const [restaurants, setRestaurants] = useState(0);
    const [users, setUsers] = useState(0);
    //  Orders
    const [orders, dispatchOrders] = useReducer(reducer, initialState);
    //  Payments
    const [payments, dispatchPayments] = useReducer(reducer, initialState);
    const [totalRevenue, setTotalRevenue] = useState(0);

    /******************************************************************************************
    @Purpose    :   To get the proper graph data
    *******************************************************************************************/
    const getProperGraphData = (apiData, type) => {
        const result = Array.from({ length: 12 }, (_, x) => {
            const entity = apiData.find(o => o.month === x + 1);
            if (isEmpty(entity)) return 0;
            return entity.data;
        });
        const maxValue = Math.max(...result) ? Math.max(...result) : 4;
        const stepSize = Math.ceil(maxValue / 4);
        const remainder = maxValue % stepSize;
        const updateMaxValue = remainder ? maxValue + remainder : maxValue;
        if (type === 'orders') {
            dispatchOrders({ type: 'max', value: updateMaxValue });
            dispatchOrders({ type: 'stepSize', value: stepSize });
            dispatchOrders({ type: 'values', value: result });
        }
        else {
            dispatchPayments({ type: 'max', value: updateMaxValue });
            dispatchPayments({ type: 'stepSize', value: stepSize });
            dispatchPayments({ type: 'values', value: result });
        }
    };

    /******************************************************************************************
    @Purpose    :   To get the counts
    *******************************************************************************************/
    const getCounts = useCallback(async () => {
        try {
            dispatch(apiCallBegan());
            const response = await CS.callApi({ url: API.DASHBOARD, isAuthorized: true });
            if (response.status === 1) {
                const { data } = response;
                setTotalRevenueCount(data.totalRevenue);
                setOngoingOrders(data.ongoingOrders);
                setRestaurants(data.restaurants);
                setOrdersCount(data.orders);
                setUsers(data.users);
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> getCounts <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    }, [dispatch, history]);

    /******************************************************************************************
    @Purpose    :   To get the orders
    *******************************************************************************************/
    const getOrders = useCallback(async () => {
        try {
            dispatch(apiCallBegan());
            const response = await CS.callApi({ url: `${API.DASHBOARD_ORDERS}/${orders.year}`, isAuthorized: true });
            if (response.status === 1) getProperGraphData(response.data, 'orders');
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> getOrders <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    }, [dispatch, history, orders.year]);

    /******************************************************************************************
    @Purpose    :   To get the payments
    *******************************************************************************************/
    const getPayments = useCallback(async () => {
        try {
            dispatch(apiCallBegan());
            const response = await CS.callApi({ url: `${API.DASHBOARD_PAYMENTS}/${payments.year}`, isAuthorized: true });
            if (response.status === 1) {
                getProperGraphData(response.data.monthlyData, 'payments');
                setTotalRevenue(response.data.totalRevenue);
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> getPayments <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    }, [dispatch, history, payments.year]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getCounts(), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getOrders(), [orders.year]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getPayments(), [payments.year]);

    //  Orders
    const ordersData = {
        labels,
        datasets: [{
            borderColor,
            data: orders.values,
            backgroundColor: '#26C1C9',
            pointBorderColor: '#26C1C9',
            pointBackgroundColor: '#26C1C9'
        }]
    };

    const ordersOptions = {
        display: true,
        responsive: true,
        legend: { display: false },
        scales: { yAxes: [{ ticks: { min: 0, max: orders.max, stepSize: orders.stepSize } }] }
    };

    //  Payments
    const paymentsData = {
        labels,
        datasets: [{
            borderColor,
            backgroundColor: '#26C1C9',
            data: payments.values,
            pointBorderColor: '#26C1C9',
            pointBackgroundColor: '#26C1C9'
        }]
    };

    const paymentsOptions = {
        display: true,
        responsive: true,
        legend: { display: false },
        scales: { yAxes: [{ ticks: { min: 0, max: payments.max, stepSize: payments.stepSize } }] }
    };

    return (
        <div className="card">
            <div className="content-wrapper mb-5">
                <div className="row animated fadeIn dashboard-box">
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <div className="card ml-0">
                            <a href="">
                                <div className="card-body">
                                    <div className="clearfix">
                                        <div className="d-flex align-item-center ">
                                            <div className="dashboard-total">
                                                <i className='bx bxs-group' />
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="card-title mb-1">{users}</h3>
                                                <h4 className="card-text">User</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <div className="card">
                            <a href="">
                                <div className="card-body">
                                    <div className="clearfix">
                                        <div className="d-flex align-item-center">
                                            <div className="dashboard-total">
                                                <i className='bx bxs-group' />
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="card-title mb-1">{restaurants}</h3>
                                                <h4 className="card-text">Restaurant</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <div className="card">
                            <a href="">
                                <div className="card-body">
                                    <div className="clearfix">
                                        <div className="d-flex align-item-center">
                                            <div className="dashboard-total">
                                                <i className='bx bxs-group' />
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="card-title mb-1">{ongoingOrders}</h3>
                                                <h4 className="card-text">On Going Orders</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <div className="card mr-0 md-mr-10-ml-0">
                            <a href="">
                                <div className="card-body">
                                    <div className="clearfix">
                                        <div className="d-flex align-item-center">
                                            <div className="dashboard-total">
                                                <i className='bx bx-package'></i>
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="card-title mb-1">{ordersCount}</h3>
                                                <h4 className="card-text">Orders</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <div className="card ml-0 md-ml-10">
                            <a href="">
                                <div className="card-body">
                                    <div className="clearfix">
                                        <div className="d-flex align-item-center">
                                            <div className="dashboard-total">
                                                <i className='bx bx-receipt'></i>
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="card-title mb-1">{totalRevenueCount}</h3>
                                                <h4 className="card-text">Total Revenue</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12 col-lg-6 mb-0 mb-md-5'>
                    <div className="bar-box">
                        <div className="d-flex align-items-center justify-content-between border-bottom mb-5 pb-4">
                            <h3 className="mb-0">Total Orders</h3>
                            <div className="form-group mb-0">
                                <label className='text-left'>Year</label>
                                <DateTime
                                    inputProps={{ placeholder: 'Year' }}
                                    dateFormat='YYYY'
                                    timeFormat={false}
                                    value={new Date().setFullYear(orders.year)}
                                    onChange={(date) => dispatchOrders({ type: 'year', value: new Date(date).getFullYear() })}
                                    closeOnSelect={true}
                                    isValidDate={current => new Date(current).getFullYear() <= new Date().getFullYear()}
                                />
                            </div>
                        </div>
                        <br />
                        <Bar data={ordersData} options={ordersOptions} />
                    </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                    <div className="bar-box">
                        <div className="d-flex align-items-center justify-content-between border-bottom mb-3 pb-4">
                            <h3 className="mb-0">Payments</h3>
                            <div className="form-group mb-0">
                                <label className='text-left'>Year</label>
                                <DateTime
                                    inputProps={{ placeholder: 'Year' }}
                                    dateFormat='YYYY'
                                    timeFormat={false}
                                    value={new Date().setFullYear(payments.year)}
                                    onChange={(date) => dispatchPayments({ type: 'year', value: new Date(date).getFullYear() })}
                                    closeOnSelect={true}
                                    isValidDate={current => new Date(current).getFullYear() <= new Date().getFullYear()}
                                />
                            </div>
                        </div>
                        <h4 className="revenue-title mb-3">Total Revenue {totalRevenue}</h4>
                        <Bar data={paymentsData} options={paymentsOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;