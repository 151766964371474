//	Internals
import { columnsOrdering } from '../../../util/columnsOrdering';
import CS from '../../../services/commonService';
import * as URL from '../../../constants/URL';
import Table from '../../common/table';
import Icon from '../../icons/index';
//	Externals
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import React from 'react';
//	Component starts
const UpcomingPayoutsTable = ({ columnKey, onColumnSettings, onPayoutToRestaurant }) => {
	//	Pre-defined values
	const columns = [
		{
			label: '', status: false
		},
		{
			label: 'Restaurant Info',
			type: 'string',
			sort: true,
			path: 'restaurantName',
			content: (item) => <Link to={{
				pathname: `${URL.restaurants}/${item.restaurantId}`,
				state: { key: 'upcomingPayouts' }
			}}>
				<div className="d-flex align-items-center">
					<div className="flex-auto">{CS.renderImageUrl(item.restaurantPhoto)}</div>
					<div className="d-flex flex-column flex-wrap">
						<span className='pl-2 user-head'>{item.restaurantName}</span>
						<span className='pl-2'>{item.restaurantEmailId}</span>
					</div>
				</div>
			</Link>
		},
		{
			label: 'Amount',
			type: 'date',
			sort: true,
			path: 'amount'
		},
		{
			label: 'Bank/Acc',
			type: 'string',
			sort: false,
			path: 'phoneNo',
			content: (item) => <div className="d-flex align-items-center">
				<div className="d-flex flex-column flex-wrap">
					{!isEmpty(item.bankName) && !isEmpty(item.accountNo) ? <span className='pl-2 user-head'>{item.bankName}*****{item.accountNo}</span> : <span className='pl-2 user-head'>---</span>}
				</div>
			</div>
		},
		{
			label: 'Action',
			type: 'boolean',
			content: (item) => <>
				<Icon
					type='show'
					data-tip
					data-for='show'
					onClick={() => history.push(`${URL.upcomingPayouts}/${item.restaurantId}`)} />
				<ReactTooltip
					id='show'
					place='top'
					effect='solid'>Show</ReactTooltip>
				<button className='btn btn-primary btn-sm' onClick={() => onPayoutToRestaurant(item.restaurantId)}>Pay</button>
			</>
		}
	];
	//	History
	const history = useHistory();
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	//	Manipulate the data
	const availableColumns = columns.filter(column => {
		const entity = columnSettings.find(c => c.label === column.label);
		if (isEmpty(entity)) return column;
		if (entity.status) return column;
		return null;
	});
	const updatedColumns = columnsOrdering(availableColumns, columnSettings);

	return (
		<Table
			columnKey={columnKey}
			columns={updatedColumns}
			showColumnSettings={true}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	columnKey: PropTypes.string.isRequired,
	onColumnSettings: PropTypes.func.isRequired,
	onPayoutToRestaurant: PropTypes.func.isRequired
};

export default UpcomingPayoutsTable;