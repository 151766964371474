//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import * as API from '../../../constants/API';
import URL from '../../../constants/URL';
import MSG from '../../../constants/MSG';
//	Externals
import React, { useEffect, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
//	Component starts
const PaymentGateway = () => {
	//	Redux-store
	const dispatch = useDispatch();
	//  History
	const history = useHistory();
	//	State values
	const [stripe, setStripe] = useState({
		stripeSandbox: { status: true, secretKey: '', publishKey: '' },
		stripeLive: { status: false, secretKey: '', publishKey: '' }
	});
	//	Errors
	const [errors, setErrors] = useState({ sandboxSecretKey: '', sandboxPublishKey: '', liveSecretKey: '', livePublishKey: '' });

	/******************************************************************************************
	@Purpose    :   To get payment gateway settings
	*******************************************************************************************/
	const getDetails = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const response = await CS.callApi({ url: API.PAYMENT_GATEWAY_SETTINGS, isAuthorized: true });
			if (response.status === 1) setStripe({ ...response.data });
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getDetails <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	}, [dispatch, history]);

	useEffect(() => {
		getDetails();
		dispatch(resetLoading());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	/******************************************************************************************
	@Purpose    :   To validate form
	*******************************************************************************************/
	const validateForm = () => {
		const newErrors = {};
		if (stripe.stripeSandbox.status) {
			//	Sandbox secret key
			if (isEmpty(stripe.stripeSandbox.secretKey)) newErrors.sandboxSecretKey = MSG.REQUIRED_SECRET_KEY;
			else {
				newErrors.sandboxSecretKey = '';
				newErrors.liveSecretKey = '';
			}
			//  Sandbox publish key
			if (isEmpty(stripe.stripeSandbox.publishKey)) newErrors.sandboxPublishKey = MSG.REQUIRED_PUBLISH_KEY;
			else {
				newErrors.sandboxPublishKey = '';
				newErrors.livePublishKey = '';
			}
		}
		if (stripe.stripeLive.status) {
			//  Live secret key
			if (isEmpty(stripe.stripeLive.secretKey)) newErrors.liveSecretKey = MSG.REQUIRED_SECRET_KEY;
			else {
				newErrors.liveSecretKey = '';
				newErrors.sandboxSecretKey = '';
			}
			//  Live publish key
			if (isEmpty(stripe.stripeLive.publishKey)) newErrors.livePublishKey = MSG.REQUIRED_PUBLISH_KEY;
			else {
				newErrors.livePublishKey = '';
				newErrors.sandboxPublishKey = '';
			}
		}
		//  Errors
		setErrors({ ...newErrors });
		return isEmpty(newErrors.sandboxSecretKey) && isEmpty(newErrors.sandboxPublishKey) && isEmpty(newErrors.liveSecretKey) && isEmpty(newErrors.livePublishKey) ? true : false;
	};

	/******************************************************************************************
	@Purpose    :   To update the payment gateway settings
	*******************************************************************************************/
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		try {
			dispatch(apiCallBegan());
			const body = { ...stripe };
			const response = await CS.callApi({ url: API.PAYMENT_GATEWAY_SETTINGS, body, method: 'put', isAuthorized: true });
			if (response.status) CS.showSuccessMessage(response.message);
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> Add-Update Stripe Settings <<<<====', error);
			dispatch(apiCallFailed());
			history.push(URL.login);
		}
	};

	return (
		<>
			<BreadCrumbs title='Settings' data={[{ label: 'Payment Gateway' }]} />
			<div className='card strip-card'>
				<form onSubmit={(event) => handleSubmit(event)}>
					<div id='accordion' className='collapse-setting'>
						<div className='card mb-5'>
							<div className='card-header' id='headingOne'>
								<div className='collapse-title d-flex align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<div className='mr-md-6 mr-3'><img src='/assets/images/stripe.svg' alt='logo' /></div>
										<div>
											<h5 className='mb-1'>Stripe</h5>
											<h6 className='mb-0'>Stripe - Settings</h6>
										</div>
									</div>
								</div>
							</div>
							<div id='collapseOne' className='collapse show' aria-labelledby='headingOne' data-parent='#accordion'>
								<div className='row'>
									<div className='col-md-6'>
										<div className='card-strip'>
											<div className='form-group'>
												<input
													className='cursor-pointer'
													type='radio'
													name='cssradio'
													id='check'
													autoComplete='off'
													checked={stripe.stripeSandbox.status}
													onChange={() => setStripe({ stripeLive: { ...stripe.stripeLive, status: false }, stripeSandbox: { ...stripe.stripeSandbox, status: true } })}
												/>
												<span></span>
												<label htmlFor='check' className='ml-2 cursor-pointer'>Test Environment</label>
												<hr />
											</div>
											<div className='form-group'>
												<label htmlFor='sandboxPublishKey'>Publishable key</label>
												<input
													value={stripe.stripeSandbox.publishKey}
													onChange={(e) => setStripe({ ...stripe, stripeSandbox: { ...stripe.stripeSandbox, publishKey: e.target.value } })}
													type='text'
													id='sandboxPublishKey'
													className='form-control'
													placeholder='Enter here' />
												<span className='text-danger error-msg'>{errors.sandboxPublishKey}</span>
											</div>
											<div className='form-group'>
												<label htmlFor='sandboxSecretKey' className='p-0 mb-1'>Secret key</label>
												<input
													value={stripe.stripeSandbox.secretKey}
													onChange={(e) => setStripe({ ...stripe, stripeSandbox: { ...stripe.stripeSandbox, secretKey: e.target.value } })}
													type='text'
													id='sandboxSecretKey'
													className='form-control'
													placeholder='Enter here' />
												<span className='text-danger error-msg'>{errors.sandboxSecretKey}</span>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='card-strip'>
											<div className='form-group'>
												<input
													className='cursor-pointer'
													type='radio'
													name='cssradio'
													id='check1'
													autoComplete='off'
													checked={stripe.stripeLive.status}
													onChange={(e) => setStripe({ stripeSandbox: { ...stripe.stripeSandbox, status: false }, stripeLive: { ...stripe.stripeLive, status: true } })}
												/>
												<span></span>
												<label htmlFor='check1' className='ml-2 cursor-pointer'>Live Environment</label>
												<hr />
											</div>
											<div className='form-group'>
												<label htmlFor='livePublishKey'>Publishable key</label>
												<input
													value={stripe.stripeLive.publishKey}
													onChange={(e) => setStripe({ ...stripe, stripeLive: { ...stripe.stripeLive, publishKey: e.target.value } })}
													type='text'
													id='livePublishKey'
													className='form-control'
													placeholder='Enter here' />
												<span className='text-danger error-msg'>{errors.livePublishKey}</span>
											</div>
											<div className='form-group'>
												<label htmlFor='liveSecretKey' className='p-0 mb-1'>Secret key</label>
												<input
													value={stripe.stripeLive.secretKey}
													onChange={(e) => setStripe({ ...stripe, stripeLive: { ...stripe.stripeLive, secretKey: e.target.value } })}
													type='text'
													id='liveSecretKey'
													className='form-control'
													placeholder='Enter here' />
												<span className='text-danger error-msg'>{errors.liveSecretKey}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='text-right mt-2'>
						<button className='btn btn-primary glow-primary mr-4' type='submit'>Save Changes</button>
						<Link to={URL.dashboard} className='btn btn-outline-primary'>Cancel</Link>
					</div>
				</form>
			</div >
		</>
	);
}

export default PaymentGateway;