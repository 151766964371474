//  Externals
import PropTypes from 'prop-types';
import React from 'react';
//  Component starts
const SingleStatusToggle = ({ _id, status, onToggleChange, htmlFor }) => {
    return (<div className='custom-control custom-switch light'>
        <input
            type='checkbox'
            id={_id}
            className='custom-control-input'
            checked={status}
            onChange={onToggleChange}
        />
        <label
            className='custom-control-label'
            htmlFor={htmlFor}
        ></label>
    </div>);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    _id: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    htmlFor: PropTypes.string.isRequired,
    onToggleChange: PropTypes.func.isRequired
};

export default SingleStatusToggle;