//  Internals
import { apiCallBegan, resetLoading } from '../../store/reducers/loading';
import { setCurrentUser } from '../../store/reducers/auth';
import CS from '../../services/commonService';
import * as API from '../../constants/API';
import * as MSG from '../../constants/MSG';
import * as URL from '../../constants/URL';
import Theme from '../common/theme';
//  Externals
import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';
//	Component starts
const Login = () => {
	//	Redux-store
	const dispatch = useDispatch();
	//	History
	const history = useHistory();
	//	State values
	const [showPassword, setShowPassword] = useState(true);
	const [rememberMe, setRememberMe] = useState(false);
	const [password, setPassword] = useState('');
	const [emailId, setEmailId] = useState('');
	//	Errors
	const [errors, setErrors] = useState({ emailId: '', password: '' });

	useEffect(() => {
		dispatch(resetLoading());
		const localStorageRememberMe = CS.getItem('rememberMe');
		if (localStorageRememberMe === 'true') {
			const localStorageEmail = CS.getItem('emailId');
			const localStoragePassword = CS.getItem('password');
			setEmailId(localStorageEmail);
			setPassword(atob(localStoragePassword));
			setRememberMe(true);
		}
	}, [dispatch]);

	/******************************************************************************************
	@Purpose    :   To validate the login form
	*******************************************************************************************/
	const validateForm = () => {
		//  Email
		if (isEmpty(emailId)) errors.emailId = MSG.REQUIRED_EMAIL;
		else if (!CS.validateEmail(emailId)) errors.emailId = MSG.REQUIRED_PROPER_EMAIL;
		else errors.emailId = '';
		//  Password
		if (isEmpty(password)) errors.password = MSG.REQUIRED_PASSWORD;
		else if (!CS.validatePassword(password)) errors.password = MSG.PROVIDE_PROPER_PASSWORD;
		else errors.password = '';
		//  Errors
		setErrors({ ...errors });
		return (isEmpty(errors.emailId) && isEmpty(errors.password)) ? true : false;
	};

	/******************************************************************************************
	@Purpose    :   To submit the login form
	*******************************************************************************************/
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		try {
			const body = { emailId, password };
			await dispatch(apiCallBegan({ url: API.LOGIN, body, method: 'POST', onSuccess: setCurrentUser.type }));
			if (rememberMe) {
				CS.setItem('emailId', emailId);
				CS.setItem('password', btoa(password));
				CS.setItem('rememberMe', rememberMe);
			} else {
				CS.removeItem('emailId');
				CS.removeItem('password');
				CS.removeItem('rememberMe');
			}
			history.replace(URL.dashboard);
		}
		catch (error) {
			console.error('error In ====>>>> adminLogin <<<<====', error);
			history.replace(URL.login);
		}
	};

	/******************************************************************************************
	@Purpose    :   To show the tooltip for password show/hid
	*******************************************************************************************/
	const renderTooltip = () => showPassword ? 'Show Password' : 'Hide Password';

	return (
		<>
			<Theme />
			<div className='login-wrapper' style={{ background: 'url(../assets/images/login-bg.jpg) no-repeat center center' }}>
				<div className='login-body'>
					<div className='login-container row m-0'>
						<div className='col-xl-8 col-11'>
							<div className='card bg-login-card'>
								<div className='row m-0'>
									<div className='col-md-6 col-12 pl-0 pr-0'>
										<div className='card rounded-left mb-0 p-4 d-flex justify-content-center h-100'>
											<div className='card-header'>
												<div className='card-title text-center mb-5'>
													<h4 className='mb-3'>Welcome to OpaLink Admin</h4>
												</div>
											</div>
											<div className='sign-account'>
												<p>Sign in to your account</p>
											</div>
											<div className='card-content'>
												<div className='card-body'>
													<form onSubmit={(event) => handleSubmit(event)} >
														<div className='form-group mb-4'>
															<label htmlFor='emailId'>Email<sup className='text-danger'>*</sup></label>
															<input
																type='text'
																id='emailId'
																name='emailId'
																placeholder='Email'
																className={errors.emailId ? 'form-control input-error' : 'form-control'}
																value={emailId}
																onChange={(e) => {
																	setEmailId(e.target.value);
																	setErrors({ ...errors, emailId: '' });
																}}
															/>
															<span className='text-danger error-msg'>{errors.emailId}</span>
														</div>
														<div className='form-group mb-4 position-relative'>
															<label htmlFor='password'>Password<sup className='text-danger'>*</sup></label>
															<input
																className={errors.password ? 'form-control input-error' : 'form-control'}
																name='password'
																value={password}
																id='password'
																type={showPassword ? 'password' : 'text'}
																placeholder='Password'
																onChange={(e) => {
																	setPassword(e.target.value);
																	setErrors({ ...errors, password: '' });
																}} />
															<span
																onClick={() => setShowPassword(!showPassword)}
																data-tip
																data-for='registerTip'
																className={showPassword ? 'bx bx-hide field-icon toggle-password' : 'bx bx-show field-icon toggle-password'}
															></span>
															<ReactTooltip
																id='registerTip'
																place='top'
																effect='solid'>{renderTooltip()}</ReactTooltip>
															<em className='fa fa-lock' aria-hidden='true' />
															<span className='text-danger error-msg'>{errors.password}</span>
														</div>
														<div className='form-group d-flex flex-md-row flex-column justify-content-between align-items-center mb-3 mt-3'>
															<div className='text-left'>
																<div className='form-group form-check form-check-small mb-0'>
																	<input
																		type='checkbox'
																		className='form-check-input'
																		id='keepLogged'
																		checked={rememberMe}
																		onChange={() => setRememberMe(!rememberMe)}
																	/>
																	<label className='form-check-label' htmlFor='keepLogged'>Keep me logged in</label>
																</div>
															</div>
															<div className='text-right'>
																<Link
																	className='card-link'
																	to={{
																		pathname: URL.forgotPassword,
																		state: { emailId }
																	}}>Forgot Password?</Link>
															</div>
														</div>
														<button
															className='btn btn-primary glow position-relative btn-block'
															type='submit'
														>Login</button>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className='col-md-6 d-md-block d-none text-center align-self-center p-7'>
										<div className='card-content'>
											<img className='img-fluid' src='../assets/images/login.png' alt='login'></img>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;