/*************************************************************
@Purpose    :   Router links
*************************************************************/
module.exports = {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    modules: '/modules',
    dashboard: '/modules/dashboard',
    profile: '/modules/profile',

    userDetails: '/modules/users/:slug',
    users: '/modules/users',

    restaurantDetails: '/modules/restaurants/:slug',
    restaurants: '/modules/restaurants',
    updateRestaurantSlug: '/modules/update-restaurant/:slug',
    updateRestaurant: '/modules/update-restaurant/',

    orderDetails: '/modules/orders/:slug',
    orders: '/modules/orders',

    giftOrderDetails: '/modules/gift-orders/:slug',
    giftOrders: '/modules/gift-orders',

    updateCMS: '/modules/cms/:slug',
    CMS: '/modules/cms',

    updateEmailTemplate: '/modules/email-templates/:slug',
    emailTemplates: '/modules/email-templates',

    helpQueriesRestaurants: '/modules/help-queries-restaurants',
    helpQueriesUsers: '/modules/help-queries-users',
    helpTopics: '/modules/help-topics',

    categories: '/modules/categories',
    cuisines: '/modules/cuisines',
    coupons: '/modules/coupons',
    brands: '/modules/brands',

    upcomingPayoutDetails: '/modules/upcoming-payouts/:slug',
    upcomingPayouts: '/modules/upcoming-payouts',

    payoutsHistory: '/modules/payouts-history',

    generalSettings: '/modules/general-settings',
    paymentGatewaySettings: '/modules/payment-gateway-settings',
    contactSettings: '/modules/contact-settings'
};