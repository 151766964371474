//  Externals
import React, { memo } from 'react';
import PropTypes from 'prop-types';
//  Component start
const MultipleDeleteBox = ({ onClick }) => {
    return (
        <button
            onClick={() => onClick()}
            type='button'
            className='btn btn-default delete-btn minW-0 btn-bg-white'
        ><i className='bx bx-trash-alt'></i></button>
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default memo(MultipleDeleteBox);