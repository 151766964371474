import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'auth',
    initialState: {
        currentUser: {
            theme: 'light',             //  dark
            subMenu: '',
            mainMenu: 'dashboard',
            menuAlignment: 'vertical', //  horizontal
        },
        token: ''
    },
    reducers: {
        setCurrentUser: (auth, action) => {
            auth.currentUser = action.payload;
        },
        setTheme: (auth, action) => {
            auth.currentUser.theme = auth.currentUser.theme === 'dark' ? 'light' : 'dark';
        },
        setMainMenu: (auth, action) => {
            auth.currentUser.mainMenu = action.payload.mainMenu;
        },
        setSubMenu: (auth, action) => {
            auth.currentUser.subMenu = action.payload.subMenu;
        },
        setMenuAlignment: (auth, action) => {
            auth.currentUser.menuAlignment = auth.currentUser.menuAlignment === 'horizontal' ? 'vertical' : 'horizontal';
        },
        updateCurrentUser: (auth, action) => {
            auth.currentUser = action.payload;
        },
        resetCurrentUser: (auth, action) => {
            auth.currentUser = {
                theme: 'light',  //  dark
                menuAlignment: 'vertical'  //  horizontal
            };
        },
    }
});

export default slice.reducer;

export const { setCurrentUser, setTheme, setMainMenu, setSubMenu, setMenuAlignment, updateCurrentUser, resetCurrentUser } = slice.actions;