//	Internals
import SingleStatusToggle from '../../common/singleStatusToggle';
import { columnsOrdering } from '../../../util/columnsOrdering';
import CS from '../../../services/commonService';
import * as URL from '../../../constants/URL';
import CheckBox from '../../common/checkBox';
import Table from '../../common/table';
import Icon from '../../icons/index';
//	Externals
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Select from 'react-dropdown';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
//	Component starts
const RestaurantsTable = ({ columnKey, selectAll, onDelete, onSelectAll, onSelectSingle, onChangeStatus, onVerifyStatus, onChangeFeatured, onColumnSettings }) => {
	//	Pre-defined values
	const options = [
		{ value: 'pending', label: 'Pending' },
		{ value: 'approved', label: 'Approve' },
		{ value: 'rejected', label: 'Reject' }
	];
	const columns = [
		{
			label: <CheckBox checked={selectAll} onChange={onSelectAll} />,
			type: 'checkbox',
			content: (item) => <CheckBox checked={item.checked} onChange={() => onSelectSingle(item._id)} />
		},
		{
			label: 'Joined Date',
			type: 'date',
			sort: true,
			path: 'createdAt',
			content: (item) => moment(item.createdAt).format('DD-MM-YYYY')
		},
		{
			label: 'Restaurant Name',
			type: 'string',
			path: 'restaurantName',
			sort: true,
			content: (item) => <Link to={`${URL.restaurants}/${item._id}`}>
				<div className="d-flex align-items-center">
					<div className="flex-auto">{CS.renderImageUrl(item.photo)}</div>
					<div className="d-flex flex-column flex-wrap">
						<span className='pl-2'>{item.restaurantName}</span>
					</div>
				</div>
			</Link>,
		},
		{
			label: 'Email',
			type: 'string',
			sort: true,
			path: 'emailId',
			content: (item) => <div className='email-col d-flex align-items-center'>
				<div className='badge-outer circle round d-flex align-items-center mr-2'>
					<span className={item.emailVerificationStatus ? 'badge badge-success circle' : 'badge badge-danger circle'}></span>
				</div>
				<span>{item.emailId}
				</span>
			</div>
		},
		{
			label: 'Phone No',
			type: 'string',
			sort: true,
			path: 'phoneNumber'
		},
		{
			label: 'Featured',
			type: 'boolean',
			content: (item) => <SingleStatusToggle _id={`${item._id}featured`} status={item.featured} onToggleChange={() => onChangeFeatured([item._id], !item.featured)} htmlFor={`${item._id}featured`} />
		},
		{
			label: 'Status',
			type: 'boolean',
			content: (item) => <SingleStatusToggle _id={item._id} status={item.status} onToggleChange={() => onChangeStatus([item._id], !item.status)} htmlFor={item._id} />
		},
		{
			label: 'Verification Status',
			type: 'boolean',
			content: (item) => item.adminVerifiedStatus === 'pending' ? (<Select
				className='badge badge-pending'
				value={options.find(option => option.value === item.adminVerifiedStatus)}
				onChange={(option) => handleVerificationStatus(item._id, option.value)}
				options={options}
			/>) : item.adminVerifiedStatus === 'approved' ? <p className='badge badge-light-success mb-0'>Approved</p> : <p className='badge badge-light-danger mb-0'>Rejected</p>
		},
		{
			label: 'Action',
			type: 'boolean',
			content: (item) => <>
				<Icon
					type='show'
					data-tip
					data-for='show'
					onClick={() => history.push(`${URL.restaurants}/${item._id}`)} />
				<ReactTooltip
					id='show'
					place='top'
					effect='solid'>Show</ReactTooltip>
				<Icon
					type='delete'
					data-tip
					data-for='delete'
					onClick={() => onDelete([item._id])} />
				<ReactTooltip
					id='delete'
					place='top'
					effect='solid'>Delete</ReactTooltip>
			</>
		}
	];
	//	History
	const history = useHistory();
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	//	Manipulate the data
	const availableColumns = columns.filter(column => {
		const entity = columnSettings.find(c => c.label === column.label);
		if (isEmpty(entity)) return column;
		if (entity.status) return column;
		return null;
	});
	const updatedColumns = columnsOrdering(availableColumns, columnSettings);

	/******************************************************************************************
	@Purpose    :   To change restaurant verification status
	*******************************************************************************************/
	const handleVerificationStatus = (_id, value) => onVerifyStatus([_id], value);

	return (
		<Table
			columnKey={columnKey}
			columns={updatedColumns}
			showColumnSettings={true}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	onDelete: PropTypes.func.isRequired,
	selectAll: PropTypes.bool.isRequired,
	onSelectAll: PropTypes.func.isRequired,
	columnKey: PropTypes.string.isRequired,
	onSelectSingle: PropTypes.func.isRequired,
	onChangeStatus: PropTypes.func.isRequired,
	onVerifyStatus: PropTypes.func.isRequired,
	onChangeFeatured: PropTypes.func.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default RestaurantsTable;