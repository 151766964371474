//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
import * as MSG from '../../../constants/MSG';
//  Externals
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import Slider from 'rc-slider';
//  Component starts
const RestaurantUpdate = () => {
    //	Params
    const { slug } = useParams();
    //	History
    const history = useHistory();
    //	Redux-store
    const dispatch = useDispatch();
    //	State values
    const [errors, setErrors] = useState({ restaurantName: '', emailId: '', mobile: '', commissionRate: '' });
    const [formattedMobile, setFormattedMobile] = useState('');
    const [commissionRate, setCommissionRate] = useState(10);
    let [restaurantName, setRestaurantName] = useState('');
    const [numberFormat, setNumberFormat] = useState('');
    const [dialCode, setDialCode] = useState('');
    let [mobile, setMobile] = useState('');
    let [emailId, setEmailId] = useState('');

    /******************************************************************************************
    @Purpose    :   To get the restaurant details
    *******************************************************************************************/
    const getDetails = useCallback(async () => {
        try {
            dispatch(apiCallBegan());
            const response = await CS.callApi({ url: `${API.RESTAURANT}/${slug}`, isAuthorized: true });
            if (response.status === 1) {
                const { data } = response;
                //	Store the Data in local state
                setRestaurantName(data.restaurantName);
                setFormattedMobile(`${data.dialCode} ${data.mobile}`);
                setCommissionRate(Number(data.commissionRate));
                setDialCode(data.dialCode);
                setEmailId(data.emailId);
                setMobile(data.mobile);
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> getDetails <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    }, [dispatch, history, slug]);

    useEffect(() => {
        if (slug !== 'add') getDetails();
        dispatch(resetLoading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, slug]);

    /******************************************************************************************
    @Purpose    :   To handle mobile number change
    *******************************************************************************************/
    const handleMobileNumberChange = (value, country, event, formattedValue) => {
        setMobile(value);
        setDialCode(country.dialCode);
        setFormattedMobile(formattedValue);
        setNumberFormat(country.format);
    };

    /******************************************************************************************
    @Purpose    :   To validate form
    *******************************************************************************************/
    const validateForm = () => {
        restaurantName = restaurantName.trim();
        mobile = mobile.trim();
        emailId = emailId.trim();
        //  Restaurant name
        if (isEmpty(restaurantName)) errors.restaurantName = MSG.REQUIRED_RESTAURANT_NAME;
        else errors.restaurantName = '';
        //	Mobile
        if (isEmpty(mobile)) errors.mobile = MSG.PROVIDE_MOBILE_NUMBER;
        else if (!isEmpty(numberFormat) && !CS.validateMobileNumber(numberFormat, mobile)) errors.mobile = MSG.REQUIRED_PROPER_MOBILE_NUMBER;
        else errors.mobile = '';
        //  Email
        if (isEmpty(emailId)) errors.emailId = MSG.REQUIRED_EMAIL;
        else if (!CS.validateEmail(emailId)) errors.emailId = MSG.REQUIRED_PROPER_EMAIL;
        else errors.emailId = '';
        //  Errors
        setErrors({ ...errors });
        return isEmpty(errors.restaurantName) && isEmpty(errors.mobile) && isEmpty(errors.emailId) ? true : false;
    };

    /******************************************************************************************
    @Purpose    :   To update restaurant
    *******************************************************************************************/
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        try {
            dispatch(apiCallBegan());
            const body = { mobile, dialCode, emailId, restaurantName, commissionRate };
            let url = API.RESTAURANT;
            let method = 'post';
            if (slug !== 'add') {
                url = `${url}/${slug}`;
                method = 'put';
            }
            const response = await CS.callApi({ url, body, method, isAuthorized: true });
            if (response.status) {
                CS.showSuccessMessage(response.message);
                if (slug !== 'add') history.replace(`${URL.restaurants}/${slug}`);
                else history.replace(URL.restaurants);
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> Add-Update Restaurant <<<<====', error);
            dispatch(apiCallFailed());
            history.replace(URL.login);
        }
    };

    return (
        <>
            <form onSubmit={(event) => handleSubmit(event)}>
                {slug === 'add' ? <BreadCrumbs title={'Users Management'} data={[{ label: 'Restaurants', path: URL.restaurants }, { label: 'Add Restaurant' }]}
                /> : <BreadCrumbs title={'Users Management'} data={[{ label: 'Restaurants', path: URL.restaurants }, { label: restaurantName, path: `${URL.restaurants}/${slug}` }, { label: 'Update Restaurant' }]}
                />}
                <div className='card restaurant-card mb-5'>
                    <div className='modal-header p-0 pb-3 mb-4 w-100'>
                        <h5 className='box-title modal-title'><i className='bx bx-user mr-1'></i> Restaurant Info</h5>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row align-items-end'>
                                <div className='col-md-12'>
                                    <div className='user-title-info user-details'>
                                        <div className='row'>
                                            <div className='form-group col-md-6 mb-md-4 mb-3'>
                                                <label className='mb-0' htmlFor='restaurantName'>Restaurant Name</label>
                                                <div className='w-100'>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='restaurantName'
                                                        name='restaurantName'
                                                        placeholder='Restaurant Name'
                                                        value={restaurantName}
                                                        onChange={(e) => setRestaurantName(e.target.value)}
                                                    />
                                                    <span className='text-danger d-block'>{errors.restaurantName}</span>
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6 mb-md-4 mb-3'>
                                                <label className='mb-0 xl-label'>Phone Number</label>
                                                <div className='w-100'>
                                                    <PhoneInput
                                                        className='form-control w-100'
                                                        name='mobile'
                                                        country={'us'}
                                                        value={formattedMobile}
                                                        onChange={handleMobileNumberChange}
                                                    />
                                                    <span className='text-danger d-block'>{errors.mobile}</span>
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6 mb-md-4 mb-3'>
                                                <label className='mb-0' htmlFor="emailId">Email</label>
                                                <div className='w-100'>
                                                    <input
                                                        className='form-control disabled'
                                                        id='emailId'
                                                        disabled={slug === 'add' ? false : true}
                                                        name='emailId'
                                                        placeholder='Email'
                                                        type='text'
                                                        value={emailId}
                                                        onChange={(e) => setEmailId(e.target.value)}
                                                    />
                                                    <span className='text-danger d-block'>{errors.emailId}</span>
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6 mb-0'>
                                                <label className='mb-0'>Commission Rate</label>
                                                <input
                                                    className='form-control'
                                                    id='commissionRate'
                                                    name='commissionRate'
                                                    placeholder='Commission Rate'
                                                    value={commissionRate}
                                                    type='number'
                                                    onChange={(e) => setCommissionRate(Number(e.target.value))}
                                                />
                                                <Slider
                                                    className='mt-3'
                                                    defaultValue={10}
                                                    min={0}
                                                    max={100}
                                                    value={commissionRate}
                                                    onChange={(v) => setCommissionRate(Number(v))}
                                                />
                                                <span className='text-danger d-block'>{errors.commissionRate}</span>
                                            </div>
                                            <div className='col-md-12 mt-4 btn-blocks text-right'>
                                                <button className='btn btn-primary glow-primary mr-4'>Save Changes</button>
                                                <Link className='btn btn-outline-primary' to={slug !== 'add' ? `${URL.restaurants}/${slug}` : URL.restaurants}>Cancel</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default RestaurantUpdate;