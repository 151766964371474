import { Route, Switch } from 'react-router-dom';
import React, { useEffect } from 'react';

//	Constants
import * as URL from '../../constants/URL';
//	Components
//	Common
import Dashboard from './dashboard/index';
import Sidebar from './sideBar';
import Header from './header';
//	Admin profile
import Profile from './profile/profile';
//	Users
import UserDetails from './users/details';
import Users from './users/index';
//	Restaurants
import RestaurantDetails from './restaurants/details';
import UpdateRestaurant from './restaurants/update';
import Restaurants from './restaurants/index';
//  Orders
import OrderDetails from './orders/orderDetails';
import Orders from './orders/index';
//  Orders
import GiftOrderDetails from './giftOrders/giftOrderDetails';
import GiftOrders from './giftOrders/index';
//  Payouts
import UpcomingPayoutDetails from './upcomingPayouts/upcomingPayoutDetails';
import UpcomingPayouts from './upcomingPayouts/index';
import PayoutsHistory from './payoutsHistory/index';
//  CMS
import AddUpdateCMS from './cms/addUpdate';
import CMS from './cms/index';
//  Email Templates
import AddUpdateEmailTemplate from './emailTemplates/addUpdate';
import EmailTemplates from './emailTemplates/index';
//  Help
import HelpQueriesRestaurants from './helpQueriesRestaurants/index';
import HelpQueriesUsers from './helpQueriesUsers/index';
import HelpTopics from './helpTopics/index';
//  Master Management
import Categories from './categories/index';
import Cuisines from './cuisines/index';
import Coupons from './coupons/index';
import Brands from './brands/index';
//	Settings
import PaymentGatewaySettings from './settings/paymentGateway';
import GeneralSettings from './settings/general';
import ContactSettings from './settings/contact';
import PageNotFound from './pageNotFound';

const Home = () => {

	let Theme = 'light';
	let resize = true;
	let sidebar = true;

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [Theme, resize, sidebar]);

	return (
		<div className='container-scrollbar'>
			<Header />
			<Sidebar />
			<div className='page-body-wrapper'>
				<div className='main-panel'>
					<div className='content-wrapper'>
						<div className='main-content-area'>
							<div className='overlay' />
							<div className='main-content-block'>
								<Switch>
									<Route path={URL.profile} component={Profile} />

									<Route path={URL.userDetails} component={UserDetails} />
									<Route path={URL.users} component={Users} />

									<Route path={URL.restaurantDetails} component={RestaurantDetails} />
									<Route path={URL.restaurants} component={Restaurants} />
									<Route path={URL.updateRestaurantSlug} component={UpdateRestaurant} />

									<Route path={URL.orderDetails} component={OrderDetails} />
									<Route path={URL.orders} component={Orders} />

									<Route path={URL.giftOrderDetails} component={GiftOrderDetails} />
									<Route path={URL.giftOrders} component={GiftOrders} />

									<Route path={URL.upcomingPayoutDetails} component={UpcomingPayoutDetails} />
									<Route path={URL.upcomingPayouts} component={UpcomingPayouts} />

									<Route path={URL.payoutsHistory} component={PayoutsHistory} />

									<Route path={URL.updateCMS} component={AddUpdateCMS} />
									<Route path={URL.CMS} component={CMS} />

									<Route path={URL.updateEmailTemplate} component={AddUpdateEmailTemplate} />
									<Route path={URL.emailTemplates} component={EmailTemplates} />

									<Route path={URL.helpQueriesRestaurants} component={HelpQueriesRestaurants} />
									<Route path={URL.helpQueriesUsers} component={HelpQueriesUsers} />
									<Route path={URL.helpTopics} component={HelpTopics} />

									<Route path={URL.categories} component={Categories} />
									<Route path={URL.cuisines} component={Cuisines} />
									<Route path={URL.coupons} component={Coupons} />
									<Route path={URL.brands} component={Brands} />

									<Route path={URL.paymentGatewaySettings} component={PaymentGatewaySettings} />
									<Route path={URL.generalSettings} component={GeneralSettings} />
									<Route path={URL.contactSettings} component={ContactSettings} />
									<Route path={URL.dashboard} component={Dashboard} />
									<Route path='/*' component={PageNotFound} />
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;