//  Internals
import { setDefaultPagination, setSearchText } from '../../store/reducers/entities';
//  Externals
import { useDispatch } from 'react-redux';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
//  Component starts
const SearchBox = ({ columnKey, searchText }) => {
    //	Redux-store
    const dispatch = useDispatch();
    return (
        <div className='search position-relative has-icon-left'>
            <input
                type='search'
                className='form-control text-none'
                placeholder='Search'
                value={searchText}
                onChange={(e) => dispatch(setSearchText({ columnKey, searchText: e.target.value }))}
            />
            {!isEmpty(searchText) && (<div className='clear'>
                <span onClick={() => dispatch(setDefaultPagination({ columnKey }))}>
                    <span className='bx bx-x'></span>
                </span>
            </div>)}
            <div className='form-control-position'><span className='bx bx-search'></span></div>
        </div>
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    columnKey: PropTypes.string.isRequired,
    searchText: PropTypes.string.isRequired
};

export default memo(SearchBox);