//	Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import { setItems, setTotal } from '../../../store/reducers/entities';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import PayoutOrders from './payoutOrdersTable';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
//	Externals
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
//	Component starts
const UpcomingPayoutDetails = () => {
	//	Pre-defined values
	const columnKey = 'payoutOrdersTable';
	//	Params
	const { slug } = useParams();
	//	Redux-store
	const dispatch = useDispatch();
	const { page, limit, sort } = useSelector(state => state.entities[columnKey]);
	//	State values
	const [restaurantData, setRestaurantData] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	//	Manipulate the data
	const { restaurantName, emailId, mobile, dialCode, photo, address, yelpId } = restaurantData;

	/******************************************************************************************
	@Purpose    :   To get the restaurant details
	*******************************************************************************************/
	const getDetails = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const response = await CS.callApi({ url: `${API.RESTAURANT}/${slug}`, isAuthorized: true });
			if (response.status === 1) {
				const { data } = response;
				//	Store the Data in local state
				setRestaurantData({ ...data });
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getDetails <<<<====', error);
			dispatch(apiCallFailed());
		}
	}, [dispatch, slug]);

	/******************************************************************************************
	@Purpose    :   To get the total amount
	*******************************************************************************************/
	const getTotalPayout = async () => {
		try {
			dispatch(apiCallBegan());
			const body = { _id: slug };
			//	Get the response from API
			const response = await CS.callApi({ url: API.PAYOUT_DATA, body, method: 'post', isAuthorized: true });
			console.log("response ===>>>", response);
			if (response.status === 1) {
				setTotalAmount(response.data.totalAmount);
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getTotalPayout <<<<====', error);
			dispatch(apiCallFailed());
		}
	};

	/******************************************************************************************
	@Purpose    :   To get the orders related to payout
	*******************************************************************************************/
	//	To get payout orders
	const getPayouts = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const body = { page, limit, id: slug };
			//	Sorting
			if (!isEmpty(sort.path) && ([1, -1].includes(sort.order))) body.sort = { [sort.path]: sort.order };
			//	Get the response from API
			const response = await CS.callApi({ url: API.PAYOUTS, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				const { data, total } = response;
				dispatch(setTotal({ columnKey, total }));
				dispatch(setItems({ columnKey, items: data.listing }));
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getPayouts <<<<====', error);
			dispatch(apiCallFailed());
		}
	}, [dispatch, page, limit, sort.path, sort.order, slug]);

	useEffect(() => {
		if (!isEmpty(slug)) getPayouts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, limit, sort.path, sort.order, slug]);

	useEffect(() => {
		dispatch(resetLoading());
		if (!isEmpty(slug)) {
			getDetails();
			getTotalPayout();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug, dispatch]);

	/******************************************************************************************
	@Purpose    :   Render address
	*******************************************************************************************/
	const renderAddress = () => isEmpty(address) ? '-' : address;

	/******************************************************************************************
	@Purpose    :   Render yelp id
	*******************************************************************************************/
	const renderYelpId = () => isEmpty(yelpId) ? '-' : yelpId;

	/******************************************************************************************
	@Purpose    :   Render phone number
	*******************************************************************************************/
	const renderPhoneNumber = () => isEmpty(dialCode) || isEmpty(mobile) ? '-' : `(${dialCode}) ${mobile}`;

	return (
		<>
			<div className='d-flex align-items-center justify-content-between flex-wrap mb-2'>
				<BreadCrumbs title='Payouts Management' data={[{ label: 'Upcoming Payouts', path: URL.upcomingPayouts }, { label: 'Payout Details' }]} />
			</div>
			<div className='card-deck user-details-deck'>
				<div className='card persona-info-card profile-card mb-5'>
					<h4 className='d-flex align-items-center title mb-4'><i className='bx bx-user mr-1' />Basic Information</h4>
					<div className='d-flex info-content'>
						<div className='personal-info'>
							<ul className='info-list'>
								<li>
									<h6 className='info-title'>RESTAURANT NAME</h6>
									<span className='sub-content'>{restaurantName}</span>
								</li>
								<li>
									<h6 className='info-title'>PHONE NUMBER</h6>
									<span className='sub-content' >{renderPhoneNumber()}</span>
								</li>
								<li>
									<h6 className='info-title'>EMAIL ADDRESS</h6>
									<span className='sub-content'>{emailId}</span>
								</li>
								<li>
									<h6 className='info-title'>ADDRESS</h6>
									<span className='sub-content'>{renderAddress()}</span>
								</li>
								<li>
									<h6 className='info-title'>YELP ID</h6>
									<span className='sub-content'>{renderYelpId()}</span>
								</li>
							</ul>
						</div>
						<div className='user-item'>
							<div className='user-image'>{CS.renderImageUrl(photo)}</div>
						</div>
					</div>
				</div>
				<div className='card persona-info-card profile-card mb-5'>
					<h4 className='d-flex align-items-center title mb-4'><i className='bx bx-user mr-1' />Order Info</h4>
					<div className='d-flex info-content'>
						<div className='personal-info'>
							<ul className='info-list'>
								<li>
									<h6 className='info-title'>TOTAL AMOUNT</h6>
									<span className='sub-content'>{totalAmount}</span>
								</li>
								{/* <li>
									<h6 className='info-title'>TOTAL RECEIVED PAYMENTS</h6>
									<span className='sub-content'>$ {totalReceivedPayments}</span>
								</li>
								<li>
									<h6 className='info-title'>TOTAL COMMISSION</h6>
									<span className='sub-content'>$ {totalCommission}</span>
								</li>
								<li>
									<h6 className='info-title'>TOTAL TAX AMOUNT</h6>
									<span className='sub-content'>$ {totalTax}</span>
								</li>
								<li>
									<h6 className='info-title'>TOTAL EARNINGS</h6>
									<span className='sub-content'>$ {totalAmountToAdmin}</span>
								</li>
								<li>
									<h6 className='info-title'>COMMISSION RATE</h6>
									<span className='sub-content'>{commissionRate}%</span>
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='menu-item d-inline-block w-100 mt-5 mb-5'>
				<h4 className='d-flex align-items-center title mb-4'>Orders</h4>
				<PayoutOrders columnKey={columnKey} />
			</div>
		</>
	);
}

export default UpcomingPayoutDetails;