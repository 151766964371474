//	Internals
import SingleStatusToggle from '../../common/singleStatusToggle';
import { columnsOrdering } from '../../../util/columnsOrdering';
import CS from '../../../services/commonService';
import CheckBox from '../../common/checkBox';
import Table from '../../common/table';
import Icon from '../../icons/index';
//	Externals
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
//	Component starts
const CouponsTable = ({ setOpen, setCoupon, columnKey, selectAll, onDelete, onSelectAll, onChangeStatus, onSelectSingle, onChangeFeatured, onChangeOffered, onColumnSettings }) => {
	//	Pre-defined values
	const columns = [
		{
			label: <CheckBox checked={selectAll} onChange={onSelectAll} />,
			type: 'checkbox',
			content: (item) => <CheckBox checked={item.checked} onChange={() => onSelectSingle(item._id)} />
		},
		{
			label: 'Created On',
			type: 'date',
			sort: true,
			path: 'createdAt',
			content: (item) => moment(item.createdAt).format('DD-MM-YYYY')
		},
		{
			label: 'Coupon Name',
			type: 'string',
			sort: true,
			path: 'title',
			content: (item) => <a href='#' onClick={() => { setOpen(true); setCoupon(item); }}>
				<div className="d-flex align-items-center">
					<div className="flex-auto">{CS.renderImageUrl(item.photo)}</div>
					<div className="d-flex flex-column flex-wrap">
						<span className='pl-2'>{item.title}</span>
					</div>
				</div>
			</a>
		},
		{
			label: 'Coupon code',
			type: 'string',
			sort: true,
			path: 'code',
			content: (item) => <a href='#' onClick={() => { setOpen(true); setCoupon(item); }}>{item.code}</a>
		},
		{
			label: 'Start Date',
			type: 'date',
			sort: true,
			path: 'startDate',
			content: (item) => moment(item.startDate).format('DD-MM-YYYY, h:mm a')
		},
		{
			label: 'End Date',
			type: 'date',
			sort: true,
			path: 'endDate',
			content: (item) => moment(item.endDate).format('DD-MM-YYYY, h:mm a')
		},
		{
			label: 'Discount Rate',
			type: 'number',
			sort: true,
			path: 'discountRate'
		},
		{
			label: 'Position',
			type: 'number',
			sort: true,
			path: 'position'
		},
		{
			label: 'Offers',
			type: 'boolean',
			content: (item) => <SingleStatusToggle _id={`${item._id}offered`} status={item.offered} onToggleChange={() => onChangeOffered([item._id], !item.offered)} htmlFor={`${item._id}offered`} />
		},
		{
			label: 'Featured',
			type: 'boolean',
			content: (item) => <SingleStatusToggle _id={`${item._id}featured`} status={item.featured} onToggleChange={() => onChangeFeatured([item._id], !item.featured)} htmlFor={`${item._id}featured`} />
		},
		{
			label: 'Status',
			type: 'boolean',
			content: (item) => <SingleStatusToggle _id={item._id} status={item.status} onToggleChange={() => onChangeStatus([item._id], !item.status)} htmlFor={item._id} />
		},
		{
			label: 'Action',
			type: 'boolean',
			content: (item) => <>
				<Icon
					type='edit'
					data-tip
					data-for='edit'
					onClick={() => { setOpen(true); setCoupon(item); }} />
				<ReactTooltip
					id='edit'
					place='top'
					effect='solid'>Edit</ReactTooltip>
				<Icon
					type='delete'
					data-tip
					data-for='delete'
					onClick={() => onDelete([item._id])} />
				<ReactTooltip
					id='delete'
					place='top'
					effect='solid'>Delete</ReactTooltip>
			</>
		}
	];
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	/******************************************************************************************
	@Purpose    :   To available columns
	*******************************************************************************************/
	const availableColumns = columns.filter(column => {
		const entity = columnSettings.find(c => c.label === column.label);
		if (isEmpty(entity) || entity.status) return column;
		return null;
	});
	//	Manipulate the data
	const updatedColumns = columnsOrdering(availableColumns, columnSettings);

	return (
		<Table
			columnKey={columnKey}
			columns={updatedColumns}
			showColumnSettings={true}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	setOpen: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	setCoupon: PropTypes.func.isRequired,
	selectAll: PropTypes.bool.isRequired,
	onSelectAll: PropTypes.func.isRequired,
	columnKey: PropTypes.string.isRequired,
	onChangeStatus: PropTypes.func.isRequired,
	onSelectSingle: PropTypes.func.isRequired,
	onChangeFeatured: PropTypes.func.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default memo(CouponsTable);