import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useCallback, useRef } from 'react';

import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { setTheme } from '../../store/reducers/auth';
import Icon from '../icons/index';

const Theme = () => {

	const dispatch = useDispatch();

	const [toggle, setToggle] = useState(false);

	//	Theme selection -- Start
	const { theme } = useSelector(state => state.auth.currentUser);
	if (theme === 'light') {
		document.body.classList.add('light');
		document.body.classList.remove('dark');
	} else {
		document.body.classList.add('dark');
		document.body.classList.remove('light');
	}

	// Create a ref that we add to the element for which we want to detect outside clicks
	const ref = useRef();
	const onClose = useCallback(() => setToggle(false), []);
	// Call hook passing in the ref and a function to call on outside click
	useOnClickOutside(ref, onClose);

	return (
		<div ref={ref} className={toggle ? 'theme-setting-block open' : 'theme-setting-block'}>
			<span onClick={() => setToggle(!toggle)} className='theme-setting-link'>
				<Icon type='themeSettings' />
			</span>
			<div className='d-flex align-items-center'>
				<span className='light-icon icon mr-1 d-block'><Icon type='sun' /></span>
				<div className='custom-control custom-switch theme-switch'>
					<input type='checkbox' checked={theme === 'dark'} onChange={() => dispatch(setTheme())} className='custom-control-input' id='customSwitchTheme' />
					<label className='custom-control-label' htmlFor='customSwitchTheme'></label>
				</div>
				<span className='dark-icon icon'><Icon type='sun' /></span>
			</div>
		</div>
	);
}

export default Theme;