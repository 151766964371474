import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import './scss/styles.scss';
import React from 'react';
//  Redux-store
import { store, persistor } from './store/store';
//  Auth Routes
import { PublicRoute, PrivateRoute, LoginRoute } from './components/common/routes';
//	Components
import Home from './components/modules/home';
//	Public
import ForgotPassword from './components/public/forgotPassword';
import ResetPassword from './components/public/resetPassword';
import Login from './components/public/login';
//  Loader
import FullPageLoader from './loaders/fullPageLoader';
import * as URL from './constants/URL';

function App() {

    return (
        <div className='dashboard-container'>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Switch>
                            <LoginRoute path={URL.login} component={Login} />
                            <PublicRoute path={URL.resetPassword} component={ResetPassword} />
                            <PublicRoute path={URL.forgotPassword} component={ForgotPassword} />
                            <PrivateRoute path={URL.modules} component={Home} />
                            <Redirect from='/' to={URL.login} />
                        </Switch>
                    </BrowserRouter>
                    <FullPageLoader loading={false} />
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;