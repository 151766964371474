//	Internals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import { setColumnSettings, setItems, setTotal } from '../../../store/reducers/entities';
import MultipleDeleteBox from '../../common/multipleDeleteBox';
import { usePrevious } from '../../../hooks/usePrevious';
import EmailTemplateTable from './emailTemplateTable';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import SearchBox from '../../common/searchBox';
import withCommon from '../../hoc/withCommon';
import * as API from '../../../constants/API';
import * as URL from '../../../constants/URL';
//	Externals
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
//	Pre-defined values
const columnKey = 'emailTemplates';
//	Component starts
const EmailTemplates = ({ selectAll, setSelectAll, handleSelectSingle, handleSelectAll, handleDelete, handleMultipleDelete, handleColumnSettings }) => {
	//	Redux-store
	const dispatch = useDispatch();
	const { page, limit, searchText, items, sort } = useSelector(state => state.entities[columnKey]);
	//	History
	const history = useHistory();
	//	Custom hooks
	const previousSearchText = usePrevious(searchText);
	//	State values
	const checked = items.map(item => item.checked).includes(true);
	/******************************************************************************************
	@Purpose    :   To get the Email templates list
	*******************************************************************************************/
	const getEmailTemplates = useCallback(async () => {
		try {
			dispatch(apiCallBegan());
			const body = { page, limit, columnKey };
			//	Search text
			if (previousSearchText !== searchText) body.searchText = searchText;
			//	Sorting
			if (!isEmpty(sort.path) && ([1, -1].includes(sort.order))) body.sort = { [sort.path]: sort.order };
			//	Get the response from API
			const response = await CS.callApi({ url: API.EMAIL_TEMPLATES, body, method: 'post', isAuthorized: true });
			if (response.status === 1) {
				const { data } = response;
				const { listing, columnSettings, total } = data;
				dispatch(setItems({ columnKey, items: listing.map(list => { list.checked = false; return list; }) }));
				dispatch(setColumnSettings({ columnKey, columnSettings }));
				dispatch(setTotal({ columnKey, total }));
				//	Store the Data in local state
				setSelectAll(false);
			}
			dispatch(apiCallEnd());
		} catch (error) {
			console.error('error In ====>>>> getEmailTemplates <<<<====', error);
			dispatch(apiCallFailed());
			history.replace(URL.login);
		}
	}, [dispatch, page, limit, previousSearchText, searchText, sort.path, sort.order, setSelectAll, history]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getEmailTemplates(), [sort, page, limit, searchText]);

	return (
		<>
			<BreadCrumbs
				title='Email Templates'
				data={[{ label: 'Email Templates' }]} />
			<div className='dataTables_wrapper'>
				<div className='content-area position-relative'>
					<div className='grid'>
						<div className='grid-content'>
							<div className='user-listing-filterOptions mb-2 mt-3 d-block'>
								<div className='row mb-2'>
									<div className='col-sm-8 mb-2 position-static'>
										<div className='left-buttons d-flex'>
											<SearchBox
												columnKey={columnKey}
												searchText={searchText}
											/>
											{checked && <MultipleDeleteBox onClick={handleMultipleDelete} />}
										</div>
									</div>
									<div className='col-sm-4'>
										<div className='right-buttons d-flex justify-content-end'>
											<button
												onClick={() => history.push(`${URL.emailTemplates}/add`)}
												type='button'
												className='btn glow-primary btn-primary'
											>
												<span className='bx bx-user-plus d-lg-none'></span>
												<span className='d-none d-sm-none d-lg-inline-block'>Add Email Template</span>
											</button>
										</div>
									</div>
								</div>
							</div>
							<EmailTemplateTable
								columnKey={columnKey}
								selectAll={selectAll}
								onDelete={handleDelete}
								onSelectAll={handleSelectAll}
								onSelectSingle={handleSelectSingle}
								onColumnSettings={handleColumnSettings}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withCommon({ component: EmailTemplates, columnKey });