import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'language',
    initialState: 'en',
    reducers: {

    }
});

export default slice.reducer;