//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed, resetLoading } from '../../../store/reducers/loading';
import CS from '../../../services/commonService';
import * as API from '../../../constants/API';
import * as MSG from '../../../constants/MSG';
import * as URL from '../../../constants/URL';
//  Externals
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';
//	Component starts
const ChangePassword = (props) => {
    //	Redux-store
    const dispatch = useDispatch();
    //  History
    const history = useHistory();
    //	State values
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [hideOldPassword, setHideOldPassword] = useState(true);
    const [hideNewPassword, setHideNewPassword] = useState(true);
    const [errors, setErrors] = useState({ oldPassword: '', newPassword: '' });

    useEffect(() => {
        dispatch(resetLoading());
        setNewPassword('');
        setOldPassword('');
        setHideOldPassword(true);
        setHideNewPassword(true);
        setErrors({ oldPassword: '', newPassword: '' });
    }, [dispatch]);

    /******************************************************************************************
    @Purpose    :   To validate the admin change password form
    *******************************************************************************************/
    const validateForm = () => {
        //	Old password
        if (isEmpty(oldPassword)) errors.oldPassword = MSG.REQUIRED_OLD_PASSWORD;
        else if (!CS.validatePassword(oldPassword)) errors.oldPassword = MSG.PROVIDE_PROPER_PASSWORD;
        else errors.oldPassword = '';
        //	New password
        if (isEmpty(newPassword)) errors.newPassword = MSG.REQUIRED_NEW_PASSWORD;
        else if (!CS.validatePassword(newPassword)) errors.newPassword = MSG.PROVIDE_PROPER_PASSWORD;
        else errors.newPassword = '';
        //  Errors
        setErrors({ ...errors });
        return (isEmpty(errors.oldPassword) && isEmpty(errors.newPassword)) ? true : false;
    };
    /******************************************************************************************
    @Purpose    :   To submit the admin change password form
    *******************************************************************************************/
    const changePassword = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                dispatch(apiCallBegan());
                const body = { oldPassword, newPassword };
                const response = await CS.callApi({ url: API.CHANGE_PASSWORD, body, method: 'post', isAuthorized: true });
                if (response.status === 1) {
                    CS.showSuccessMessage(response.message);
                    props.handleLogout();
                }
                dispatch(apiCallEnd());
            } catch (error) {
                console.error('error In ====>>>> changePassword <<<<====', error);
                dispatch(apiCallFailed());
                history.replace(URL.login);
            }
        }
    };
    /******************************************************************************************
    @Purpose    :   To show the tooltip for old password show/hid
    *******************************************************************************************/
    const renderToolTipForOldPassword = () => hideOldPassword ? 'Show Password' : 'Hide Password';
    /******************************************************************************************
    @Purpose    :   To show the tooltip for new password show/hid
    *******************************************************************************************/
    const renderTooltipForNewPassword = () => hideNewPassword ? 'Show Password' : 'Hide Password';

    return (
        <div className='modal-content'>
            <div className='modal-header'>
                <h5 className='modal-title'>Change Password</h5>
            </div>
            <div className='notification-form modal-body password-modal'>
                <div className='row'>
                    <div className='col-md-12'>
                        <form onSubmit={(e) => changePassword(e)}>
                            <div className='mb-md-4 mb-3'>
                                <div className='form-group mb-0'>
                                    <label className='label xl-label'>Old Password</label>
                                    <div className='w-100 position-relative'>
                                        <input
                                            className='form-control'
                                            type={hideOldPassword ? 'password' : 'text'}
                                            placeholder='Old Password'
                                            name='oldPassword'
                                            value={oldPassword}
                                            onChange={(e) => {
                                                setOldPassword(e.target.value);
                                                setErrors({ ...errors, oldPassword: '' });
                                            }}
                                        />
                                        <span
                                            onClick={() => setHideOldPassword(!hideOldPassword)}
                                            data-tip
                                            data-for='oldPassword'
                                            className={hideOldPassword ? 'bx bx-hide field-icon-profile toggle-password' : 'bx bx-show field-icon-profile toggle-password'}
                                        ></span>
                                        <ReactTooltip
                                            id='oldPassword'
                                            place='top'
                                            effect='solid'>{renderToolTipForOldPassword()}</ReactTooltip>
                                    </div>
                                </div>
                                <div className='error-message-password'>
                                    <span className='text-danger error-msg d-block mb-2'>{errors.oldPassword}</span>
                                </div>
                            </div>
                            <div className='mb-md-4 mb-3 position-relative'>
                                <div className='form-group mb-0'>
                                    <label className='label xl-label'>New Password</label>
                                    <div className='w-100 position-relative'>
                                        <input
                                            className='form-control'
                                            type={hideNewPassword ? 'password' : 'text'}
                                            placeholder='New Password'
                                            id='newPassword'
                                            name='newPassword'
                                            value={newPassword}
                                            onChange={(e) => {
                                                setNewPassword(e.target.value);
                                                setErrors({ ...errors, newPassword: '' });
                                            }}
                                        />
                                        <span
                                            onClick={() => setHideNewPassword(!hideNewPassword)}
                                            data-tip
                                            data-for='newPassword'
                                            className={hideNewPassword ? 'bx bx-hide field-icon-profile toggle-password' : 'bx bx-show field-icon-profile toggle-password'}
                                        ></span>
                                        <ReactTooltip
                                            id='newPassword'
                                            place='top'
                                            effect='solid'>{renderTooltipForNewPassword()}</ReactTooltip>
                                        <em className='fa fa-lock' aria-hidden='true' />
                                    </div>
                                </div>
                                <div className='error-message-password' >
                                    <span className='text-danger error-msg d-block mb-2'>{errors.newPassword}</span>
                                </div>
                            </div>
                            <div className='mt-5 text-right'>
                                <button className='btn btn-primary' type='submit' >Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;