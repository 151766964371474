import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import React, { memo } from 'react';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import Icon from '../icons/index';

const DragHandler = sortableHandle(() => <Icon type='grid' />);

const SortableItem = sortableElement(({ columnSetting: { label, status }, onChange }) => (
	<li className='list-group-item toggle-vis d-flex'>
		<div className='custom-checkbox'>
			<DragHandler />
			<label htmlFor={label}>
				<input
					type='checkbox'
					autoComplete='off'
					name={label}
					id={label}
					checked={status}
					onChange={() => onChange(label, !status)}
				/>
				<span></span>{label}
			</label>
		</div>
	</li>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <ul className='list-group handle-list'>{children}</ul>;
});

const DragHandle = ({ columnSettings, onColumnSettings }) => {

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const updatedItems = arrayMove(columnSettings, oldIndex, newIndex);
		onColumnSettings([...updatedItems]);
	};

	const handleChange = (label, status) => {
		const updatedItems = columnSettings.map(columnSetting => {
			if (columnSetting.label === label) columnSetting = { ...columnSetting, status };
			return columnSetting;
		});
		onColumnSettings([...updatedItems]);
	};

	return (
		<SortableContainer onSortEnd={onSortEnd} useDragHandle>
			{columnSettings.map((columnSetting, index) => (
				<SortableItem key={index} index={index} columnSetting={columnSetting} onChange={handleChange} />
			))}
		</SortableContainer>
	);
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	columnSettings: PropTypes.array.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default memo(DragHandle);