//	Internals
import { updateCurrentUser } from '../../../store/reducers/auth';
import { apiCallBegan } from '../../../store/reducers/loading';
import BreadCrumbs from '../../common/breadcrumbs';
import CS from '../../../services/commonService';
import CropImage from '../../common/cropImage';
import MSG from '../../../constants/MSG';
import API from '../../../constants/API';
import URL from '../../../constants/URL';
//  Externals
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
//	Component starts
const Profile = () => {
	//	Redux-store
	const dispatch = useDispatch();
	const { firstName, lastName, phoneNumber, emailId, photo } = useSelector(state => state.auth.currentUser);
	//	History
	const history = useHistory();
	//	State values
	const [formData, setFormData] = useState({ firstName, lastName, phoneNumber, emailId });
	const [numberFormat, setNumberFormat] = useState('');
	//	Errors
	const [errors, setErrors] = useState({ firstName: '', lastName: '', phoneNumber: '', emailId: '' });

	/******************************************************************************************
	@Purpose    :   To change the form fields values
	*******************************************************************************************/
	const handleChange = (e) => {
		const { name, value } = e.target;
		formData[name] = value;
		setFormData({ ...formData });
	};

	/******************************************************************************************
	@Purpose    :   To change the phoneNumber number with country code and number
	*******************************************************************************************/
	const handlePhoneNumberChange = (value, country) => {
		const { dialCode } = country;
		const mobileNumber = value.slice(dialCode.length);
		formData.phoneNumber = `+${dialCode}-${mobileNumber}`;
		setNumberFormat(country.format);
		setFormData({ ...formData });
	};

	/******************************************************************************************
	@Purpose    :   To validate the admin profile update form
	*******************************************************************************************/
	const validateForm = () => {
		//	First name
		if (isEmpty(formData.firstName)) errors.firstName = MSG.REQUIRED_FIRST_NAME;
		else errors.firstName = '';
		//	Last name
		if (isEmpty(formData.lastName)) errors.lastName = MSG.REQUIRED_LAST_NAME;
		else errors.lastName = '';
		//	Phone number
		if (isEmpty(formData.phoneNumber)) errors.phoneNumber = MSG.PROVIDE_MOBILE_NUMBER;
		else if (!isEmpty(numberFormat) && !CS.validateMobileNumber(numberFormat, formData.phoneNumber)) errors.phoneNumber = MSG.REQUIRED_PROPER_MOBILE_NUMBER;
		else errors.phoneNumber = '';
		//  Errors
		setErrors({ ...errors });
		return (isEmpty(errors.firstName) && isEmpty(errors.lastName) && isEmpty(errors.phoneNumber)) ? true : false;
	};

	/******************************************************************************************
	@Purpose    :   To submit the admin profile update form
	*******************************************************************************************/
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		try {
			const body = { firstName, lastName, phoneNumber };
			await dispatch(apiCallBegan({ url: API.UPDATE_PROFILE, body, method: 'put', isAuthorized: true, onSuccess: updateCurrentUser.type }));
			CS.showSuccessMessage(MSG.USER_UPDATED_SUCCESSFULLY);
		}
		catch (error) {
			console.error('error In ====>>>> updateAdminProfile <<<<====', error);
			history.replace(URL.login);
		}
	};

	/******************************************************************************************
	@Purpose    :   To handle the file upload
	*******************************************************************************************/
	const handleFileUpload = async (file) => {
		const fileData = new FormData();
		fileData.append('file', file);
		try {
			const response = await CS.callApi({ url: API.FILE_UPLOAD, body: fileData, method: 'post', isAuthorized: true, isForm: true });
			if (response.status === 1) {
				const body = { photo: response.data.filePath };
				await dispatch(apiCallBegan({ url: API.UPDATE_ADMIN_PHOTO, body, method: 'put', isAuthorized: true, onSuccess: updateCurrentUser.type }));
				CS.showSuccessMessage(MSG.PHOTO_UPLOADED_SUCCESSFULLY);
			}
		} catch (error) {
			console.error('error In ====>>>> handleFileUpload <<<<====', error);
			history.replace(URL.login);
		}
	};

	/******************************************************************************************
	@Purpose    :   To remove the file upload
	*******************************************************************************************/
	const removePhoto = async () => {
		try {
			const body = { photo: '' };
			await dispatch(apiCallBegan({ url: API.UPDATE_ADMIN_PHOTO, body, method: 'put', isAuthorized: true, onSuccess: updateCurrentUser.type }));
			CS.showSuccessMessage(MSG.PHOTO_REMOVED_SUCCESSFULLY);
		}
		catch (error) {
			console.error('error In ====>>>> removePhoto <<<<====', error);
			history.replace(URL.login);
		}
	};

	return (
		<>
			<div className='d-flex align-items-center justify-content-between flex-wrap mb-2'>
				<BreadCrumbs title='Admin Profile' data={[{ label: 'Update Profile' }]} />
			</div>
			<div className='btn-blocks text-right mb-2'>
				<button className='btn btn-primary glow-primary' onClick={(event) => handleSubmit(event)}><em className='bx bx-revision mr-2' />Update</button>
			</div>
			<div className='card profile-card mb-5'>
				<div className='row align-items-center'>
					<div className='col-md-2 text-center'>
						<div className='edit-image'>
							<div className='user-image'>
								{CS.renderImageUrl(photo)}
								<label className='img-upload' htmlFor='attach-file'>
									<CropImage onFileUpload={handleFileUpload} />
									<em className='bx bxs-edit-alt' />
								</label>
							</div>
						</div>
						<div className='mt-3'>
							{(!isEmpty(photo)) && <button className='btn btn-primary' onClick={() => removePhoto()}>Remove photo</button>
							}
						</div>
					</div>
					<div className='col-md-10 mt-3'>
						<form onSubmit={(event) => handleSubmit(event)}>
							<div className='user-title-info user-details'>
								<div className='row'>
									<div className='form-group col-md-6 mb-md-4 mb-3'>
										<label className='mb-1' htmlFor='firstName'>First Name<sup className='text-danger'>*</sup></label>
										<div className='w-100'>
											<input
												type='text'
												className='form-control'
												id='firstName'
												name='firstName'
												placeholder='First Name'
												value={formData.firstName}
												onChange={(e) => handleChange(e)}
											/>
											<span className='text-danger d-block'>{errors.firstName}</span>
										</div>
									</div>
									<div className='form-group col-md-6 mb-md-4 mb-3'>
										<label className='mb-1' htmlFor='lastName'>Last Name<sup className='text-danger'>*</sup></label>
										<div className='w-100'>
											<input
												type='text'
												className='form-control'
												id='lastName'
												name='lastName'
												placeholder='Last Name'
												value={formData.lastName}
												onChange={(e) => handleChange(e)}
											/>
											<span className='text-danger d-block'>{errors.lastName}</span>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='form-group col-md-6 mb-md-4 mb-3'>
										<label className='mb-1' htmlFor='emailId'>Email<sup className='text-danger'>*</sup></label>
										<div className='w-100'>
											<input
												className='form-control disabled'
												id='emailId'
												name='emailId'
												disabled
												placeholder='Email'
												type='text'
												value={formData.emailId}
												onChange={(e) => handleChange(e)}
											/>
											<span className='text-danger d-block'>{errors.emailId}</span>
										</div>
									</div>
									<div className='btn-blocks col-md-6 mb-md-0 mb-2'>
										<div className='form-group  mb-md-4 mb-3'>
											<label className='mb-1 xl-label' htmlFor='phone'>Phone number<sup className='text-danger'>*</sup></label>
											<div className='w-100'>
												<PhoneInput
													className='form-control w-100'
													name='phone'
													country={'in'}
													masks={''}
													countryCodeEditable={false}
													value={formData.phoneNumber}
													onChange={handlePhoneNumberChange}
												/>
												<span className='text-danger d-block'>{errors.phoneNumber}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Profile;