//  Externals
import React, { useEffect, useRef } from 'react';
import SunEditor from 'suneditor-react';
import PropTypes from 'prop-types';
//  Component starts
const Editor = ({ description, setDescription }) => {
    const editorRef = useRef();
    useEffect(() => {
        // Get underlining core object here
        // Notice that useEffect is been used because you have to make sure the editor is rendered.
        console.log(editorRef.current.editor.core);
    }, []);
    return (
        <SunEditor
            ref={editorRef}
            name="description"
            placeholder="Please type here..."
            onChange={(content) => setDescription(content)}
            setContents={description}
            setOptions={{
                buttonList: [['blockquote', 'bold', 'font', 'fontSize', 'formatBlock', 'fullScreen', 'image', 'indent', 'italic', 'link', 'outdent', 'paragraphStyle', 'preview', 'print', 'redo', 'save', 'subscript', 'superscript', 'table', 'underline', 'undo', 'codeView']],
                controller: ['maxSize', 'remove', 'resize100'],
                width: "100%",
                minHeight: 400,
            }} />
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    description: PropTypes.string.isRequired,
    setDescription: PropTypes.func.isRequired
};

export default Editor;