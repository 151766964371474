//  Externals
import React, { memo } from 'react';
import Select from 'react-dropdown';
import PropTypes from 'prop-types';
//	Pre-defined values
const options = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
];
//  Component starts
const MultipleStatusSelect = ({ onChange }) => {
    return (<Select
        className='ml-2 custom-dropdown'
        placeholder='Status'
        onChange={(option) => onChange(option.value)}
        options={options}
    />);
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default memo(MultipleStatusSelect);