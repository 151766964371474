//  Externals
import React from 'react';
//	Pre-defined values
const icons = {
    sun: 'sun',
    edit: 'edit',
    menu: 'menu',
    settings: 'cog',
    show: 'show-alt',
    sort: 'sort-alt',
    sortAsc: 'chevron-up',
    sortDesc: 'chevron-down',
    delete: 'trash-alt',
    grid: 'grid-vertical',
    plus: 'plus-circle text-success',
    minus: 'minus-circle mr-1 text-danger',
    themeSettings: 'cog bx-flip-horizontal bx-spin',
};
//  Component starts
const Icon = ({ type, onClick, ...props }) => {
    return (<span className='cursor-pointer bx-btn' onClick={onClick}><i className={`bx bx-${icons[type]}`} {...props} /></span>);
};

export default Icon;