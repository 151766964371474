/*************************************************************
@Purpose    :   API end points
*************************************************************/
module.exports = {
    //  Authentication
    LOGIN: '/admin/login',
    LOG_OUT: '/admin/logout',
    FORGOT_PASSWORD: '/admin/forgotPassword',
    RESET_PASSWORD: '/admin/resetPassword',
    //  Profile
    UPDATE_PROFILE: '/admin/profile',
    FILE_UPLOAD: '/admin/fileUpload',
    UPDATE_ADMIN_PHOTO: '/admin/updatePhoto',
    CHANGE_PASSWORD: '/admin/changePassword',
    //  Users
    USERS: '/usersManagement/users',
    GET_USER: '/usersManagement/user/',
    DELETE_USER: '/user/deleteUsers',
    //  Restaurants
    RESTAURANTS: '/restaurantsManagement/restaurants',
    VERIFY_RESTAURANT_STATUS: '/restaurantsManagement/verifyStatus',
    RESTAURANT: '/restaurantsManagement/restaurant',
    RESTAURANT_MENU_ITEMS: '/restaurantsManagement/menuItems/',
    //  Orders
    ORDERS: '/ordersManagement/orders',
    ORDER_DETAILS: '/ordersManagement/order/',
    //  Gift Orders
    GIFT_ORDERS: '/ordersManagement/giftOrders',
    GIFT_ORDER_DETAILS: '/ordersManagement/giftOrder/',
    //  Payouts
    PAYOUT_DATA: '/payoutsManagement/nextPayoutData',
    UPCOMING_PAYOUTS: '/payoutsManagement/upcomingPayouts',
    PAYOUTS: '/payoutsManagement/payouts',
    PAYOUT_TO_RESTAURANT: '/payoutsManagement/payoutRestaurant',
    PAYOUTS_HISTORY: '/payoutsManagement/history',
    //  CMS
    CMSES: '/cmsManagement/cmsList',
    CMS: '/cmsManagement/cms',
    //  Email Templates
    EMAIL_TEMPLATES: '/emailTemplates/emailTemplates',
    EMAIL_TEMPLATE: '/emailTemplates/emailTemplate',
    //  Help Queries Management
    HELP_QUERIES: '/helpQueriesManagement/helpQueries',
    DELETE_HELP_QUERIES: '/helpQueriesManagement/helpQuery',
    CHANGE_HELP_QUERY_STATUS: '/helpQueriesManagement/changeStatus',
    //  Help Topics Management
    HELP_TOPICS: '/helpTopicManagement/helpTopics',
    HELP_TOPIC: '/helpTopicManagement/helpTopic',
    //  Master Management
    //  Categories
    CATEGORIES: '/categoryManagement/categories',
    CATEGORY: '/categoryManagement/category',
    //  Brands
    BRANDS: '/brandsManagement/brands',
    BRAND: '/brandsManagement/brand',
    //  Coupons
    COUPONS: '/couponsManagement/coupons',
    COUPON: '/couponsManagement/coupon',
    COUPON_OFFERED_CHANGE: '/couponsManagement/changeOffer',
    //  Cuisines
    CUISINES: '/cuisinesManagement/cuisines',
    CUISINE: '/cuisinesManagement/cuisine',
    //  Settings
    CONTACT_SETTINGS: '/settingsManagement/contactSettings',
    PAYMENT_SETTINGS: '/settingsManagement/paymentSettings',
    PAYMENT_GATEWAY_SETTINGS: '/settingsManagement/paymentGatewaySettings',
    //  Dashboard
    DASHBOARD: '/dashboardManagement/dashboard',
    DASHBOARD_ORDERS: '/dashboardManagement/orders',
    DASHBOARD_PAYMENTS: '/dashboardManagement/payments',
    //  Common
    DELETE: '/admin/delete',
    STATUS_CHANGE: '/admin/changeStatus',
    FEATURED_CHANGE: '/admin/changeFeatured',
    COLUMN_SETTINGS: '/admin/saveColumnSettings',
    TEMPLATE_SETTINGS: '/admin/templateSettings',
    FILTER_SETTINGS: '/admin/filterSettings',
    DOWNLOAD_CSV: '/admin/downloadCsv',
    DOWNLOAD_EXCEL: '/admin/downloadExcel',
    UPDATE_THEME: 'admin/updateThemeDetails'
};