//	Internals
import { columnsOrdering } from '../../../util/columnsOrdering';
import CS from '../../../services/commonService';
import * as URL from '../../../constants/URL';
import CheckBox from '../../common/checkBox';
import Table from '../../common/table';
import Icon from '../../icons/index';
//  Externals
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import React, { memo } from 'react';
import Select from 'react-dropdown';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
//	Component starts
const HelpQueriesTable = ({ setOpen, setQuery, columnKey, selectAll, onDelete, onSelectAll, onSelectSingle, onVerifyStatus, onColumnSettings }) => {
	//	Redux-store
	const { columnSettings } = useSelector(state => state.entities[columnKey]);
	//	Pre-defined values
	const options = [
		{ value: 'open', label: 'Open' },
		{ value: 'closed', label: 'Close' }
	];
	const columns = [
		{
			label: <CheckBox checked={selectAll} onChange={onSelectAll} />,
			type: 'checkbox',
			content: (item) => <CheckBox checked={item.checked} onChange={() => onSelectSingle(item._id)} />
		},
		{
			label: 'Created On',
			type: 'date',
			sort: true,
			path: 'createdAt',
			content: (item) => moment(item.createdAt).format('DD-MM-YYYY')
		},
		{
			label: 'User Name',
			type: 'string',
			path: 'name',
			sort: true,
			content: (item) => <Link to={{
				pathname: `${URL.users}/${item.userId}`,
				state: { key: 'helpQueries' }
			}} >
				<div className="d-flex align-items-center">
					<div className="flex-auto">{CS.renderImageUrl(item.photo)}</div>
					<div className="d-flex flex-column flex-wrap">
						<span className='pl-2 user-head'>{item.name}</span>
						<span className='pl-2'>{item.emailId}</span>
					</div>
				</div>
			</Link>,
		},
		{
			label: 'Query',
			type: 'string',
			sort: true,
			path: 'query'
		},
		{
			label: 'Status',
			type: 'boolean',
			path: 'status',
			content: (item) => item.status === 'open' ? (<Select
				className='badge badge-pending'
				value={options.find(option => option.value === item.status)}
				onChange={(option) => handleVerificationStatus(item._id, option.value)}
				options={options}
			/>) : <p className='badge badge-light-danger mb-0'>Closed</p>
		},
		{
			label: 'Action',
			type: 'boolean',
			content: (item) => <>
				<Icon
					type='show'
					data-tip
					data-for='show'
					onClick={() => { setOpen(true); setQuery(item); }} />
				<ReactTooltip
					id='show'
					place='top'
					effect='solid'>Show</ReactTooltip>
				<Icon
					type='delete'
					data-tip
					data-for='delete'
					onClick={() => onDelete([item._id])} />
				<ReactTooltip
					id='delete'
					place='top'
					effect='solid'>Delete</ReactTooltip>
			</>
		}
	];

	/******************************************************************************************
	@Purpose    :   To available columns
	*******************************************************************************************/
	const availableColumns = columns.filter(column => {
		const entity = columnSettings.find(c => c.label === column.label);
		if (isEmpty(entity)) return column;
		if (isEmpty(entity) || entity.status) return column;
		return null;
	});
	//	Manipulate the data
	const updatedColumns = columnsOrdering(availableColumns, columnSettings);

	/******************************************************************************************
	@Purpose    :   To change restaurant verification status
	*******************************************************************************************/
	const handleVerificationStatus = (_id, value) => onVerifyStatus([_id], value);

	return (
		<Table
			columnKey={columnKey}
			columns={updatedColumns}
			showColumnSettings={true}
			onColumnSettings={onColumnSettings}
		/>
	);
};

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
	setOpen: PropTypes.func.isRequired,
	setQuery: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	selectAll: PropTypes.bool.isRequired,
	onSelectAll: PropTypes.func.isRequired,
	columnKey: PropTypes.string.isRequired,
	onSelectSingle: PropTypes.func.isRequired,
	onVerifyStatus: PropTypes.func.isRequired,
	onColumnSettings: PropTypes.func.isRequired
};

export default memo(HelpQueriesTable);