//  Externals
import { useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
//  Component starts
const TableBody = ({ columns, columnKey }) => {
    //  Redux-store
    const { items } = useSelector(state => state.entities[columnKey]);
    /******************************************************************************************
    @Purpose    :   To render the class dynamically
    *******************************************************************************************/
    const renderClass = (type) => {
        let classes = '';
        if (type === 'string') classes += 'text-left';
        if (type === 'number') classes += 'text-right';
        if (['date', 'boolean', 'checkbox'].includes(type)) classes += 'text-center';
        return classes;
    };
    /******************************************************************************************
    @Purpose    :   To render the cell dynamically
    *******************************************************************************************/
    const renderCell = (item, column) => {
        return column.content ? column.content(item) : _.get(item, column.path);
    };

    return (
        <tbody>
            {isEmpty(items) ? (
                <tr className='text-center text-danger not-found-txt'>
                    <td colSpan={columns.length} className='text-center'><h6 className='text-center mb-0 text-danger not-found-txt'>No Records found!</h6></td>
                </tr>
            ) : items.map((item, i) => (
                <tr key={i}>
                    {columns.map((column, j) => {
                        if (column.type === 'checkbox') return (<td key={j} className={renderClass(column.type)} >{renderCell(item, column)}</td>);
                        return (<td key={j} className={renderClass(column.type)} style={column.type === 'number' ? { paddingRight: '38px' } : {}}>{renderCell(item, column)}</td>);
                    })}
                </tr>
            ))}
        </tbody>
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    columns: PropTypes.array.isRequired,
    columnKey: PropTypes.string.isRequired,
};

export default memo(TableBody);