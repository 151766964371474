//  Internals
import { apiCallBegan, apiCallEnd, apiCallFailed } from '../../../store/reducers/loading';
import { setItems, setDefaultPagination } from '../../../store/reducers/entities';
import CS from '../../../services/commonService';
import * as API from '../../../constants/API';
import * as MSG from '../../../constants/MSG';
//  Externals
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-dropdown';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
//  Component starts
const AddUpdate = ({ columnKey, helpTopic, onCloseModal }) => {
    //	Pre-defined values
    if (isEmpty(helpTopic)) helpTopic = { query: '' };
    const statusOptions = [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' }
    ];
    //	Redux-store
    const dispatch = useDispatch();
    const { items } = useSelector(state => state.entities[columnKey]);
    //  History
    const history = useHistory();
    //	State values
    const [status, setStatus] = useState(statusOptions.find(option => option.value === helpTopic.status) || { value: true, label: 'Active' });
    const [errors, setErrors] = useState({ query: '' });
    let [query, setQuery] = useState(helpTopic.query);
    const [id] = useState(helpTopic._id);

    /******************************************************************************************
    @Purpose    :   To validate form
    *******************************************************************************************/
    const validateForm = () => {
        query = query.trim();
        //  Query
        if (isEmpty(query)) errors.query = MSG.REQUIRED_QUERY;
        else errors.query = '';
        //	Errors
        setErrors({ ...errors });
        return isEmpty(errors.query) ? true : false;
    };

    /******************************************************************************************
    @Purpose    :   To add-update help topic
    *******************************************************************************************/
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        try {
            dispatch(apiCallBegan());
            const body = { query, status: status.value };
            let url = API.HELP_TOPIC;
            let method = 'post';
            if (!isEmpty(id)) {
                url = `${API.HELP_TOPIC}/${id}`;
                method = 'put';
            }
            const response = await CS.callApi({ url, body, method, isAuthorized: true });
            if (response.status) {
                CS.showSuccessMessage(response.message);
                if (isEmpty(id)) await dispatch(setDefaultPagination({ columnKey }));
                else {
                    const updatedItems = items.map(item => {
                        const entity = { ...item };
                        if (item._id === id) {
                            entity.query = query;
                            entity.status = status.value;
                        }
                        return entity;
                    });
                    dispatch(setItems({ columnKey, items: updatedItems }));
                }
                await onCloseModal(isEmpty(id));
            }
            dispatch(apiCallEnd());
        } catch (error) {
            console.error('error In ====>>>> Add-Update Help Topic <<<<====', error);
            dispatch(apiCallFailed());
            history.push(URL.login);
        }
    };

    return (
        <div className='modal-content'>
            <div className='modal-header'>
                <h5 className='modal-title'> {isEmpty(id) ? 'Add Help Topic' : 'Update Help Topic'}</h5>
            </div>
            <div className='notification-form modal-body card profile-card password-modal'>
                <div className='row'>
                    <div className='col-md-12'>
                        <form onSubmit={(event) => handleSubmit(event)}>
                            <div className='mb-md-4 mb-3'>
                                <div className='form-group mb-0'>
                                    <label className='label xl-label'>Query Name<sup className='text-danger'>*</sup></label>
                                    <div className='w-100 position-relative'>
                                        <input
                                            className='form-control'
                                            type='text'
                                            id='query'
                                            name='query'
                                            placeholder='Query Name'
                                            value={query}
                                            onChange={(e) => {
                                                setQuery(e.target.value);
                                                setErrors({ ...errors, query: '', }
                                                );
                                            }}
                                        />
                                        <div className='error-message-password'>
                                            <span className='text-danger error-msg d-block mb-2'>{errors.query}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!isEmpty(id) && <div className='mb-md-4 mb-3'>
                                <div className='form-group mb-0'>
                                    <label className='label xl-label'>Status<sup className='text-danger'>*</sup></label>
                                    <div className='w-100 position-relative'>
                                        <Select
                                            value={status}
                                            onChange={(option) => setStatus(option)}
                                            options={statusOptions}
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div className='mt-5 text-right'>
                                <button className='btn btn-primary' type='submit' >{isEmpty(id) ? 'Add' : 'Update'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    );
}

/******************************************************************************************
@Purpose    :   To validate the props
*******************************************************************************************/
PropTypes.propTypes = {
    helpTopic: PropTypes.object,
    columnKey: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired,
};

export default memo(AddUpdate);